import styled, { css } from 'styled-components';
import { IconButton, CircularProgress } from '@material-ui/core';
import { GlobalContainer } from '../../../utils/GlobalStyles';

interface ContentProps {
  disabled?: boolean;
}

export const Container = styled(GlobalContainer)`
  @media screen and (max-width: 769px) {
    min-width: 90vw;
  }
`;

export const Content = styled.div<ContentProps>`
  && {
    display: flex;
    justify-content: space-between;
    max-width: 959px;
    height: auto;
    padding-top: 32px;
    padding-bottom: 32px;

    ${({ disabled }) =>
      disabled &&
      css`
        label {
          color: ${({ theme }) => theme.palette.grey._250.hex()};
        }
      `};

    .tefPending {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.yellow._600.hex()};
    }

    .tefOk {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.green._400.hex()};
    }

    .tefProblem {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.red._700.hex()};
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding-top: 32px;
      flex-direction: column;
    }
  }
`;

export const PaymentTitle = styled.div`
  && {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 10px;
    place-content: center;

    font-size: 20px;
    font-weight: 700;

    span {
      align-self: center;
      margin-left: 11px;
    }

    svg {
      border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
      border-radius: 50px;
      height: 22px;
      width: 22px;
      padding: 3px;
      background-color: ${({ theme }) => theme.palette.grey._50.hex()};
    }

    @media screen and (min-width: 769px) {
      place-content: initial;
    }
  }
`;

export const CardBrand = styled.div`
  && {
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;

    label {
      padding-bottom: 8px;
    }

    & .MuiFormControl-root {
      width: 100%;
      max-width: 569px;
    }
  }
`;

export const RemovePayment = styled(IconButton)`
  color: ${({ theme }) => theme.palette.grey._500.hex()};
`;

export const ValueField = styled.div`
  && label {
    padding-bottom: 8px;
  }
  width: 200px;
`;

export const InstallmentsOptions = styled.div`
  && span {
    font-weight: 600;
    margin-top: 30px;
  }
  margin-top: 30px;
`;

export const PaymentContainer = styled.div<ContentProps>`
  width: 570px;

  .formFields:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.palette.grey._250.hex()};
    background: ${({ theme }) => theme.palette.grey._50.hex()};
  }

  .addLoadingPayment {
    color: ${({ theme }) => theme.palette.grey._210.hex()};
  }

  .addPayment {
    margin-top: 30px;
    text-transform: capitalize;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.yellow._600.hex()};
  }
  @media screen and (max-width: 769px) {
    max-width: 90vw;
  }
`;

export const CircularLoader = styled(CircularProgress)`
  && {
    .MuiCircularProgress-colorPrimary {
      color: ${({ theme }) => theme.palette.yellow._600.hex()};
    }
  }
`;

export const InstallmentsContainer = styled.div`
  && {
    fieldset {
      border: none;
    }
  }
  margin-top: 10px;
  width: 569px;
  border: 1px solid ${({ theme }) => theme.palette.grey._500.hex()};

  :hover {
    border-color: ${({ theme }) => theme.palette.brown.main.hex()};
  }
  @media screen and (max-width: 769px) {
    width: 100%;
  }
`;

export const LabelInstallments = styled.div`
  display: grid;
  grid: 1fr 1fr 1fr 1fr;
  grid-template-columns: 80px 100px 100px 100px;
  grid-gap: 60px;
  border: none;
  font-weight: 600;

  && div {
    padding-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 12px;
  }

  @media screen and (max-width: 769px) {
    grid-template-columns: 80px 80px 100px 100px;
  }
`;

export const ValuesContainer = styled.div`
  width: 350px;
  height: auto;
  border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};

  .title {
    font-weight: 600;
    font-size: 18px;
    padding: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
    background: ${({ theme }) => theme.palette.grey._50.hex()};
  }
  && hr {
    background: none;
    margin: auto;
    border: 1px dashed ${({ theme }) => theme.palette.grey._200.hex()};
  }
`;

export const Values = styled.div`
  display: grid;
  grid: 1fr 1fr;
  grid-template-columns: 170px 100px;
  grid-gap: 55px;
  padding: 10px;

  .customValue {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 110px;
  }

  .moneyValues {
    text-align: right;
  }

  .remaining {
    color: ${({ theme }) => theme.palette.pink._500.hex()};
  }

  .noRest {
    color: ${({ theme }) => theme.palette.green._400.hex()};
  }

  .methodTitle {
    display: flex;
    align-items: center;
  }
`;

export const TableContainer = styled.div`
  width: 350px;

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding-top: 42px;
    margin-bottom: 32px;
  }
`;

export const RemoveItem = styled(IconButton)`
  && {
    color: ${({ theme }) => theme.palette.grey._500.hex()};
  }
`;
