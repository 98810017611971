export const CEP = [11111111];

export const CPF = ['11111111111'];

export const metodoPagamento = [{ id: 61, descricao: 'Cartão' }];

export const formasPagamento = [
  { id: 10, nome: 'VISA' },
  { id: 15, nome: 'MASTERCARD' },
  { id: 20, nome: 'AMERICAN EXPRESS' },
  { id: 25, nome: 'AURA' },
  { id: 30, nome: 'ELO' },
];
