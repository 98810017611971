import { Factory } from 'miragejs';
import faker from 'faker';

faker.locale = 'pt_BR';

const ofereceMontagem = faker.random.boolean();

export default {
  itinerario: Factory.extend({
    id() {
      return faker.random.number(10);
    },
    nome() {
      return faker.fake('Cachoeiro do Itapemerin - Atacado');
    },
    descricao() {
      return 'Atende bairro Garavello';
    },
    ofereceMontagem() {
      return ofereceMontagem;
    },
    descontoNaoMontar() {
      let descontoNaoMontar: number;

      if (ofereceMontagem) {
        descontoNaoMontar = 2;
      } else {
        descontoNaoMontar = 0;
      }

      return descontoNaoMontar;
    },
    valorEntrega() {
      return faker.random.float({ max: 300 });
    },
    bairros() {
      return [
        faker.fake('{{address.country}}'),
        faker.fake('{{address.country}}'),
      ];
    },
  }),
};
