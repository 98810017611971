/* eslint-disable import/no-unresolved */
import { Server } from 'miragejs';
import { AnyRegistry, Instantiate } from 'miragejs/-types';
import { ModelInitializer } from 'miragejs/orm/schema';

const addressSeeder = (server: Server): void => {
  server.createList('endereco', 10);
};

const productsSeeder = (server: Server): void => {
  server.create('produto', { prdno: '111111' } as Partial<
    ModelInitializer<Instantiate<AnyRegistry, 'produto'>>
  >);
  server.create('produto', { prdno: '222222' } as Partial<
    ModelInitializer<Instantiate<AnyRegistry, 'produto'>>
  >);
  server.create('produto', { prdno: '333333' } as Partial<
    ModelInitializer<Instantiate<AnyRegistry, 'produto'>>
  >);
};

const pedidoSeeder = (server: Server): void => {
  server.create('pedido');
};

const customerSeeder = (server: Server): void => {
  server.createList('cliente', 1);
};

export default function seeds(server: Server): void {
  addressSeeder(server);
  productsSeeder(server);
  pedidoSeeder(server);
  customerSeeder(server);
}
