import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useFeature } from 'flagged';
import {
  addPayment,
  PaymentData,
  StoragedPaymentData,
} from '../../services/payment';
import {
  isTefPending,
  TefPaymentMethods,
  tefStepUri,
} from '../../services/tef';
import Footer from '../../components/Footer';
import PaymentForm from '../../components/Forms/PaymentForm';
import Layout from '../../components/Layout';
import { useStepForm } from '../../hooks/StepForm';
import { useOrder } from '../../hooks/OrderContext';
import { StepIndex, Steps } from '../../hooks/types';
import { useToast } from '../../hooks/Toast';
import {
  finalizeOrder,
  Order,
  OrderStatus,
  reserveOrder,
} from '../../services/order';

const Payment: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const editPayment = Boolean(useFeature('PermitirPagamentoRateado'));

  const { setStep, getFormData, setFormData, getNextStep } = useStepForm();
  const { order, setOrder } = useOrder();
  const [orderPaymentData, setOrderPaymentData] = useState<PaymentData | null>(
    null,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasPendingPayment, setHasPendingPayment] = useState(false);

  useEffect(() => setStep(Steps.payment), [setStep]);

  const handleFinalizeOrder = useCallback(async (): Promise<void> => {
    const formData = getFormData<StoragedPaymentData | undefined>(
      StepIndex.PAYMENT,
    );

    if (!order || !formData || !formData.tef) return;

    const { id, version } = order;

    try {
      const orderData = await finalizeOrder({ pedido: { id, version } });

      setFormData(StepIndex.PAYMENT, { ...formData, tef: null });

      setOrder(orderData);

      history.push(`${Steps.details.url}#${id}`);
      return;
    } catch (error) {
      toast.addToast({
        type: 'error',
        title: 'Erro ao finalizar pedido',
        description: error.response?.data?.detail,
      });
    }

    if (formData.tef.atendimentoUri && hasPendingPayment) {
      toast.addToast({
        type: 'info',
        title: 'Atendimento Pendente',
        description: 'Finalize o atendimento pendente no MS-TEF',
      });
      history.push(tefStepUri);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFormData,
    setFormData,
    hasPendingPayment,
    order,
    setOrder,
    history,
    getNextStep,
  ]);

  const getInitialValues = useCallback(async (): Promise<void> => {
    const formData = getFormData<StoragedPaymentData | undefined>(
      StepIndex.PAYMENT,
    );
    if (!order || !formData) return;

    if (order.pagamentos.length > 0) {
      const payment =
        order.pagamentos.find(
          pagamento => pagamento.id === Number(formData?.paymentId),
        ) ?? order?.pagamentos[0];
      if (!payment || order.requisicoesTef.length === 0) return;
    }

    const tefPending = order.requisicoesTef.filter(({ status }) =>
      isTefPending(status),
    );

    if (tefPending.length === 0) return;

    setHasPendingPayment(true);
    await handleFinalizeOrder();
  }, [getFormData, handleFinalizeOrder, order]);

  useEffect(() => {
    getInitialValues();
  }, [order, getInitialValues]);

  const handleReserveOrder = useCallback(
    async (data: Order | null): Promise<void> => {
      if (!data) return;
      try {
        const { id, version, situacao } = data;
        if (situacao === OrderStatus.reserved) {
          toast.addToast({
            type: 'info',
            title: 'Atenção',
            description: `Pedido ${id} já foi reservado`,
          });
          history.push(tefStepUri);
          return;
        }

        if (data.valorEmAberto > 0) {
          toast.addToast({
            type: 'error',
            title: 'Atenção',
            description: `Pedido #${id} ainda possui Valor Em Aberto ! Favor terminar de informar as Formas de Pagamento antes de reservar.`,
          });
          return;
        }

        const orderData = await reserveOrder({
          pedido: {
            id,
            version,
          },
        });

        setOrder(orderData);

        const isTefPayment = orderData.pagamentos.find(
          item =>
            item.metodoPagamento.id === TefPaymentMethods.credit.id ||
            item.metodoPagamento.id === TefPaymentMethods.debit.id,
        );

        if (isTefPayment) {
          history.push(tefStepUri);
          return;
        }

        history.push(`${Steps.details.url}#${id}`);
      } catch (error) {
        toast.addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: error?.response?.data?.detail,
        });
      }
    },
    [history, setOrder, toast],
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    if (order?.valorEmAberto === 0) {
      await handleReserveOrder(order);
      return;
    }

    if (!orderPaymentData?.parcelamento) {
      toast.addToast({
        type: 'error',
        title: 'Erro',
        description: 'Selecione uma opção de parcelamento',
      });
      return;
    }

    try {
      const data = await addPayment(orderPaymentData);

      setOrder(data);
      await handleReserveOrder(data);
    } catch (error) {
      toast.addToast({
        type: 'error',
        title: 'Ocorreu um erro',
        description: error?.response?.data?.detail,
      });
    }
  }, [setOrder, handleReserveOrder, orderPaymentData, toast, order]);

  return (
    <>
      <Layout>
        <PaymentForm setOrderPaymentData={setOrderPaymentData} />
      </Layout>

      <Footer
        onClickForward={
          editPayment ? () => handleReserveOrder(order) : handleSubmit
        }
        isDisabled={isLoading}
      />
    </>
  );
};

export default Payment;
