import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';

import { Step, Steps, StepIndex, Form } from './types';

export const defaultStep = Steps.delivery;

interface StepFormData {
  isReady: boolean;
  setStep: (step: Step) => void;
  step: Step;
  setFormData: (indexStep: number, values?: any) => void;
  getFormData: <T>(indexStep: number) => T | null;
  getNextStep: (current: StepIndex) => Step;
}

const StepFormContext = createContext<StepFormData>({} as StepFormData);

interface ReducerFn {
  (form: Form): any;
}

const StepFormProvider: React.FC = ({ children }) => {
  const [step, setStep] = useState<Step>(() => {
    const currentStep = localStorage.getItem('currentStep');
    if (currentStep) return JSON.parse(currentStep);
    return defaultStep;
  });

  const [form, setForm] = useState<Form>(() => {
    const order = localStorage.getItem('order');
    if (order) return JSON.parse(order);
    return { previousStep: 0 };
  });

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (form !== { previousStep: 0 }) setIsReady(true);
  }, [form]);

  const handleSetStep = useCallback((currentStep: Step): void => {
    window.localStorage.setItem('currentStep', JSON.stringify(currentStep));
    setStep(currentStep);
  }, []);

  const setFormData = useCallback(
    (indexStep: number, values: any | ReducerFn): void => {
      const data = {
        ...form,
        [step.key]: typeof values === 'function' ? values(form) : values,
        previousStep:
          form.previousStep < indexStep ? indexStep : form.previousStep,
      };

      localStorage.setItem('order', JSON.stringify(data));

      setForm(data);
    },
    [form, step],
  );

  const getFormData = useCallback(
    <T extends unknown>(indexStep: StepIndex): T | null => {
      if (!localStorage.order && Object.keys(form).length > 1) {
        setForm({ previousStep: 0 });
        return null;
      }
      return form[indexStep] as T;
    },
    [form],
  );

  const getNextStep = useCallback(
    (current: StepIndex) => Object.values(Steps)[current + 1],
    [],
  );

  return (
    <StepFormContext.Provider
      value={{
        step,
        setStep: handleSetStep,
        setFormData,
        getFormData,
        isReady,
        getNextStep,
      }}
    >
      {children}
    </StepFormContext.Provider>
  );
};

function useStepForm(): StepFormData {
  return useContext(StepFormContext);
}

export { StepFormProvider, useStepForm };
