import styled from 'styled-components';
import { shade } from 'polished';
import Dialog from '@material-ui/core/Dialog';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 5px;
  }
`;

export const ModalContainer = styled.div`
  && {
    min-width: 272px;
    max-width: 320px;
    width: 31vw;
    height: 179px;
    background-color: ${({ theme }) => theme.palette.white._50.hex()};
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    box-sizing: border-box;

    color: ${({ theme }) => theme.palette.black.main.hex()};
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    size: 18px;

    .cancel {
      position: absolute;
      top: 0;
      right: 0;
      margin: 5px;
    }
  }
`;

export const ModalContent = styled.span`
  && {
    margin-top: 20px;
    max-height: 50px;
  }
`;

export const ModalFooter = styled.div`
  && {
    button {
      height: 40px;
      width: 109px;
      margin: 3.5px;
      border-radius: 4px;
      cursor: pointer;

      font-weight: 700;
      size: 14px;

      :focus {
        outline: none;
      }
    }
  }
`;

export const CancelButton = styled.button`
  && {
    background-color: ${({ theme }) => theme.palette.white._50.hex()};
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};

    color: ${({ theme }) => theme.palette.grey._500.hex()};

    :hover {
      background: ${({ theme }) => shade(0.03, theme.palette.white._50.hex())};
      transition: background 0.5s;
    }
    :focus {
      background: ${({ theme }) => shade(0.03, theme.palette.white._50.hex())};
    }
  }
`;
export const ConfirmButton = styled.button`
  && {
    background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
    border: none;

    color: ${({ theme }) => theme.palette.white._50.hex()};

    :hover {
      background: ${({ theme }) => shade(0.1, theme.palette.yellow._600.hex())};
      transition: background 0.5s;
    }
    :focus {
      background: ${({ theme }) => shade(0.1, theme.palette.yellow._600.hex())};
    }
  }
`;
