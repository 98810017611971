import { Response, Server } from 'miragejs';
import { format, formatISO } from 'date-fns';
import faker from 'faker';

import { AppSchema } from '../models/index';
import { PedidoEndereco } from '../models/pedido';
import { detailOrder } from './utils';
import { ErrorResponseNotFound } from '../response';

export default (server: Server): void => {
  const getPedidoDefault = (schema: AppSchema): Record<string, unknown> => {
    const pedido = schema.first('pedido');
    if (pedido) {
      return pedido?.attrs;
    }
    return detailOrder;
  };

  server.post('pdv/pedido/criar', async (schema: AppSchema, request) => {
    const body = JSON.parse(request.requestBody);

    const itinerario = await schema.find(
      'itinerario',
      body.entrega.itinerarioId,
    );

    const pedido = await schema.create('pedido', {
      version: formatISO(new Date()),
      situacao: 'Em Aberto',
      criadoEm: formatISO(new Date()),
      entregas: [
        {
          id: faker.random.number(99),
          itinerario: itinerario?.attrs,
          endereco: body.entrega.endereco as PedidoEndereco,
          itinerarioId: body.entrega.itinerarioId,
          dataEntrega: format(new Date(), 'yyyy-MM-dd'),
          dataMontagem: format(new Date(), 'yyyy-MM-dd'),
        },
      ],
    });

    return new Response(
      200,
      {},
      {
        ...pedido.attrs,
        entregas: pedido.attrs.entregas,
      },
    );
  });

  server.post('pdv/pedido/detalhar', async (schema: AppSchema, request) => {
    const body = JSON.parse(request.requestBody);

    const pedido = schema.find('pedido', body.pedido.id);

    if (!pedido) {
      return new Response(200, {}, detailOrder);
    }

    pedido.attrs.entregas = detailOrder.entregas;
    pedido.attrs.requisicoesTef = detailOrder.requisicoesTef;
    return new Response(200, {}, pedido.attrs);
  });

  server.post('pdv/pedido/detalhar/emAberto', async (schema: AppSchema) => {
    const currentOrder = schema.first('pedido');
    if (currentOrder) {
      detailOrder.id = currentOrder.id;
    }
    return new Response(200, {}, detailOrder);
  });

  server.post(
    'pdv/pedido/entrega/gerar',
    async (schema: AppSchema, request) => {
      const body = JSON.parse(request.requestBody);

      const pedido = schema.find('pedido', body.pedido.id);

      if (!pedido) {
        return new Response(
          404,
          {},
          {
            detail: `Pedido #${body.pedido.id} não encontrado`,
          },
        );
      }
      const itinerario = await schema.first('itinerario');

      pedido.entregas[0] = {
        id: body.entregaId.id,
        ...body.entrega,
        itinerario: itinerario?.attrs,
        dataEntrega: format(new Date(), 'yyyy-MM-dd'),
        dataMontagem: format(new Date(), 'yyyy-MM-dd'),
      };
      pedido.version = formatISO(new Date());
      pedido.save();

      return new Response(200, {}, pedido.attrs);
    },
  );

  server.post('pdv/pedido/produto/adicionar', async (schema: AppSchema) => {
    return new Response(200, {}, getPedidoDefault(schema));
  });

  server.post('pdv/pedido/produto/alterar', async (schema: AppSchema) => {
    return new Response(200, {}, getPedidoDefault(schema));
  });

  server.post('pdv/pedido/produto/remover', async (schema: AppSchema) => {
    return new Response(200, {}, getPedidoDefault(schema));
  });

  server.post('pdv/pedido/cliente/registrar', async (schema: AppSchema) => {
    return new Response(200, {}, getPedidoDefault(schema));
  });

  server.post('pdv/pedido/reservar', async (schema: AppSchema) => {
    const order = schema.first('pedido');

    if (!order) {
      return new Response(500, {}, ErrorResponseNotFound());
    }

    order.update({ situacao: 'Reservado' });

    return new Response(200, {}, order?.attrs);
  });

  server.post('pdv/pedido/liberarEdicao', async (schema: AppSchema) => {
    const order = schema.first('pedido');

    if (!order) {
      return new Response(500, {}, ErrorResponseNotFound());
    }

    order.update({ situacao: 'Em Aberto' });

    return new Response(200, {}, order?.attrs);
  });

  server.post('pdv/pedido/finalizar', async (schema: AppSchema) => {
    const order = schema.first('pedido');

    if (!order) {
      return new Response(500, {}, ErrorResponseNotFound());
    }

    order.update({ situacao: 'Finalizado' });

    return new Response(200, {}, order?.attrs);
  });
};
