import styled from 'styled-components';

export const Container = styled.div`
  && {
    & label {
      color: ${({ theme }) => theme.palette.black.main.hex()};
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      size: 14px;
      padding-bottom: 8px;
    }
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: 130px;
  margin-top: 10px;
  & input {
    text-align: center;
  }
`;
