import styled, { css } from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

interface TextFieldProps {
  multiline?: boolean;
  disabled?: boolean;
}

export const StyledFormControl = styled(FormControl)`
  && label {
    color: ${({ theme }) => theme.palette.black.main.hex()};
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    size: 14px;
  }
`;

export const StyledTextField = styled(TextField)<TextFieldProps>`
  && {
    .MuiOutlinedInput-root {
      height: 42px;
      border-radius: 4px;
      box-sizing: border-box;
      background: ${({ theme }) => theme.palette.white.main.hex()};

      ${({ error }) =>
        error &&
        css`
          fieldset {
            border-color: ${({ theme }) => theme.palette.pink._500.hex()};
          }
        `}

      ${({ disabled }) =>
        disabled &&
        css`
          fieldset {
            border-color: ${({ theme }) => theme.palette.grey._250.hex()};
          }
        `}

      &:hover fieldset,
      &.Mui-focused fieldset {
        ${({ disabled }) =>
          !disabled &&
          css`
            border-color: ${({ theme }) => theme.palette.brown.main.hex()};
          `};
      }

      .MuiOutlinedInput-input:disabled {
        height: 10%;
        background-color: ${({ theme }) => theme.palette.grey._50.hex()};
        color: ${({ theme }) => theme.palette.grey._600.hex()};
        cursor: not-allowed;
      }

      .MuiOutlinedInput-inputMultiline {
        align-self: flex-start;
        resize: both;
        overflow-y: auto !important;
      }

      &.MuiOutlinedInput-multiline {
        padding: 10px 4px 10px 16px;
      }

      ${props =>
        props.multiline &&
        css`
          height: initial;
          min-height: 42px;
        `}
    }

    .MuiFilledInput-root {
      height: 42px;
      box-sizing: border-box;
      background: ${({ theme }) => theme.palette.white.main.hex()};
      color: ${({ theme }) => theme.palette.black.main.hex()};

      &:hover fieldset,
      &::after {
        border-color: ${({ theme }) => theme.palette.brown.main.hex()};
      }
    }

    .MuiInputBase-root {
      color: ${({ theme }) => theme.palette.black.main.hex()};
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      size: 14px;
    }
  }
`;
