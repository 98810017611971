import styled, { css } from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

interface FillableSelect {
  error?: boolean;
  disabled?: boolean;
}

export const StyledFormControl = styled(FormControl)`
  && {
    & label {
      color: ${({ theme }) => theme.palette.black.main.hex()};
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      size: 14px;
      padding-bottom: 8px;
    }
  }
`;

export const StyledSelect = styled(Select)<FillableSelect>`
  && {
    height: 42px;
    background: ${({ theme }) => theme.palette.white.main.hex()};
    border: ${({ theme }) => theme.palette.grey._300.hex()};
    box-sizing: border-box;
    border-radius: 4px;
    color: ${({ theme }) => theme.palette.black.main.hex()};
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;

    & option {
      color: ${({ theme }) => theme.palette.black.main.hex()};
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 14px;
    }

    &.MuiSelect-root {
      padding-top: 9px;
      padding-bottom: 11px;
    }

    ${({ error }) =>
      error &&
      css`
        fieldset {
          border-color: ${({ theme }) => theme.palette.pink._500.hex()};
        }
      `}

    .MuiSelect-select:disabled {
      cursor: not-allowed;
      color: ${({ theme }) => theme.palette.black.main.hex()};
      background: ${({ theme }) => theme.palette.grey._50.hex()};
    }

    .MuiOutlinedInput-input {
      padding: 10px 10px;
      width: 100%;
      border-radius: 4px;
      height: 20px;
    }

    .MuiOutlinedInput-input:not(:disabled):focus {
      border: 2px solid ${({ theme }) => theme.palette.brown.main.hex()};
      background: ${({ theme }) => theme.palette.white.main.hex()};
    }

    &:not(:disabled):hover fieldset,
    &:not(:disabled):focus fieldset {
      fieldset {
        border-color: ${({ theme }) => theme.palette.brown.main.hex()};
      }
    }

    & #placeholder {
      color: ${({ theme }) => theme.palette.grey._600.hex()};
    }
    & select:focus {
      background: ${({ theme }) => theme.palette.white.main.hex()};
    }
  }
`;
