import React, { RefObject, useEffect, useState } from 'react';

import { FormHandles } from '@unform/core';
import Layout from '../../components/Layout';
import DeliveryForm from '../../components/Forms/DeliveryForm';
import Footer from '../../components/Footer';

import { useStepForm } from '../../hooks/StepForm';
import { Steps } from '../../hooks/types';

const Delivery: React.FC = () => {
  const { setStep } = useStepForm();
  const [form, setForm] = useState<RefObject<FormHandles>>();

  useEffect(() => setStep(Steps.delivery), [setStep]);

  return (
    <>
      <Layout>
        <DeliveryForm setFormRef={setForm} />
      </Layout>
      <Footer form={form} />
    </>
  );
};

export default Delivery;
