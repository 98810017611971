import { createContext, useState, useContext, useCallback } from 'react';
import jwt from 'jsonwebtoken';
import api from '../services/apiClient';
import * as auth from '../services/auth';
import { clearStorage } from '../services/order';

const APP_KEY = '@MSPDV';

interface User {
  id: number;
  login: string;
  nome: string;
  loja: {
    id: number;
    nome: string;
  };
}

interface AuthParams {
  token?: string | null;
  expiresIn?: number | null;
  error?: string | null;
}

interface AuthContextData {
  signed: boolean;
  user: User | null;
  signIn(authParams?: AuthParams): void;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(() => {
    const storagedUser = localStorage.getItem(`${APP_KEY}:user`);

    if (storagedUser) {
      const storagedUserData = JSON.parse(String(storagedUser))?.userData;

      const decodedData = jwt.decode(storagedUserData, { json: true });

      return decodedData?.user;
    }
    return null;
  });

  const signIn = useCallback(async (authParams: AuthParams) => {
    const { error } = authParams;

    if (error) {
      auth.oauthHubRedirect();
      return;
    }

    try {
      const response = await api.post('auth/perfil');

      if (response.status !== 200) return;

      const { data: currentUser }: { data: User } = response;
      setUser(currentUser);

      const storagedUser = localStorage.getItem(`${APP_KEY}:user`);

      if (storagedUser) {
        const storagedUserData = JSON.parse(String(storagedUser))?.userData;

        const decodedData: {
          [key: string]: User;
        } | null = jwt.decode(storagedUserData, { json: true });

        if (decodedData?.user.id !== currentUser.id) clearStorage();
      }

      const userData = jwt.sign(
        { user: { ...currentUser } },
        String(process.env.REACT_APP_TOKEN_KEY),
      );

      localStorage.setItem(`${APP_KEY}:user`, JSON.stringify({ userData }));

      auth.appRedirect();
    } catch (err) {
      if (err?.response?.status === 401) {
        auth.authRedirect();
        return;
      }
      auth.oauthHubRedirect();
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      await api.post('auth/sair');
    } finally {
      localStorage.clear();
      auth.oauthHubRedirect();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ signed: !!user, user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export { AuthProvider, useAuth };
