import styled, { css } from 'styled-components';

interface ContainerProps {
  hasHeader?: boolean;
  isConfirmationPage?: boolean;
}

export const Container = styled.div<ContainerProps>`
  && {
    background: ${({ theme }) => theme.palette.white._100.hex()};
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    place-items: center;

    ${props =>
      props.hasHeader === false &&
      css`
        padding-top: 0;
      `}

    @media screen and (min-width: 769px) {
      transform: translateY(62px);
      overflow: hidden;
      margin-bottom: 165px;

      ${props =>
        props.isConfirmationPage === true &&
        css`
          transform: translateY(120px);
        `}
    }
  }
`;

export const Breadcrumb = styled.div`
  && {
    position: fixed;
    z-index: 2;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    display: flex;

    @media screen and (min-width: 769px) {
      position: fixed;
      overflow-x: hidden;
    }
  }
`;

export const BackwardButton = styled.button<ContainerProps>`
  && {
    border: none;
    padding-left: 10px;
    background-color: ${({ theme }) => theme.palette.red._400.hex()};

    color: ${({ theme }) => theme.palette.white._50.hex()};

    ${props =>
      props.hasHeader === true &&
      css`
        display: none;
      `}

    ${props =>
      props.isConfirmationPage === true &&
      css`
        background: linear-gradient(
          180deg,
          ${({ theme }) => theme.palette.red._700.hex()} 0%,
          ${({ theme }) => theme.palette.red._400.hex()} 100%
        );
      `}

    @media screen and (min-width: 769px) {
      display: none;
    }
  }
`;
