import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  height: 40px;
  border-radius: 4px;

  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  size: 14px;

  &:hover {
    opacity: 80%;
    transition: opacity 0.5s;
  }

  &:focus {
    outline: none;
    opacity: 70%;
  }
`;
