/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unresolved */
import { Model, hasMany, belongsTo } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';

export type Endereco = {
  id: number;
  cep: string;
};

export type Itinerario = {
  id: string;
  enderecoId: string;
};

export const EnderecoModel: ModelDefinition<Endereco> = Model.extend({
  itinerario: hasMany(),
});

export const ItinerarioModel: ModelDefinition<Itinerario> = Model.extend({
  endereco: belongsTo(),
});
