import React from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { ThemeProvider } from '@material-ui/core/styles';
import { CalendarToday } from '@material-ui/icons';

import { materialTheme } from './styles';

interface DatePickerProps {
  value: Date | null;
  onChange(date: Date | null): void;
  error?: boolean | undefined;
  required?: boolean | undefined;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  id?: string;
  name?: string;
  iconName?: string;
  disabledDates?: Date[];
  disableWeekend?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  required,
  error,
  minDate,
  maxDate,
  id,
  name,
  iconName,
  disabledDates,
  disableWeekend,
}) => {
  const isWeekend = (date: Date): boolean => [0, 6].includes(date.getDay());

  return (
    <ThemeProvider theme={materialTheme}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        margin="normal"
        id={id}
        name={name}
        value={value}
        error={error}
        required={required}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        minDateMessage="A data não deve ser anterior à data prevista."
        maxDateMessage="A data está muito longe."
        invalidDateMessage="Formato de data inválido"
        keyboardIcon={<CalendarToday color={error ? 'error' : 'action'} />}
        KeyboardButtonProps={{ name: iconName }}
        autoOk
        shouldDisableDate={date =>
          (disableWeekend && isWeekend(date as Date)) ||
          disabledDates?.some(
            current => current.getTime() === date?.getTime(),
          ) ||
          false
        }
      />
    </ThemeProvider>
  );
};

export default DatePicker;
