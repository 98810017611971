import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useApp } from '../../../hooks/Application';
import { useAuth } from '../../../hooks/Auth';

interface AuthParams {
  token: string | null;
  expiresIn: number | null;
  error: string | null;
}

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const { setOldAppStateId } = useApp();

  function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().hash);
  }

  const query = useQuery();

  const state = query.get('#state');

  const token = query.get('access_token');
  const expiresIn = Number(query.get('expires_in'));
  const error = query.get('#error');

  useCallback(() => {
    state && setOldAppStateId(state);
  }, [state, setOldAppStateId]);

  useEffect(() => {
    const authParams: AuthParams = { token, expiresIn, error };
    signIn(authParams);
  }, [error, expiresIn, signIn, token]);

  return <div>Aguarde...</div>;
};

export default SignIn;
