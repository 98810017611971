import styled, { css } from 'styled-components';
import { Form as UnformForm } from '@unform/web';

interface DisabledLabel {
  disabled?: boolean;
}

export const Container = styled.div<DisabledLabel>`
  && {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;

    background-color: ${({ theme }) => theme.palette.white.main.hex()};

    width: 100%;
    max-width: 414px;
    margin-top: 50px;
    margin-bottom: 50px;
    border: none;
    padding: 0 10px 0 10px;
    place-content: center;

    hr {
      height: 1px;
      max-width: 967.5px;
      background: ${({ theme }) => theme.palette.grey._400.hex()};
      border: none;
    }

    ${props =>
      props.disabled === true &&
      css`
        label {
          color: ${({ theme }) => theme.palette.grey._250.hex()};
        }
      `}

    @media screen and (min-width: 769px) {
      max-width: 1024px;
      min-height: 735px;
      padding-left: 29px;

      margin-top: 75px;

      border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
      box-sizing: border-box;
      border-radius: 4px;

      hr {
        margin: 11px 27.5px 0 0;
      }
    }
  }
`;

export const ScheduleTitle = styled.div`
  && {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 10px;
    place-content: center;

    font-size: 20px;
    font-weight: 700;

    span {
      align-self: center;
      margin-left: 11px;
    }

    svg {
      border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
      border-radius: 50px;
      height: 22px;
      width: 22px;
      padding: 3px;
      background-color: ${({ theme }) => theme.palette.grey._50.hex()};
    }

    @media screen and (min-width: 769px) {
      place-content: initial;
    }
  }
`;

export const Form = styled(UnformForm)`
  && {
    font-family: 'Montserrat', sans-serif;
    margin-top: 26px;

    strong {
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.palette.brown.main.hex()};
    }

    .info-group > p {
      font-size: 18px;
      font-weight: normal;
      margin: 12px 0 16px 0;
      color: ${({ theme }) => theme.palette.grey._300.hex()};
    }

    .form-group {
      display: flex;
      flex-direction: column;
    }

    .info-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 280px;
    }

    .errorMsg {
      color: red;
      font-size: 12px;
      font-weight: normal;
    }

    .divider {
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.palette.grey._400.hex()};
      border: none;
      margin: 26px 0;
    }

    .MuiFormControl-marginNormal {
      margin: 12px 0 16px 0;
    }

    .info-group:last-child {
      margin-top: 16px;
    }

    @media screen and (min-width: 769px) {
      .form-group {
        flex-direction: row;
      }

      .divider {
        width: 1px;
        height: auto;
        margin: 0px 26px;
      }

      .info-group:last-child {
        margin-top: 0;
      }
    }
  }
`;
