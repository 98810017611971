import { AppSchema, AppRegistry } from './models';

const colletions = ['pedido', 'pagamento'];

type Collection = {
  collection: string;
  data: any[];
};

export async function persist(schema: AppSchema): Promise<void> {
  const data = await Promise.all(
    colletions.map(async collection => {
      const dataCollection = await schema.all(collection as keyof AppRegistry);
      return {
        collection,
        data: dataCollection.models.map(model => model.attrs),
      };
    }),
  );

  window.localStorage.setItem('mirage', JSON.stringify(data));
}

export async function reload(schema: AppSchema): Promise<void> {
  const data = JSON.parse(window.localStorage.getItem('mirage') || '[]');

  if (!data) {
    return;
  }

  Promise.all(
    await data.map(async (collection: Collection) => {
      Promise.all(
        await collection.data.map(async (dataCollection: any) => {
          return schema.create(
            collection.collection as keyof AppRegistry,
            dataCollection,
          );
        }),
      );
    }),
  );
}
