import { CancelTokenSource } from 'axios';
import api from '../apiClient';
import { Order, OrderInfo, updateSavedOrderVersion } from '../order/index';
import { TefDataStoraged } from '../tef';

export type OptionsProps = {
  value: number;
  valueLabel: string;
};

export type OptionsCardBrand = {
  id: number;
  nome: string;
};

export type SearchPaymentMethodsData = {
  id: number;
  descricao: string;
  formasPagamento: Array<{
    id: number;
    nome: string;
  }>;
};

export type SimulatePaymentData = {
  metodoPagamento: {
    id: number;
    descricao: string;
  };
  formaPagamento: {
    id: number;
    nome: string;
    tipoUsoMetodos: string;
  };
  juros: number;
  parcelamento: {
    id: number;
    exibicao: string;
    numeroParcelas: number;
  };
  valorParcelas: number;
  valorTotalParcelado: string;
};

export type PaymentData = {
  pedido: OrderInfo;
  parcelamento: {
    metodoPagamento: {
      id: number;
    };
    formaPagamento: {
      id: number;
    };
    parcelamento: {
      id: number;
      numeroParcelas: number;
    };
  };
  valorPagamento: number;
};

export type StoragedPaymentData = PaymentData & {
  tef?: TefDataStoraged | null;
  paymentId: number;
};

export type OrderPaymentExists = {
  cardId: number;
  parcelamentoId: number;
  orderPaymentId: number;
};

type RemovePaymentData = {
  pedido: OrderInfo;
  pagamento: {
    id: number;
  };
};

type PaymentDataToSimulate = {
  pedido: OrderInfo;
  metodoPagamento: {
    id: number;
  };
  formaPagamento: {
    id: number;
  };
  valorPagamento: number;
};

export const getPaymentMethods = async (
  cancelToken: CancelTokenSource,
): Promise<{
  data: SearchPaymentMethodsData[];
}> => {
  const { data } = await api.post(
    '/pdv/pagamento/buscarMetodosPagamento',
    null,
    {
      cancelToken: cancelToken.token,
    },
  );
  return data;
};

export const getSimulatedPayments = async (
  cancelToken: CancelTokenSource,
  paymentData: PaymentDataToSimulate,
): Promise<SimulatePaymentData[]> => {
  const {
    data: { data: values },
  } = await api.post('/pdv/pagamento/simularParcelamento', paymentData, {
    cancelToken: cancelToken.token,
  });

  return values;
};

export const addPayment = async (paymentData: PaymentData): Promise<Order> => {
  const { data } = await api.post(
    '/pdv/pedido/pagamento/adicionar',
    paymentData,
  );

  updateSavedOrderVersion(data.id, data.version);
  return data;
};

export const removePayment = async (
  paymentData: RemovePaymentData,
): Promise<Order> => {
  const { data } = await api.post('/pdv/pedido/pagamento/remover', paymentData);

  updateSavedOrderVersion(data.id, data.version);
  return data;
};
