import React, { useEffect, useState } from 'react';

import Layout from '../../components/Layout';
import ProductsForm from '../../components/Forms/ProductsForm';
import Footer from '../../components/Footer';
import { useStepForm } from '../../hooks/StepForm';
import { Steps } from '../../hooks/types';

const Products: React.FC = () => {
  const { setStep } = useStepForm();
  const [showFooter, setShowFooter] = useState(false);
  const [handleForward, setHandleForward] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => setStep(Steps.product), [setStep]);

  return (
    <>
      <Layout>
        <ProductsForm
          setShowFooter={setShowFooter}
          handleForward={handleForward}
          setHandleForward={setHandleForward}
          setIsDisabled={setIsDisabled}
        />
      </Layout>

      {showFooter && (
        <Footer
          onClickForward={() => setHandleForward(true)}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};

export default Products;
