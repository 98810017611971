/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unresolved */
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';

export type Cliente = {
  id: number;
  cpfCnpj: string;
  nome: string;
};

export const ClienteModel: ModelDefinition<Cliente> = Model.extend({
  cpfCnpj: Model,
});

export default {
  cliente: ClienteModel,
};
