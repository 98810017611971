import 'styled-components';
import color from 'color';

const theme = {
  spacing: {
    small: '10px',
    medium: '20px',
    large: '30px',
  },
  palette: {
    textSecondary: {
      main: color('#9B9B9B'),
      dividers: color('#C4CACF'),
      _400: color('#949BA0'),
    },
    white: {
      main: color('#FFFFFF'),
      _50: color('#FEFEFE'),
      _100: color('#F5F6FA'),
    },
    yellow: {
      _100: color('#FDE6BA'),
      _600: color('#E09A11'),
    },
    green: {
      _300: color('#89BA1C'),
      _400: color('#97B554'),
      _700: color('#648912'),
    },
    pink: {
      _100: color('#FFD3D0'),
      _300: color('#F4BAB5'),
      _500: color('#EB5757'),
    },
    blue: {
      main: color('#A3DDFE'),
      _50: color('#F3F6F9'),
      toast: color('#3172b7'),
      _700: color('#51626F'),
      _900: color('#283143'),
    },
    grey: {
      _50: color('#F5F6FA'),
      _200: color('#E6E7EC'),
      _210: color('#DADADA'),
      _220: color('#D5D5DC'),
      _250: color('#BFC0C3'),
      _300: color('#BFC0C4'),
      _400: color('#CCCCD3'),
      _500: color('#9A9A9A'),
      _600: color('#5C5C5C'),
    },
    black: {
      main: color('#000000'),
    },
    brown: {
      main: color('#3F2D2C'),
    },
    red: {
      _400: color('#B53932'),
      _700: color('#8C1106'),
    },
  },
};

type Theme = typeof theme;

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}

export default theme;
