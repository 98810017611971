type ErrorResponse = {
  status?: number;
  type?: string;
  title?: string;
  detail?: string;
};

export const ErrorResponse = ({
  status = 400,
  type,
  title,
  detail,
}: ErrorResponse): any => {
  return {
    status,
    type,
    title,
    detail,
  };
};

export const ErrorResponseNotFound = (message?: string): ErrorResponse =>
  ErrorResponse({
    status: 404,
    type: 'http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html',
    title: 'Não foi encontrado',
    detail: message || 'Não foi encontrado registro X',
  });
