/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface ProgressIndex {
  step: number;
}

interface ContainerProps {
  isMobile: boolean;
}

export const ContainerBar = styled.div<ContainerProps>`
  && {
    display: flex;
    flex: 1;
    height: 53px;
    width: 100%;

    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;

    background-color: ${({ theme }) => theme.palette.red._400.hex()};
    color: ${({ theme }) => theme.palette.white._50.hex()};

    margin-top: 0px;

    ${props =>
      props.isMobile &&
      css`
        margin-top: 61px;
      `}

    .containerArrow,
    .containerInitial {
      display: flex;
      flex-direction: column;
      align-self: flex-end;
    }

    .crest {
      margin: 10px 10px 17px 10px;
    }

    .bottomBar {
      height: 3px;
      width: 100%;
      background-color: ${({ theme }) => theme.palette.red._400.hex()};
    }

    overflow-x: scroll;

    @media screen and (min-width: 769px) {
      height: 59px;
      margin-top: 61px;
      background-color: ${({ theme }) => theme.palette.white._50.hex()};
      color: ${({ theme }) => theme.palette.black.main.hex()};
      box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1) !important;
      justify-content: center;
      overflow-x: hidden;

      .containerArrow,
      .containerInitial {
        display: flex;
        flex-direction: row;
        margin-right: -18px;
        margin-top: 7px;
        margin-bottom: 10px;
      }

      .nock {
        height: 42px;
        background-color: ${({ theme }) => theme.palette.grey._220.hex()};
        box-sizing: border-box;
        border-top: 21px solid transparent;
        border-bottom: 21px solid transparent;
        border-left: 21px solid ${({ theme }) => theme.palette.white.main.hex()};
        padding-right: 5px;
        margin-right: -5px;
      }

      .crest {
        height: 42px;
        background-color: ${({ theme }) => theme.palette.grey._220.hex()};
        box-sizing: border-box;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 5px;
        display: flex;
        align-items: center;
        margin: 0;
      }

      .point {
        height: 42px;
        box-sizing: border-box;
        border-radius: 4px;
        border-top: 21px solid transparent;
        border-bottom: 21px solid transparent;
        border-left: 21px solid ${({ theme }) => theme.palette.grey._220.hex()};
        margin-left: -2px;
        z-index: 1;
      }

      .bottomBar {
        display: none;
      }
    }
  }
`;

export const ContainerConfirmation = styled.div<ContainerProps>`
  && {
    height: 53px;
    width: 100%;

    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;

    margin-top: 0px;

    color: ${({ theme }) => theme.palette.white._50.hex()};
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.palette.red._700.hex()} 0%,
      ${({ theme }) => theme.palette.red._400.hex()} 100%
    );

    display: flex;
    align-items: center;

    .step {
      width: 100%;
      max-width: 769px;
      text-align: center;
    }

    .exitButton {
      margin-right: 15px;
    }

    @media screen and (min-width: 769px) {
      display: none;
    }
  }
`;

export const ProgressItem = styled.div<ProgressIndex>`
  && {
    transition: all 0.5s ease-in-out;

    ${props =>
      props.step &&
      css`
        color: ${({ theme }) => theme.palette.yellow._600.hex()};

        .bottomBar {
          height: 3px;
          width: 100%;
          background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
        }
      `}

    @media screen and (min-width: 769px) {
      ${props =>
        props.step &&
        css`
          .nock,
          .crest {
            background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
          }

          .point {
            border-left: 21px solid
              ${({ theme }) => theme.palette.yellow._600.hex()};
          }
          color: ${({ theme }) => theme.palette.white._50.hex()};
        `}
    }
  }
`;
