import React, { RefObject, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';

import Layout from '../../components/Layout';
import CustomerForm from '../../components/Forms/CustomerForm';
import Footer from '../../components/Footer';

import { useStepForm } from '../../hooks/StepForm';
import { Steps } from '../../hooks/types';

const Customer: React.FC = () => {
  const { setStep } = useStepForm();
  const [form, setForm] = useState<RefObject<FormHandles>>();

  useEffect(() => setStep(Steps.customer), [setStep]);

  return (
    <>
      <Layout>
        <CustomerForm setFormRef={setForm} />
      </Layout>
      <Footer form={form} />
    </>
  );
};

export default Customer;
