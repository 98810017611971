import { CancelTokenSource } from 'axios';
import api from '../apiClient';
import { ProductData, ProductOrder } from '../product';
import { StatusTef } from '../tef';

const STORAGE_ORDER_KEY = 'order';
const STORAGE_ORDER_INFO_KEY = 'order_info';

export enum OrderStatus {
  inProgress = 'Em Aberto',
  reserved = 'Reservado',
  finished = 'Finalizado',
  exported = 'Exportado',
  processing = 'Em Processamento',
  processed = 'Processado',
  canceled = 'Cancelado',
  awaitingChargeback = 'Aguardando Estorno TEF',
}

enum OrderInServiceStatus {
  inProgress = 'Em Aberto',
  reserved = 'Reservado',
}

export type OrderItem = {
  produto: {
    prdno: string;
    grade: string;
  };
  titulo?: string;
  valorOriginal: {
    origem: string;
    precoUnitario: number;
  };
  fatorConversao: number;
  quantidade: number;
  disponibilidade: string;
  precoUnitario: number;
  precoTotal: number;
  montagem: boolean;
  valorTotalDescontos: number;
  valorTotalFrete: number;
  valorTotalServicos: number;
  descontos: [
    {
      tipo: string;
      nome: string;
      valorUnitario: number;
    },
  ];
  acrescimos: [
    {
      tipo: string;
      nome: string;
      valorUnitario: number;
    },
  ];
  valorTotal: number;
};

export type OrderPayment = {
  id: number;
  situacao: string;
  metodoPagamento: {
    id: number;
    descricao: string;
  };
  formaPagamento: {
    id: number;
    nome: string;
  };
  parcelamento: {
    id: number;
    exibicao: string;
  };
  valorPago: number;
  numeroParcelas: number;
  valorParcelas: number;
  detalhamentoProdutos: [
    {
      produto: {
        prdno: string;
        grade: string;
      };
      valorUnitario: string;
    },
  ];
  tef?: {
    filialFaturamento: number;
    codigoBandeira: number;
    bin?: string;
    nomeProduto?: string;
    nsuSitef?: string;
    nsuHost?: string;
    codigoAutorizacao?: string;
    dataAutorizacao?: string;
    codigoRedeAutorizadora: string;
    linhaCorte?: number;
    arquivoAutorizacao?: string;
  };
};

export type OrderDelivery = {
  id: number;
  itinerario: {
    id: number;
    nome: string;
    descricao: string;
    ofereceMontagem: boolean;
    descontoNaoMontar: number;
    valorEntrega: number;
    filial: string;
  };
  endereco: {
    cep: number;
    tipoLogradouro: string;
    bairro: string;
    logradouro: string;
    numero: string;
    complemento: string;
    pontoReferencia: string;
  };
  dataEntrega: string;
  dataMontagem: string;
};

export type Order = {
  id: number;
  version: string;
  situacao: string;
  loja: number;
  criadoEm: string;
  pagoEm: string;
  itens: OrderItem[];
  cliente: {
    cpfCnpj: string;
    nome: string;
    telefone: string;
    dataNascimento: string;
  };
  pagamentos: OrderPayment[];
  entregas: OrderDelivery[];
  valorTotalProdutos: number;
  valorTotalFrete: number;
  valorEmAberto: number;
  valorVenda: number;
  requisicoesTef: {
    status: StatusTef;
    tipo: number;
    dadosEnvio: Record<string, unknown>;
    dadosRetorno: Record<string, unknown>;
  }[];
  ipAtendimento: string;
  atendente: {
    id: number;
    login: string;
    nome: string;
  };
};

export type OrderInfo = {
  id: number;
  version: string;
};

export type SavedOrder = {
  pedido: OrderInfo;
};
export interface DataToCompleteOrder {
  pedido: OrderInfo;
}

export const getOrderInProgress = async (
  cancelToken?: CancelTokenSource,
): Promise<Order> => {
  const { data } = await api.post(
    'pdv/pedido/detalhar/emAberto',
    {},
    {
      cancelToken: cancelToken?.token,
    },
  );
  return data;
};

export const saveOrderInfo = async (
  orderId: number,
  orderVersion: string,
): Promise<void> => {
  localStorage.setItem(
    STORAGE_ORDER_INFO_KEY,
    JSON.stringify({
      pedido: { id: Number(orderId), version: orderVersion },
    }),
  );
};

export const getSavedOrder = async (): Promise<SavedOrder> => {
  const data = localStorage.getItem(STORAGE_ORDER_INFO_KEY);

  if (!data) {
    const { id, version } = await getOrderInProgress();
    saveOrderInfo(id, version);
    return { pedido: { id, version } };
  }
  return JSON.parse(data);
};

export const cancelOrder = async (): Promise<Order> => {
  const { pedido } = await getSavedOrder();

  const { data } = await api.post('pdv/pedido/cancelar', {
    pedido,
    motivoCancelamento: '',
  });

  return data;
};

export const updateSavedOrderVersion = async (
  orderId: number,
  orderVersion: string,
): Promise<void> => {
  const oldData = await getSavedOrder();

  if (!oldData) {
    localStorage.setItem(
      STORAGE_ORDER_INFO_KEY,
      JSON.stringify({
        pedido: { id: Number(orderId), version: orderVersion },
      }),
    );
    return;
  }

  if (oldData.pedido.id === orderId) {
    localStorage.setItem(
      STORAGE_ORDER_INFO_KEY,
      JSON.stringify({
        pedido: { id: oldData.pedido.id, version: orderVersion },
      }),
    );
  }
};

export const clearStorage = (): void => {
  localStorage.removeItem(STORAGE_ORDER_KEY);
  localStorage.removeItem(STORAGE_ORDER_INFO_KEY);
};

export const addProduct = async (product: ProductData): Promise<Order> => {
  const { data } = await api.post('pdv/pedido/produto/adicionar', {
    ...product,
  });
  updateSavedOrderVersion(data.id, data.version);

  return data;
};

export const updateProduct = async (product: ProductData): Promise<Order> => {
  const { data } = await api.post('pdv/pedido/produto/alterar', { ...product });

  updateSavedOrderVersion(data.id, data.version);
  return data;
};

export const removeProduct = async (product: ProductOrder): Promise<Order> => {
  const { data } = await api.post('pdv/pedido/produto/remover', { ...product });

  updateSavedOrderVersion(data.id, data.version);
  return data;
};

export const reserveOrder = async (orderInfo: {
  pedido: OrderInfo;
}): Promise<Order> => {
  const { data } = await api.post('pdv/pedido/reservar', orderInfo);

  updateSavedOrderVersion(data.id, data.version);

  return data;
};

export const finalizeOrder = async (
  orderData: DataToCompleteOrder,
): Promise<Order> => {
  const { data } = await api.post('pdv/pedido/finalizar', orderData);

  updateSavedOrderVersion(data.id, data.version);
  return data;
};

export const getDetailOrder = async (
  value: { pedido: { id: number } },
  cancelToken?: CancelTokenSource,
): Promise<Order> => {
  const { data } = await api.post('/pdv/pedido/detalhar', value, {
    cancelToken: cancelToken?.token,
  });

  return data;
};

export const releaseOrderEdition = async (orderInfo: {
  pedido: OrderInfo;
}): Promise<Order> => {
  const { data } = await api.post('/pdv/pedido/liberarEdicao', orderInfo);

  updateSavedOrderVersion(data.id, data.version);
  return data;
};

export const isOrderInService = ({ situacao }: Order): boolean => {
  return situacao in Object.values(OrderInServiceStatus);
};

export const isOrderCompleted = (order: Order): boolean => {
  return (
    isOrderInService(order) === false && order.situacao !== OrderStatus.canceled
  );
};
