import styled from 'styled-components';

import desktopMobileImg from '../../assets/images/desktop-mobile.png';
import desktopImg from '../../assets/images/desktop.png';

import cardMachineMobileImg from '../../assets/images/card-machine-mobile.png';
import cardMachineImg from '../../assets/images/card-machine.png';
import Button from '../Button';

interface ImageProps {
  isMobile: boolean;
}

export const Title = styled.div`
  && {
    display: none;

    @media screen and (min-width: 769px) {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      flex-direction: row;
      margin-top: 16px;
      margin-bottom: 10px;
      place-content: initial;

      font-size: 20px;
      font-weight: 700;

      span {
        align-self: center;
        margin-left: 11px;
      }

      svg {
        border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
        border-radius: 50px;
        height: 22px;
        width: 22px;
        padding: 3px;
        background-color: ${({ theme }) => theme.palette.grey._50.hex()};
      }
    }
  }
`;

export const Content = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    row-gap: 40px;

    @media screen and (min-width: 769px) {
      && {
        padding: 0;
        margin-top: 60px;
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-count: 3;
  column-gap: 32px;

  @media screen and (min-width: 769px) {
    column-gap: 42px;
  }
`;

export const DesktopImage = styled.img.attrs<ImageProps>(({ isMobile }) => ({
  src: isMobile ? desktopMobileImg : desktopImg,
}))<ImageProps>``;

export const CardMachineImage = styled.img.attrs<ImageProps>(
  ({ isMobile }) => ({
    src: isMobile ? cardMachineMobileImg : cardMachineImg,
  }),
)<ImageProps>``;

export const Label = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.white.main.hex()};
  text-align: center;

  @media screen and (min-width: 769px) {
    font-weight: 400;
    font-size: 18px;
    font-weight: 22px;
    color: ${({ theme }) => theme.palette.grey._600.hex()};
  }
`;

export const TryAgainButton = styled(Button)`
  && {
    display: block;
    cursor: pointer;

    height: 40px;
    border: 1px;
    padding-left: 1rem;
    padding-right: 1rem;

    font-weight: 600;
    font-size: 16px;

    background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
    color: ${({ theme }) => theme.palette.white._50.hex()};

    &:hover {
      opacity: 80%;
      transition: opacity 0.5s;
    }
  }
`;
