/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unresolved */
import { Factory } from 'miragejs';
import faker from 'faker';
import { metodoPagamento, formasPagamento } from './utils';

faker.locale = 'pt_BR';

const getCardBrandOptions = formasPagamento.map((item: any) => ({
  id: item.id,
  descricao: item.nome,
}));

export default {
  pagamentos: Factory.extend({
    metodoPagamento() {
      return metodoPagamento;
    },
    formaPagamento() {
      return getCardBrandOptions;
    },
  }),
};
