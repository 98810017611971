import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import { AutoComplete, VariationStyle, Mask } from './types';
import {
  any,
  maskCep,
  maskCPF,
  maskCurrency,
  maskDate,
  maskName,
  maskPhone,
  onlyNumbers,
} from '../../services/masks';

import { StyledFormControl, StyledTextField } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  variationStyle?: VariationStyle;
  noValidate?: boolean;
  autoComplete?: AutoComplete;
  pattern?: string;
  helperText?: string; // To use with error
  placeholder?: string;
  disabled?: boolean;
  fieldValue?: string;
  multiline?: boolean;
  required?: boolean;
  type?: string;
  mask?: Mask;
  length: number;
  onChange?:
    | ((
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => void)
    | undefined;
}

const TextField: React.FC<InputProps> = ({
  name,
  label,
  variationStyle,
  helperText,
  placeholder,
  multiline,
  required,
  disabled,
  fieldValue,
  type,
  mask,
  length,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const [maxLength, setMaxLength] = useState(20);
  const [inputError, setInputError] = React.useState(false);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        return ref.value;
      },
      setValue: (ref, newValue: string) => {
        if (newValue && !fieldValue) setValue(newValue);
      },
      clearValue: () => {
        setValue('');
      },
    });
    setInputError(Boolean(error));
  }, [fieldName, registerField, error, fieldValue]);

  const masks: Record<Mask, (value: string) => string> = {
    [Mask.none]: any,
    [Mask.onlyNumber]: onlyNumbers,
    [Mask.cep]: maskCep,
    [Mask.currency]: maskCurrency,
    [Mask.cpf]: maskCPF,
    [Mask.phoneNumber]: maskPhone,
    [Mask.birthday]: maskDate,
    [Mask.nameUpperCase]: maskName,
  };

  const formatValue = (str: string): string => (mask ? masks[mask](str) : str);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): any => {
    const customValue: string = formatValue(event.currentTarget.value);
    setValue(customValue);
    setInputError(false);

    setMaxLength(length);

    if (onChange) {
      onChange({
        ...event,
        target: {
          ...event.target,
          value: customValue,
        },
        currentTarget: {
          ...event.currentTarget,
          value: customValue,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <StyledFormControl>
      <label htmlFor={label}>{label}</label>
      <StyledTextField
        id={fieldName}
        name={fieldName}
        variant={variationStyle}
        type={type}
        error={inputError}
        helperText={helperText}
        placeholder={placeholder}
        multiline={multiline}
        disabled={disabled}
        required={required}
        value={fieldValue || value}
        onChange={handleChange}
        InputProps={{
          inputProps: {
            ref: inputRef,
            mask,
            maxLength,
            ...rest,
            onChange: () => null,
          },
        }}
      />
    </StyledFormControl>
  );
};
export default TextField;
