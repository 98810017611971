import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

import {
  Container,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ConfirmButton,
  CancelButton,
  CustomDialog,
} from './styles';

interface ModalProps {
  description: string;
  confirmText?: string;
  cancelText?: string;
  open?: boolean;
  onClose?: any;
  onConfirm?: any;
}

const ConfirmationModal: React.FC<ModalProps> = ({
  description,
  confirmText = 'Sim',
  cancelText = 'Cancelar',
  onClose,
  open,
  onConfirm,
}) => (
  <Container>
    <CustomDialog onClose={onClose} open={!!open}>
      <ModalContainer>
        <IconButton aria-label="cancel" className="cancel" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <ModalContent>{description}</ModalContent>
        <ModalFooter>
          <ConfirmButton className="confirm" onClick={onConfirm}>
            {confirmText}
          </ConfirmButton>
          <CancelButton onClick={onClose}>{cancelText}</CancelButton>
        </ModalFooter>
      </ModalContainer>
    </CustomDialog>
  </Container>
);

export default ConfirmationModal;
