import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/Auth';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { signed } = useAuth();
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={() =>
        !signed ? (
          <Redirect
            to={{
              pathname: '/',
              state: { referrer: location.pathname },
            }}
          />
        ) : (
          <Component />
        )
      }
    />
  );
};

export default PrivateRoute;
