import { Response, Server } from 'miragejs';
import { formatISO } from 'date-fns';
import { AppSchema } from '../models';
import { ErrorResponseNotFound } from '../response';
import { formasPagamento } from '../factories/utils';

const cardBrandOptions = formasPagamento.map(({ id, nome }) => ({
  value: id,
  valueLabel: nome,
}));

export default (server: Server): void => {
  const paymentMethod = {
    id: 9,
    descricao: 'CARTOES TEF',
  };

  server.post('/pdv/pagamento/buscarMetodosPagamento', async () => {
    return new Response(
      200,
      {},
      {
        data: [
          {
            ...paymentMethod,
            formasPagamento: [...formasPagamento],
          },
        ],
      },
    );
  });

  server.post(
    '/pdv/pagamento/simularParcelamento',
    async (_, request) => {
      const body = JSON.parse(request.requestBody);

      if (!paymentMethod) {
        return new Response(404, {}, ErrorResponseNotFound());
      }

      return new Response(
        200,
        {},
        {
          data: [
            {
              metodoPagamento: {
                id: body.metodoPagamento.id,
                descricao: 'Cartão',
              },
              formaPagamento: {
                ...(formasPagamento.find(
                  ({ id }) => id === body.formaPagamento.id,
                ) || formasPagamento[0]),
                tipoUsoMetodos: 'TabelaPriceCartao',
              },
              parcelamento: {
                id: 223,
                exibicao: '2x',
                numeroParcelas: 2,
              },
              valorParcelas: body.valorPagamento / 2 || 0,
              valorTotalParcelado: body.valorPagamento || 0,
            },
          ],
        },
      );
    },
    { timing: 3000 },
  );

  server.post(
    'pdv/pedido/pagamento/adicionar',
    async (schema: AppSchema, request) => {
      const body = JSON.parse(request.requestBody);
      const { parcelamento } = body;

      const pedido = await schema.find('pedido', body.pedido.id);

      if (!pedido) {
        return new Response(
          404,
          {},
          ErrorResponseNotFound(`Pedido #${body.pedido.id} não encontrado!`),
        );
      }

      const formaPagamento = cardBrandOptions.find(
        item => item.value === Number(parcelamento.formaPagamento.id),
      );

      if (!formaPagamento) {
        // eslint-disable-next-line no-console
        return console.log(
          `Forma de pagamento inexistente: `,
          parcelamento.formaPagamento,
        );
      }

      pedido.version = formatISO(new Date());
      pedido.pagoEm = formatISO(new Date());
      pedido.pagamentos = [
        ...pedido.pagamentos,
        {
          id: pedido.id + (pedido.pagamentos.length || 0) + 1,
          metodoPagamento: {
            id: parcelamento.metodoPagamento.id,
            descricao: 'Cartão',
          },
          formaPagamento: {
            id: formaPagamento.value,
            nome: formaPagamento.valueLabel,
          },
          parcelamento: {
            id: parcelamento.id,
            exibicao: '1x',
          },
          valorPago: body.valorPagamento,
          numeroParcelas: parcelamento.parcelamento.numeroParcelas,
          valorParcelas:
            body.valorPagamento / parcelamento.parcelamento.numeroParcelas,
          detalhamentoProdutos: [
            {
              produto: {
                prdno: '44555',
                grade: 'UNICA',
              },
              valorUnitario: '389.69',
            },
          ],
          tef: {
            filialFaturamento: 104,
            codigoBandeira: 0,
            bin: '',
            nomeProduto: '',
            nsuSitef: '',
            nsuHost: '',
            codigoAutorizacao: '',
            dataAutorizacao: formatISO(new Date()),
            codigoRedeAutorizadora: '',
            linhaCorte: 0,
            arquivoAutorizacao: '',
          },
        },
      ];
      pedido.requisicoesTef = [
        {
          status: 'Pendente',
          tipo: 1,
          dadosEnvio: {},
          dadosRetorno: {},
        },
      ];
      pedido.valorEmAberto -= body.valorPagamento;

      await pedido.save();

      return new Response(
        200,
        {},
        {
          ...pedido.attrs,
        },
      );
    },
  );

  server.post(
    'pdv/pedido/pagamento/remover',
    async (schema: AppSchema, request) => {
      const body = JSON.parse(request.requestBody);

      const pedido = await schema.find('pedido', body.pedido.id);

      if (!pedido) {
        return new Response(
          404,
          {},
          ErrorResponseNotFound(`Pedido #${body.pedido.id} não encontrado!`),
        );
      }

      pedido.version = formatISO(new Date());
      pedido.pagoEm = formatISO(new Date());
      pedido.pagamentos.splice(pedido.pagamentos.indexOf(body.pagamento.id), 1);

      await pedido.save();

      return new Response(
        200,
        {},
        {
          ...pedido.attrs,
        },
      );
    },
  );

  server.post(
    'pdv/pedido/finalizacao/pagamentoViaTef',
    async (schema: AppSchema) => {
      const pedido = schema.first('pedido');

      return new Response(
        200,
        {},
        {
          pedido: {
            id: pedido?.id,
            version: pedido?.version,
          },
          atendimentoUri: 'http://localhost:8877/atendimento',
        },
      );
    },
  );
};
