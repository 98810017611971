import { PriceProps } from '../services/product/index';

export type Step = {
  key: number;
  url: string;
  name: string;
};

export enum StepPageKey {
  delivery,
  products,
  schedule,
  customer,
  tef,
  payment,
  details,
}

export const Steps: Record<string, Step> = {
  delivery: {
    key: 0,
    url: '/delivery',
    name: 'Entrega',
  },
  product: {
    key: 1,
    url: '/products',
    name: 'Produtos',
  },
  schedule: {
    key: 2,
    url: '/schedule',
    name: 'Agendamento',
  },
  customer: {
    key: 3,
    url: '/customer',
    name: 'Cliente',
  },
  payment: {
    key: 4,
    url: '/payment',
    name: 'Pagamento',
  },
  tef: {
    key: 5,
    url: '/tef',
    name: 'Tef',
  },
  details: {
    key: 6,
    url: '/details',
    name: 'Detalhes',
  },
};

export enum StepIndex {
  DELIVERY,
  PRODUCTS,
  SCHEDULE,
  CUSTOMER,
  PAYMENT,
  TEF,
  DETAILS,
}

export type DeliveryType = {
  cep: number;
  tipoLogradouro: string;
  bairro: string;
  cidade: string;
  estado: string;
  complemento: string;
  frete: number;
  observacao: string;
  itinerario: {
    value: string | number;
    text: string;
  };
  numero: string;
  endereco: string;
  pedido: {
    entregas: {
      id: number;
    }[];
  };
};

export type ProductsType = {
  code: string;
  grade: string;
  preco: PriceProps;
  quantidade: number;
  montagem: boolean;
};

export type CustomerType = {
  cpfCnpj: string;
  nome: string;
  telefone: string;
  dataNascimento: string;
};

export type ScheduleType = {
  deliveryDate: string;
  assemblyDate: string;
  hasSchedule: string;
  holidays: {
    periodo: {
      inicio: string;
      fim: string;
    };
    holidays: {
      nome: string;
      data: string;
    }[];
  };
};

export type PaymentType = {
  id: string;
  descricao: string;
};

export type DetailsType = {
  id: string;
};

export type Form = {
  previousStep: number;
  [StepIndex.DELIVERY]?: DeliveryType;
  [StepIndex.PRODUCTS]?: ProductsType;
  [StepIndex.SCHEDULE]?: ScheduleType;
  [StepIndex.CUSTOMER]?: CustomerType;
  [StepIndex.PAYMENT]?: PaymentType;
  [StepIndex.TEF]?: PaymentType;
  [StepIndex.DETAILS]?: DetailsType;
};
