import api from '../apiClient';
import { updateSavedOrderVersion, Order } from '../order';

export type CustomerData = {
  cpfCnpj: string;
  nome: string;
  telefone: string;
  dataNascimento: string;
};

export type CustomerOnOrder = {
  pedido: {
    id: number;
    version: string;
  };
  cliente: CustomerData;
};

export const getCpfCnpj = async (cpfCnpj: string): Promise<CustomerData> => {
  const { data } = await api.post(
    `pessoas/detalhar`,
    {
      cpfCnpj,
    },
    { timeout: 10000, timeoutErrorMessage: 'Timeout' },
  );

  return data;
};

export const registerCustomer = async (
  customer: CustomerOnOrder,
): Promise<Order> => {
  const { data } = await api.post(`pdv/pedido/cliente/registrar`, {
    ...customer,
  });
  updateSavedOrderVersion(data.id, data.version);
  return data;
};
