import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { useStepForm } from '../../hooks/StepForm';

import logo from '../../assets/images/logo.png';

import {
  Logo,
  RightContent,
  ButtonLogout,
  ClockIcon,
  Container,
  NameUser,
} from './styles';
import { useScreen } from '../../hooks/Screen';

interface HeaderProps {
  name: string;
  logout?: (event: React.MouseEvent) => void;
}

const Header: React.FC<HeaderProps> = ({ logout, name }) => {
  const [dateTime, setDateTime] = useState(
    format(new Date(), 'dd/MM/yyyy HH:mm'),
  );
  const { isMobile } = useScreen();

  const { step } = useStepForm();

  useEffect(() => {
    const interval = setInterval(() => {
      const time = format(new Date(), 'dd/MM/yyyy HH:mm');
      if (time !== dateTime) {
        setDateTime(time);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [dateTime]);

  return (
    <Container>
      {(!isMobile || step.key === 0) && <Logo src={logo} alt="MS PDV" />}

      <RightContent>
        <NameUser>
          {name}
          <ClockIcon />
        </NameUser>
        {` ${dateTime}`}
        <ButtonLogout onClick={logout} startIcon={<ExitToAppIcon />}>
          Sair
        </ButtonLogout>
      </RightContent>
    </Container>
  );
};

export default Header;
