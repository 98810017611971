import React, {
  InputHTMLAttributes,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { FormControl, InputBase, IconButton } from '@material-ui/core';
import { useField } from '@unform/core';
import { Container, Group } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  step?: number;
  min?: number;
  count: number;
  setCount: React.Dispatch<SetStateAction<number>>;
  max?: number;
  initialValue?: number;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

const TapCounter: React.FC<InputProps> = ({
  name,
  label,
  step = 1,
  min = 0,
  count,
  setCount,
  max,
  ...rest
}) => {
  const counterRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);

  const handleIncrement = (): void => {
    !max
      ? setCount(count + step)
      : count + step <= max && setCount(count + step);
  };

  const handleDecrement = (): void => {
    count - step >= min && setCount(count - step);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: counterRef.current,
      getValue: ref => ref.value,
      setValue: (_, v) => setCount(Number(v)),
      clearValue: () => setCount(0),
    });
  }, [fieldName, registerField, setCount]);

  return (
    <Container>
      <label htmlFor={label}>{label}</label>
      <Group>
        <IconButton className="minus" onClick={handleDecrement}>
          <RemoveCircleIcon color={count === min ? 'disabled' : 'action'} />
        </IconButton>

        <FormControl>
          <InputBase
            inputRef={counterRef}
            value={count}
            readOnly
            inputProps={{ ...rest }}
            disabled={count === 0}
          />
        </FormControl>
        <IconButton className="plus" onClick={handleIncrement}>
          <AddCircleIcon color={count === max ? 'disabled' : 'action'} />
        </IconButton>
      </Group>
    </Container>
  );
};

export default TapCounter;
