import React from 'react';
import { Router, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import { Location } from 'history';
import PrivateRoute from './PrivateRoute';
import SignIn from '../components/Auth/SignIn';
import history from '../services/history';

import Delivery from '../pages/Delivery';
import Products from '../pages/Products';
import Schedule from '../pages/Schedule';
import Customer from '../pages/Customer';
import Payment from '../pages/Payment';
import Tef from '../pages/Tef/Tef';
import Confirmation from '../pages/Confirmation';

interface PrivateRouteProps extends RouteProps {
  location?: Location | undefined;
}

const Routes: React.FC<PrivateRouteProps> = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={SignIn} />

        <PrivateRoute path="/delivery" component={Delivery} />
        <PrivateRoute path="/products" component={Products} />
        <PrivateRoute path="/schedule" component={Schedule} />
        <PrivateRoute path="/customer" component={Customer} />
        <PrivateRoute path="/payment" component={Payment} />
        <PrivateRoute path="/tef" component={Tef} />
        <PrivateRoute path="/details" component={Confirmation} />

        <PrivateRoute
          path="/"
          component={() => (
            <Redirect
              to={{
                pathname: '/delivery',
                state: history.location.state,
              }}
            />
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
