import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body, * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    outline: none;
  }
  html, body {
    overflow: auto !important;
  }
`;

export const GlobalContainer = styled.div`
  && {
    font-family: 'Montserrat', sans-serif;

    background-color: ${({ theme }) => theme.palette.white.main.hex()};

    width: 100%;
    max-width: 414px;
    margin-top: 50px;
    margin-bottom: 20px;
    border: none;
    padding: 0 10px 0 10px;
    place-content: center;

    hr {
      height: 1px;
      max-width: 967.5px;
      background: ${({ theme }) => theme.palette.grey._400.hex()};
      border: none;
    }

    @media screen and (min-width: 769px) {
      max-width: 1024px;
      min-height: 67vh;
      padding-left: 29px;

      margin-top: 75px;

      border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
      box-sizing: border-box;
      border-radius: 4px;

      hr {
        margin: 11px 27.5px 0 0;
      }
    }
  }
`;
