import React from 'react';
import { FlagsProvider } from 'flagged';
import { ThemeProvider } from 'styled-components';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import Routes from './routes';

import HookProvider from './hooks';

import GlobalStyles from './utils/GlobalStyles';
import theme from './utils/themes';
import { features } from './utils/features';

import { makeServer } from './services/mirage/server';

if (process.env.REACT_APP_MIRAGE) {
  makeServer();
}

const App: React.FC = () => (
  <FlagsProvider features={features}>
    <ThemeProvider theme={theme}>
      <HookProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <GlobalStyles />
          <Routes />
        </MuiPickersUtilsProvider>
      </HookProvider>
    </ThemeProvider>
  </FlagsProvider>
);

export default App;
