import { Server } from 'miragejs';

import auth from './auth.routes';
import endereco from './endereco.routes';
import cliente from './cliente.routes';
import produto from './produto.routes';
import pedido from './pedido.routes';
import agendamento from './agendamento.routes';
import pagamento from './pagamento.routes';
import { persist } from '../persist';
import { AppSchema } from '../models';

export default function routes(this: Server): void {
  this.passthrough();
  auth(this);
  endereco(this);
  cliente(this);
  produto(this);
  pedido(this);
  agendamento(this);
  pagamento(this);

  this.pretender.handledRequest = async (verb, path, request) => {
    // eslint-disable-next-line no-console
    console.log(`${verb} ${path}`, request);
    await persist(this.schema as AppSchema);
  };
}
