import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MenuProps } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Installments, LabelInstallments, Container } from './styles';
import { InstallmentValue } from '../PaymentForm';

interface MenuPropsData extends MenuProps {
  options: InstallmentValue[];
  name: string;
  menu: InstallmentValue | null;
  setMenuValue: React.Dispatch<React.SetStateAction<InstallmentValue | null>>;
}

const SimpleMenu: React.FC<MenuPropsData> = ({ options, setMenuValue }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: InstallmentValue,
  ): void => {
    setSelectedIndex(index);
    setMenuValue(option);
    setAnchorEl(null);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Container>
          <LabelInstallments>
            <div>Parcela</div>
            <div>Valor</div>
            <div>Valor Final</div>
            <div>Juros</div>
          </LabelInstallments>
          <Installments>
            <div>{options[selectedIndex].parcela}</div>
            <div>{options[selectedIndex].valor}</div>
            <div>{options[selectedIndex].valorFinal}</div>
            <div>{options[selectedIndex].juros}</div>
          </Installments>
        </Container>
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              key={option.id}
              selected={option.id === selectedIndex}
              onClick={event => {
                handleMenuItemClick(event, index, option);
              }}
            >
              <Installments className="installmentOption">
                <div className="installment">{option.parcela}</div>
                <div className="value">{option.valor}</div>
                <div className="totalValue">{option.valorFinal}</div>
                <div className="fees">{option.juros}</div>
              </Installments>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default SimpleMenu;
