/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unresolved */
import { Model } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';

export type Produto = {
  prdno: number;
  titulo: string;
  grades: [
    {
      grade: string;
      titulo: string;
      disponibilidade: string;
      valores: [
        {
          tipo: string;
          origem: string;
          valor: number;
        },
      ];
    },
  ];
  montagem: boolean;
  fatorConversao: number;
};

export const ProdutoModel: ModelDefinition<Produto> = Model;
