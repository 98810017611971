import { Button, List, ListItem, TextField } from '@material-ui/core';
import styled from 'styled-components';

const TABLET_SCREEN = '769px';

export const SearchContainer = styled.div`
  && {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.palette.white.main.hex()};

    a {
      color: ${({ theme }) => theme.palette.black.main.hex()};

      text-decoration: none;
      z-index: 1;
    }
  }
`;

export const SearchBackground = styled.div`
  && {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    size: 14px;

    color: ${({ theme }) => theme.palette.white.main.hex()};
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.palette.red._700.hex()} 0%,
      ${({ theme }) => theme.palette.red._400.hex()} 100%
    );
  }
`;

export const SearchProductBar = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    & > .MuiSvgIcon-root {
      margin-right: 10px;
      margin-left: 16px;
    }
    @media screen and (min-width: ${TABLET_SCREEN}) {
      max-width: 700px;
      margin: 0 auto;

      .MuiInputBase-root {
        max-width: 500px;
      }
    }
  }
`;

export const InputSearch = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      background: ${({ theme }) => theme.palette.white.main.hex()};

      &:hover fieldset,
      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.brown.main.hex()};
      }
    }

    .MuiInputBase-root {
      color: ${({ theme }) => theme.palette.grey._600.hex()};
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      size: 14px;
      width: 65vw;
      height: 40px;
    }

    input {
      width: 100%;
    }

    @media (min-width: 570px) and (max-width: ${TABLET_SCREEN}) {
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }
`;

export const CatalogButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.white.main.hex()};
    font-weight: 600;
    margin-right: 16px;

    p {
      margin-left: 5px;
      text-transform: capitalize;
    }

    @media screen and (max-width: 500px) {
      p {
        display: none;
      }
      margin-right: 0;
    }
  }
`;

export const SearchResult = styled.div`
  && {
    margin: 0 auto;
    width: 100%;
    max-width: 700px;
    font-weight: 400;
  }
`;

export const SearchList = styled(List)`
  && {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    padding: 0;
  }
`;

export const SearchListItem = styled(ListItem)`
  && {
    cursor: pointer;
    list-style: none;
    padding: 20px 24px;
    min-height: 50px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
    transition: all 0.2s;
  }
`;
