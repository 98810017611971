/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unresolved */
import { Model, Registry } from 'miragejs';
import { ModelDefinition } from 'miragejs/-types';
import Schema from 'miragejs/orm/schema';

type AppRegistry = Registry<
  {
    metodoPagamento: typeof PagamentoModel;
  },
  {}
>;

export type PagamentoSchema = Schema<AppRegistry>;

export type Pagamento = {
  metodoPagamento: string;
};

export const PagamentoModel: ModelDefinition<Pagamento> = Model.extend({
  metodoPagamento: Model,
});

export default {
  cliente: PagamentoModel,
};
