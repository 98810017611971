import CloseIcon from '@material-ui/icons/Close';
import { Payment } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios, { CancelTokenSource } from 'axios';
import { Footer, Layout, LoadTef } from '../../components';

import { useScreen } from '../../hooks/Screen';
import { useStepForm } from '../../hooks/StepForm';
import { useOrder } from '../../hooks/OrderContext';
import { useToast } from '../../hooks/Toast';
import { Form, StepIndex, Steps } from '../../hooks/types';
import {
  finalizeReversePaymentTef,
  paymentByTef,
  PaymentStatus,
  ReversePayment,
  reversePaymentTef,
} from '../../services/tef';
import {
  Container,
  MobileIframe,
  ModalContainer,
  ModalTef,
  ModalTop,
  Title,
  Content,
  PaymentContainer,
  PaymentTitle,
  ContainerCard,
  Table,
  ButtonTef,
  List,
  PaymentItem,
} from './styles';
import {
  finalizeOrder,
  getOrderInProgress,
  OrderPayment,
  OrderStatus,
} from '../../services/order';
import { maskCurrency } from '../../services/masks';

const Tef: React.FC = () => {
  const { addToast, clearToasts } = useToast();
  const { setFormData, setStep } = useStepForm();
  const { order, setOrder } = useOrder();
  const history = useHistory();
  const { isMobile } = useScreen();
  const [orderPaymentsList, setOrderPaymentsList] = useState<OrderPayment[]>(
    [],
  );
  const [listening, setListening] = useState(false);
  const [paymentInChargeBack, setPaymentInChargeBack] = useState<number | null>(
    null,
  );

  useEffect(() => {
    if (order?.pagamentos && order?.pagamentos.length > 0) {
      setOrderPaymentsList(order?.pagamentos);
    }
  }, [order]);

  const [tefAtendimentoUri, setTefAtendimentoUri] = useState<
    string | undefined
  >(undefined);

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => setStep(Steps.tef), [setStep]);

  const handleFinalizeOrder = useCallback(async (): Promise<void> => {
    if (!order) return;

    const { id, version } = order;

    if (order.situacao === OrderStatus.awaitingChargeback) return;

    try {
      setErrorMessage(undefined);
      setOrder(await finalizeOrder({ pedido: { id, version } }));

      setFormData(StepIndex.PAYMENT, (form: Form): any => ({
        ...form[StepIndex.PAYMENT],
        tef: null,
      }));
      history.push(`${Steps.details.url}#${id}`);

      return;
    } catch (error) {
      clearToasts();
      setErrorMessage(error.response?.data?.detail || error.message);
      addToast({
        type: 'error',
        title: 'Erro ao finalizar pedido',
        description: error.response?.data?.detail,
      });
      setOrder(await getOrderInProgress());
    }
  }, [order, setOrder, setFormData, history, clearToasts, addToast]);

  const openModal = useCallback((): void => {
    setShowModal(true);
    history.push('#modal');
  }, [history]);

  const handleFinalizeReversePaymentTef = useCallback(
    async (payId: number): Promise<void> => {
      if (!order) return;

      if (order.situacao === OrderStatus.reserved) return;

      const { id, version } = order;

      try {
        const objectData: ReversePayment = {
          pedido: {
            id,
            version,
          },
          pagamento: {
            id: payId,
          },
        };

        const data = await finalizeReversePaymentTef(objectData);
        setOrder(data);
        setPaymentInChargeBack(null);
      } catch (errors) {
        clearToasts();
        setErrorMessage(errors.response?.data?.detail || errors.message);
        addToast({
          type: 'error',
          title: 'Erro ao finalizar pedido',
          description: errors.response?.data?.detail,
        });
        setOrder(await getOrderInProgress());
      }
    },
    [order, setOrder, clearToasts, addToast, setErrorMessage],
  );

  const handleReversePaymentTef = useCallback(
    async (payId: number): Promise<void> => {
      if (!order) return;

      try {
        const { id, version } = order;

        const objectData: ReversePayment = {
          pedido: {
            id,
            version,
          },
          pagamento: {
            id: payId,
          },
        };

        const data = await reversePaymentTef(objectData);

        setOrder({
          ...order,
          id: data.pedido.id,
          version: data.pedido.version,
        });

        setTefAtendimentoUri(data.atendimentoUri);
        setPaymentInChargeBack(payId);
        openModal();

        setFormData(StepIndex.PAYMENT, (form: Form): any => ({
          ...form[StepIndex.PAYMENT],
          tef: { atendimentoUri: data.atendimentoUri },
        }));
      } catch (errors) {
        clearToasts();
        setErrorMessage(errors?.response?.data?.detail || errors.message);
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: errors?.response?.data?.detail,
        });
      }
    },
    [
      addToast,
      clearToasts,
      order,
      setOrder,
      setFormData,
      openModal,
      setErrorMessage,
      setTefAtendimentoUri,
    ],
  );

  const loadInitialData = useCallback(
    async (cancelToken?: CancelTokenSource): Promise<void> => {
      setErrorMessage(undefined);

      if (!order) return;

      if (order.situacao === OrderStatus.awaitingChargeback) return;

      try {
        const { id, version } = order;
        const data = await paymentByTef(
          { pedido: { id, version } },
          cancelToken,
        );

        setOrder({
          ...order,
          id: data.pedido.id,
          version: data.pedido.version,
        });

        setTefAtendimentoUri(data.atendimentoUri);
        openModal();

        setFormData(StepIndex.PAYMENT, (form: Form): any => ({
          ...form[StepIndex.PAYMENT],
          tef: { atendimentoUri: data.atendimentoUri },
        }));
      } catch (error) {
        if (axios.isCancel(error)) return;

        if (
          error?.response?.data?.type ===
          '/pdv/pagamento/tef/atendimento-finalizado'
        ) {
          await handleFinalizeOrder();
          return;
        }

        clearToasts();
        setErrorMessage(error?.response?.data?.detail || error.message);
        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: error?.response?.data?.detail,
        });
      }
    },
    [
      order,
      openModal,
      handleFinalizeOrder,
      setOrder,
      setFormData,
      clearToasts,
      addToast,
    ],
  );

  useEffect(() => {
    if (tefAtendimentoUri) return () => null;
    const cancelToken = axios.CancelToken.source();
    loadInitialData(cancelToken);
    return () => cancelToken.cancel();
  }, [loadInitialData, tefAtendimentoUri]);

  const handleCloseModal = useCallback(async (): Promise<void> => {
    setShowModal(false);
    paymentInChargeBack &&
      (await handleFinalizeReversePaymentTef(paymentInChargeBack));

    if (order?.situacao === OrderStatus.awaitingChargeback) return;

    await handleFinalizeOrder();
  }, [
    handleFinalizeOrder,
    handleFinalizeReversePaymentTef,
    order,
    paymentInChargeBack,
  ]);

  useEffect(() => {
    if (!order || listening) return;

    setListening(true);
    const unreg = history.listen((_, action) => {
      if (action === 'PUSH') return;

      unreg();
      setListening(false);
      handleCloseModal();
    });
  }, [history, handleCloseModal, listening, order]);

  return (
    <>
      <Layout>
        <Content>
          {!showModal && !isMobile && (
            <>
              <LoadTef error={errorMessage} retry={loadInitialData} />

              {order?.situacao === OrderStatus.awaitingChargeback && (
                <PaymentContainer>
                  <PaymentTitle className="paymentTitle">
                    <span>Forma de Pagamento</span>
                    <hr />
                  </PaymentTitle>

                  <Table>
                    <tbody>
                      {orderPaymentsList.map(
                        ({
                          id,
                          parcelamento,
                          metodoPagamento,
                          formaPagamento,
                          valorParcelas,
                          situacao,
                        }) => (
                          <tr key={id} className="dataRow">
                            <td>
                              <ContainerCard>
                                <Payment />
                                <span>
                                  {`${metodoPagamento.descricao} 
                                    ${formaPagamento.nome} em ${parcelamento.exibicao}`}
                                </span>
                              </ContainerCard>
                            </td>
                            <td>{maskCurrency(String(valorParcelas || 0))}</td>
                            <td>{situacao}</td>
                            {situacao === PaymentStatus.payed && (
                              <td>
                                <ButtonTef
                                  onClick={() => handleReversePaymentTef(id)}
                                >
                                  Estornar
                                </ButtonTef>
                              </td>
                            )}
                            {situacao === PaymentStatus.cashBack && (
                              <td>
                                <ButtonTef
                                  onClick={() =>
                                    handleFinalizeReversePaymentTef(id)
                                  }
                                >
                                  Tentar Novamente
                                </ButtonTef>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </PaymentContainer>
              )}
            </>
          )}
          {isMobile && !showModal && (
            <>
              <PaymentContainer>
                <PaymentTitle className="paymentTitle">
                  <span>Forma de Pagamento</span>
                  <hr />
                </PaymentTitle>
                <List>
                  {orderPaymentsList.map(
                    ({
                      formaPagamento,
                      situacao,
                      parcelamento,
                      valorParcelas,
                      id,
                    }) => (
                      <PaymentItem key={id}>
                        <div className="left">
                          <span className="title">{formaPagamento.nome}</span>
                          <p>
                            <span
                              className={
                                situacao === 'Pago' ? 'tefOk' : 'tefPending'
                              }
                            >
                              {situacao}
                            </span>
                          </p>
                          <span>{parcelamento.exibicao}</span>
                          {` ${maskCurrency(String(valorParcelas || 0))}`}
                        </div>
                        {situacao === PaymentStatus.payed && (
                          <ButtonTef
                            onClick={() => handleReversePaymentTef(id)}
                          >
                            Estornar
                          </ButtonTef>
                        )}
                        {situacao === PaymentStatus.cashBack && (
                          <ButtonTef
                            onClick={() => handleFinalizeReversePaymentTef(id)}
                          >
                            Tentar Novamente
                          </ButtonTef>
                        )}
                      </PaymentItem>
                    ),
                  )}
                </List>
              </PaymentContainer>
            </>
          )}
          {!isMobile && showModal && (
            <ModalTef
              open={showModal}
              onClose={handleCloseModal}
              disableEscapeKeyDown
              disableBackdropClick
            >
              <ModalContainer>
                <ModalTop>
                  <CloseIcon onClick={handleCloseModal} />
                  <p className="title">MS TEF</p>
                </ModalTop>

                <iframe title="MS TEF" src={tefAtendimentoUri} />
              </ModalContainer>
            </ModalTef>
          )}
          {isMobile && showModal && (
            <Container>
              <Title>
                <span>MS TEF</span>
              </Title>
              <hr />
              <MobileIframe title="MS TEF" src={tefAtendimentoUri} />
            </Container>
          )}
        </Content>
      </Layout>
      <Footer
        handleCloseModal={handleCloseModal}
        onClickForward={handleCloseModal}
      />
    </>
  );
};

export default Tef;
