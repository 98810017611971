import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

import theme from '../../utils/themes';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

export const materialTheme = createMuiTheme({
  palette: {
    primary: grey,
  },

  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.palette.yellow._600.hex(),
      },
      toolbarTxt: {
        color: theme.palette.white.main.hex(),
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: theme.palette.yellow._600.hex(),
        color: theme.palette.white.main.hex(),
      },
      iconButton: {
        backgroundColor: theme.palette.yellow._600.hex(),
        color: theme.palette.white.main.hex(),
      },
    },
    MuiPickersDay: {
      day: {
        color: theme.palette.grey._600.hex(),
      },
      daySelected: {
        backgroundColor: theme.palette.yellow._600.hex(),
        color: theme.palette.white.main.hex(),
      },
      dayDisabled: {
        color: theme.palette.grey._300.hex(),
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: theme.palette.yellow._600.hex(),
      },
    },
  },
});

export const Container = styled.div`
  && {
    label {
      font-weight: 600;
    }
  }
`;
