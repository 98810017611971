import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import api from '../../../services/apiClient';
import {
  DataItinerario,
  getCep,
  ItineraryProps,
  ShippingProps,
  TypeAddressArray,
} from '../../../services/address';
import { useStepForm } from '../../../hooks/StepForm';
import { useOrder } from '../../../hooks/OrderContext';
import { StepIndex, DeliveryType } from '../../../hooks/types';

import TextField from '../../TextField';
import { Mask } from '../../TextField/types';
import Autocomplete from '../../Autocomplete';
import { useToast } from '../../../hooks/Toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Container,
  DeliveryTitle,
  ZipCode,
  Address,
  Number,
  Complement,
  District,
  City,
  State,
  Itinerary,
  ItineraryError,
  Observations,
  Shipping,
  TypeAddress,
} from './styles';
import { saveOrderInfo } from '../../../services/order';
import { maskCep } from '../../../services/masks';
import Loader from '../../Loader';
import { formatMoneyToText } from '../../../utils/formatMoney';
import { SimpleSelect } from '../..';

interface DeliveryFormData {
  referencePoint: string;
  itinerary: {
    text: string;
    value: string;
  };
  shipping: string;
  zipCodeScope: {
    zipCode: string;
  };
  addressContainerScope: {
    typeAddressScope: {
      typeAddress: string;
    };
    addressScope: {
      address: string;
    };
    numberScope: {
      number: string;
    };
  };
  districtContainerScope: {
    complement: '';
    districtScope: {
      district: string;
    };
  };
  stateContainerScope: {
    cityScope: {
      city: string;
    };
    stateScope: {
      state: string;
    };
  };
}

const DeliveryForm: React.FC<{
  setFormRef: (el: RefObject<FormHandles>) => void;
}> = ({ setFormRef }) => {
  const history = useHistory();
  const { order, setOrder } = useOrder();

  const { setFormData, step, getFormData, getNextStep } = useStepForm();

  const [isValidZipCode, setIsValidZipCode] = useState(false);
  const [itinerary, setItinerary] = useState(true);
  const [itineraryOptions, setItineraryOptions] = useState<ItineraryProps[]>(
    [],
  );
  const [unservedZipCodeError, setUnservedZipCodeError] = useState('');
  const [shippingValues, setShippingValues] = useState<ShippingProps[]>([]);
  const [shippingValue, setShippingValue] = useState<number | null>(null);
  const [isAddressZipCode, setIsAddressZipCode] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const toast = useToast();

  const updateShipping = (shipping: number | null | undefined): void => {
    !shipping || shipping === 0
      ? setShippingValue(null)
      : setShippingValue(shipping);
  };

  const getCepData = useCallback(async (value: string): Promise<void> => {
    const formattedCep = value.replaceAll(/[^0-9]/g, '');

    if (formattedCep?.length !== 8) {
      setIsValidZipCode(false);
      formRef.current?.reset();
    }

    setIsSearchLoading(true);

    try {
      setIsValidZipCode(true);

      const { endereco, itinerarios } = await getCep(formattedCep);

      setIsSearchLoading(false);

      const itinerarySelect = itinerarios.map((item: DataItinerario) => ({
        value: item.id,
        text: item.nome,
      }));

      if (itinerarySelect) {
        setItineraryOptions(itinerarySelect);
      }
      setItinerary(!!itinerarySelect);

      const shipping = itinerarios.map((item: DataItinerario) => ({
        itineraryId: item.id,
        value: item.valorEntrega,
      }));

      setShippingValues(shipping);

      if (itinerarySelect.length === 1) {
        const shippingValueId = shipping.find(
          item => item.itineraryId === itinerarySelect[0].value,
        );

        updateShipping(shippingValueId?.value);
      }

      setIsAddressZipCode(!!endereco.logradouro && !!endereco.bairro);

      if (!itinerarios) {
        formRef.current?.reset();
      }

      const tipoEndereco = TypeAddressArray.find(
        item => item.valueLabel === endereco.tipo,
      );

      formRef.current?.setData({
        addressContainerScope: {
          typeAddressScope: {
            typeAddress: tipoEndereco?.value || 'R',
          },
          addressScope: {
            address: endereco.logradouro,
          },
        },
        districtContainerScope: {
          districtScope: {
            district: endereco.bairro,
          },
        },
        stateContainerScope: {
          cityScope: {
            city: endereco.cidade,
          },
          stateScope: {
            state: endereco.uf,
          },
        },
      });
    } catch (error) {
      if (axios.isCancel(error)) return;

      if (error?.response.status >= 400 && error?.response.status < 500) {
        setUnservedZipCodeError(error?.response.data.detail);
      }
      setIsValidZipCode(false);
      setItinerary(false);
      setIsSearchLoading(false);

      formRef.current?.reset();
    }
  }, []);

  const getInitialValues = useCallback(async (): Promise<void> => {
    const initialValues = getFormData<DeliveryType | undefined>(
      StepIndex.DELIVERY,
    );

    if (!initialValues?.cep && order) {
      const { entregas } = order;

      if (entregas.length === 0) return;
      const currentDelivery = entregas[0];
      const { endereco, itinerario } = currentDelivery;

      setFormData(StepIndex.DELIVERY, {
        pedido: {
          entregas: [
            {
              id: currentDelivery.id,
            },
          ],
        },
        cep: endereco.cep,
        endereco: endereco.logradouro,
        tipoLogradouro: endereco.tipoLogradouro,
        numero: endereco.numero,
        complemento: endereco.complemento,
        bairro: endereco.bairro,
        frete: itinerario.valorEntrega,
        itinerario: {
          text: itinerario.nome,
          value: itinerario.id,
        },
        observacao: endereco.pontoReferencia,
      });

      updateShipping(itinerario.valorEntrega);
      return;
    }

    if (!initialValues?.cep) return;

    await getCepData(initialValues.cep.toString());

    updateShipping(initialValues.frete);

    formRef.current?.setData({
      zipCodeScope: {
        zipCode: maskCep(initialValues.cep),
      },
      itinerary: {
        text: initialValues.itinerario.text,
        value: initialValues.itinerario.value,
      },
      referencePoint: initialValues.observacao,
      addressContainerScope: {
        typeAddressScope: {
          typeAddress: initialValues.tipoLogradouro,
        },
        numberScope: {
          number: initialValues.numero,
        },
        addressScope: {
          address: initialValues.endereco,
        },
      },
      districtContainerScope: {
        complement: initialValues.complemento,
        districtScope: {
          district: initialValues.bairro,
        },
      },
      stateContainerScope: {
        cityScope: {
          city: initialValues.cidade,
        },
        stateScope: {
          state: initialValues.estado,
        },
      },
    });
  }, [getFormData, setFormData, order, getCepData]);

  useEffect(() => {
    getInitialValues();
  }, [getInitialValues]);

  useEffect(() => {
    setFormRef(formRef);
  }, [setFormRef]);

  const onChangeZipCode = useCallback(
    async (event: any): Promise<void> => {
      const { value } = event.target;

      if (value.length === 10) {
        await getCepData(value);
      }
    },
    [getCepData],
  );

  const handleSelectItinerary = useCallback(
    async (value: number): Promise<void> => {
      const currentShippingValue = shippingValues.find(
        item => item.itineraryId === value,
      );

      if (!currentShippingValue) return;

      updateShipping(currentShippingValue.value);
    },
    [shippingValues],
  );

  const handleSubmit = useCallback(
    async (data: DeliveryFormData): Promise<void> => {
      try {
        const schema = Yup.object().shape({
          zipCodeScope: Yup.object().shape({
            zipCode: Yup.string()
              .matches(/[0-9]{2}.[0-9]{3}-[0-9]{3}/)
              .required('CEP não informado'),
          }),
          addressContainerScope: Yup.object().shape({
            typeAddressScope: Yup.object().shape({
              typeAddress: Yup.string().required(
                'Tipo de logradouro não informado',
              ),
            }),
            addressScope: Yup.object().shape({
              address: Yup.string().required('Logradouro não informado'),
            }),
            numberScope: Yup.object().shape({
              number: Yup.string().max(15),
            }),
          }),
          districtContainerScope: Yup.object().shape({
            complement: Yup.string(),
            districtScope: Yup.object().shape({
              district: Yup.string().required('Bairro não informado'),
            }),
          }),
          stateContainerScope: Yup.object().shape({
            cityScope: Yup.object().shape({
              city: Yup.string().required('Cidade não informada'),
            }),
            stateScope: Yup.object().shape({
              state: Yup.string().required('Estado Não informado'),
            }),
          }),
          itinerary: Yup.mixed().test({
            name: 'required',
            message: 'Itinerário não informado',
            exclusive: true,
            test: value => value.value !== null,
          }),
          referencePoint: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const orderData = {
          entrega: {
            itinerarioId: data.itinerary.value,
            endereco: {
              cep: parseInt(
                data.zipCodeScope.zipCode.replaceAll(/[^0-9]/g, ''),
                10,
              ),
              tipoLogradouro:
                data.addressContainerScope.typeAddressScope.typeAddress,
              bairro: data.districtContainerScope.districtScope.district,
              logradouro: data.addressContainerScope.addressScope.address,
              numero: data.addressContainerScope.numberScope.number,
              complemento: data.districtContainerScope.complement,
              pontoReferencia: data.referencePoint,
            },
          },
        };

        const addressData = {
          cep: parseInt(
            data.zipCodeScope.zipCode.replaceAll(/[^0-9]/g, ''),
            10,
          ),
          tipoLogradouro:
            data.addressContainerScope.typeAddressScope.typeAddress,
          endereco: data.addressContainerScope.addressScope.address,
          numero: data.addressContainerScope.numberScope.number,
          complemento: data.districtContainerScope.complement,
          bairro: data.districtContainerScope.districtScope.district,
          cidade: data.stateContainerScope.cityScope.city,
          estado: data.stateContainerScope.stateScope.state,
          frete: shippingValue,
          itinerario: data.itinerary,
          observacao: data.referencePoint,
        };

        try {
          if (order) {
            const pedido = order;

            const deliveryData = {
              pedido: {
                id: pedido.id,
                version: pedido.version,
              },
              entregaId: pedido.entregas[0].id && {
                id: pedido.entregas[0].id,
              },
              ...orderData,
            };

            const response = await api.post(
              '/pdv/pedido/entrega/gerar',
              deliveryData,
            );

            const formData = {
              pedido: {
                entregas: [
                  {
                    id: pedido.entregas[0].id,
                  },
                ],
              },
              ...addressData,
            };
            setOrder(response.data);
            saveOrderInfo(response.data.id, response.data.version);
            history.push(getNextStep(step.key)?.url);
            setFormData(StepIndex.DELIVERY, formData);
            return;
          }
        } catch (error) {
          if (error?.response?.status !== 404) {
            toast.addToast({
              title: 'Erro ao gerar entrega',
              type: 'error',
              description: error?.response?.data?.detail,
            });
          }
        }

        try {
          const response = await api.post('/pdv/pedido/criar', orderData);

          const formData = {
            pedido: {
              entregas: [
                {
                  id: response.data.entregas[0].id,
                },
              ],
            },
            ...addressData,
          };
          setOrder(response.data);
          saveOrderInfo(response.data.id, response.data.version);
          history.push(getNextStep(step.key)?.url);
          setFormData(StepIndex.DELIVERY, formData);
        } catch (error) {
          toast.addToast({
            title: 'Erro ao criar pedido',
            type: 'error',
            description: error?.response?.data?.detail,
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          Object.values(errors).forEach(fieldError => {
            toast.addToast({
              type: 'error',
              title: 'Erro',
              description: fieldError,
            });
          });

          formRef.current?.setErrors(errors);
        } else {
          toast.addToast({
            type: 'error',
            title: 'Erro',
            description: error?.response?.data?.detail,
          });
        }
      }
    },
    [
      step.key,
      history,
      getNextStep,
      setFormData,
      toast,
      setOrder,
      order,
      shippingValue,
    ],
  );

  return (
    <Container>
      <DeliveryTitle className="deliveryTitle">
        <LocalShippingOutlinedIcon />
        <span>Dados de entrega</span>
      </DeliveryTitle>
      <hr />
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        onKeyPress={e =>
          e.code === 'Enter' &&
          handleSubmit(formRef.current?.getData() as DeliveryFormData)
        }
      >
        <Scope path="zipCodeScope">
          <ZipCode className="zipCode">
            <TextField
              label="CEP"
              name="zipCode"
              required
              autoFocus
              variationStyle="outlined"
              mask={Mask.cep}
              length={10}
              onChange={event => onChangeZipCode(event)}
              autoComplete="off"
            />
            <a
              href={process.env.REACT_APP_BUSCA_CEP_URI}
              target="_blank"
              rel="noreferrer"
            >
              Não sabe o CEP?
            </a>
          </ZipCode>
          {isSearchLoading && (
            <div className="loading">
              <Loader />
            </div>
          )}
        </Scope>
        {itinerary ? (
          <>
            <Scope path="addressContainerScope">
              <div className="address">
                <Scope path="typeAddressScope">
                  <TypeAddress>
                    <SimpleSelect
                      id="type"
                      label="Tipo Logradouro"
                      name="typeAddress"
                      options={TypeAddressArray}
                    />
                  </TypeAddress>
                </Scope>
                <div className="addressLogNumber">
                  <Scope path="addressScope">
                    <Address>
                      <TextField
                        label="Logradouro"
                        name="address"
                        required
                        variationStyle="outlined"
                        mask={Mask.none}
                        length={20}
                        disabled={isAddressZipCode}
                        autoComplete="off"
                      />
                    </Address>
                  </Scope>
                  <Scope path="numberScope">
                    <Number>
                      <TextField
                        label="Número"
                        name="number"
                        variationStyle="outlined"
                        mask={Mask.none}
                        length={15}
                        autoComplete="off"
                      />
                    </Number>
                  </Scope>
                </div>
              </div>
            </Scope>
            <Scope path="districtContainerScope">
              <div className="complement">
                <Complement>
                  <TextField
                    label="Complemento"
                    name="complement"
                    variationStyle="outlined"
                    mask={Mask.none}
                    length={20}
                    autoComplete="off"
                  />
                  <span className="optional">opcional</span>
                </Complement>
                <Scope path="districtScope">
                  <District>
                    <TextField
                      label="Bairro"
                      name="district"
                      required
                      variationStyle="outlined"
                      mask={Mask.none}
                      length={20}
                      disabled={isAddressZipCode}
                      autoComplete="off"
                    />
                  </District>
                </Scope>
              </div>
            </Scope>
            <Scope path="stateContainerScope">
              <div className="complement">
                <Scope path="cityScope">
                  <City>
                    <TextField
                      label="Cidade"
                      name="city"
                      required
                      variationStyle="outlined"
                      mask={Mask.none}
                      length={20}
                      disabled
                      autoComplete="off"
                    />
                  </City>
                </Scope>
                <Scope path="stateScope">
                  <State>
                    <TextField
                      id="estado"
                      label="Estado"
                      name="state"
                      disabled
                      length={2}
                      variationStyle="outlined"
                    />
                  </State>
                </Scope>
              </div>
            </Scope>
            <div>
              <Itinerary>
                <Autocomplete
                  label="Itinerário"
                  name="itinerary"
                  required
                  id="itinerary"
                  fieldValue={
                    itineraryOptions.length === 1 ? itineraryOptions[0] : null
                  }
                  placeholder="Selecione"
                  noOptionText="Nenhum itinerário disponível"
                  optionsRequest={() => itineraryOptions}
                  onSelectOption={(_, value) => {
                    handleSelectItinerary(value?.value);
                  }}
                  disabled={isValidZipCode === false}
                />
              </Itinerary>
              {shippingValue && (
                <Shipping>
                  <p>
                    <strong>
                      Frete: &nbsp;
                      {formatMoneyToText(shippingValue)}
                    </strong>
                  </p>
                </Shipping>
              )}
            </div>

            <Observations>
              <TextField
                label="Observações"
                name="referencePoint"
                multiline
                mask={Mask.none}
                length={3000}
                variationStyle="outlined"
              />
              <span className="optional">opcional</span>
            </Observations>
          </>
        ) : (
          <ItineraryError title={unservedZipCodeError}>
            <Autocomplete
              hasError
              disabled
              name="itinerary"
              label="Itinerário"
            />
          </ItineraryError>
        )}
      </Form>
    </Container>
  );
};

export default DeliveryForm;
