import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Container, StyledTextField } from './styles';
import { Mask } from '../TextField/types';
import {
  any,
  maskCep,
  maskCPF,
  maskCurrency,
  maskDate,
  maskName,
  maskPhone,
  onlyNumbers,
} from '../../services/masks';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

export interface SelectProps {
  value?: any;
  text: string;
  noOptions?: boolean;
}

interface AutoCompleteProps extends InputProps {
  label?: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  hasError?: boolean;
  required?: boolean;
  freeSolo?: boolean;
  mask?: Mask;
  fieldValue?: SelectProps | null;
  autoFocus?: boolean;
  customOptions?: React.ReactNode;
  closeIcon?: React.ReactNode;
  noOptionText?: string;
  optionsRequest?: () => SelectProps[];
  asyncOptionsRequest?: (search: string) => Promise<SelectProps[]>;
  onSelectOption?: (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: SelectProps | null,
  ) => void;
  clearText?: string;
  noFilter?: boolean;
  onChange?:
    | ((
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => void)
    | undefined;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  name,
  label,
  id,
  placeholder,
  disabled,
  required,
  closeIcon,
  mask,
  fieldValue,
  freeSolo,
  autoFocus,
  hasError,
  noOptionText = 'Sem opção disponível',
  optionsRequest,
  asyncOptionsRequest,
  onSelectOption,
  clearText,
  readOnly,
  noFilter,
  onChange,
}) => {
  const [value, setValue] = React.useState<SelectProps | null>(null);
  const [options, setOptions] = React.useState<SelectProps[]>([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [inputError, setInputError] = React.useState(false);

  const { fieldName, registerField, error } = useField(name);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => value || { value: null, text: '' },
      setValue: (_, newValue: SelectProps) =>
        newValue && !fieldValue && setValue(newValue),
      clearValue: () => {
        setValue(null);
      },
    });
    setInputError(Boolean(error));
  }, [fieldName, registerField, value, fieldValue, error]);

  useEffect(() => {
    setValue(fieldValue || null);
  }, [fieldValue]);

  useEffect(() => {
    if (optionsRequest) {
      setOptions(optionsRequest());
    }

    if (!open && !optionsRequest) {
      setOptions([]);
      return;
    }
    setLoading(!asyncOptionsRequest);
    setInputError(false);
  }, [asyncOptionsRequest, open, optionsRequest]);

  const masks: Record<Mask, (value: string) => string> = {
    [Mask.none]: any,
    [Mask.onlyNumber]: onlyNumbers,
    [Mask.cep]: maskCep,
    [Mask.currency]: maskCurrency,
    [Mask.cpf]: maskCPF,
    [Mask.phoneNumber]: maskPhone,
    [Mask.birthday]: maskDate,
    [Mask.nameUpperCase]: maskName,
  };

  const formatValue = (str: string): string => (mask ? masks[mask](str) : str);

  const filter = createFilterOptions<SelectProps>();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): any => {
    const customValue: string = formatValue(event.currentTarget.value);
    setValue({ value: customValue, text: formatValue(customValue) });
    setInputError(false);

    onChange &&
      onChange({
        ...event,
        target: {
          ...event.target,
          value: customValue,
        },
        currentTarget: {
          ...event.currentTarget,
          value: customValue,
        },
      } as React.ChangeEvent<HTMLInputElement>);
  };

  if (readOnly) {
    return (
      <Container>
        <label htmlFor={id}>{label}</label>
        <StyledTextField
          id={fieldName}
          name={fieldName}
          inputRef={inputRef}
          variant="outlined"
          size="small"
          value={value?.text}
          hasError={hasError ?? inputError}
          placeholder={placeholder}
          required={required}
          InputProps={{ disabled: true }}
        />
      </Container>
    );
  }

  return (
    <Container>
      <label htmlFor={id}>{label}</label>
      <Autocomplete
        id={id}
        selectOnFocus
        clearOnBlur={false}
        handleHomeEndKeys
        disabled={disabled}
        open={open}
        value={value}
        freeSolo={freeSolo}
        closeIcon={closeIcon}
        clearText={clearText}
        noOptionsText={noOptionText}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={(event: any, newValue: any) => {
          const valueOption =
            typeof newValue === 'string' ? newValue : newValue?.text;

          setValue({ text: valueOption || '', value: newValue?.value });
          !!onSelectOption && onSelectOption(event, newValue);
        }}
        options={options}
        filterOptions={(option, params) => {
          const filtered = noFilter ? option : filter(option, params);
          if (params.inputValue !== '' && freeSolo) {
            filtered.push({
              value: params.inputValue,
              text: formatValue(params.inputValue),
            });
          }
          return filtered;
        }}
        getOptionLabel={option => option.text || ''}
        loading={loading}
        renderInput={params => (
          <StyledTextField
            {...params}
            id={fieldName}
            name={fieldName}
            inputRef={inputRef}
            variant="outlined"
            size="small"
            value={value}
            disabled={disabled}
            autoFocus={autoFocus}
            hasError={hasError ?? inputError}
            placeholder={placeholder}
            required={required}
            onChange={async event => {
              if (asyncOptionsRequest) {
                setLoading(true);
                setOptions(await asyncOptionsRequest(event.target.value));
                setLoading(false);
                return;
              }
              if (!options.length && !asyncOptionsRequest && optionsRequest) {
                setLoading(false);
                setOptions(optionsRequest());
              }
              freeSolo && handleChange(event);
            }}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </Container>
  );
};

export default AutoComplete;
