import styled, { css } from 'styled-components';

import { GlobalContainer } from '../../../utils/GlobalStyles';
import TextField from '../../TextField';

interface ErrorProps {
  error?: boolean;
  errorText?: string;
}

export const Container = styled(GlobalContainer)`
  && {
    .MuiFormControl-root {
      width: 100%;
    }
  }
`;

export const CustomerTitle = styled.div`
  && {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 10px;
    place-content: center;

    font-size: 20px;
    font-weight: 700;

    span {
      align-self: center;
      margin-left: 11px;
    }

    svg {
      border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
      border-radius: 50px;
      height: 22px;
      width: 22px;
      padding: 3px;
      background-color: ${({ theme }) => theme.palette.grey._50.hex()};
    }

    @media screen and (min-width: 769px) {
      place-content: initial;
    }
  }
`;

export const CpfCnpjContainer = styled.div`
  && {
    margin-top: 28px;
    margin-bottom: 35px;

    label {
      padding-bottom: 8px;
    }

    .MuiFormHelperText-root {
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 400;

      margin: 0;
      margin-top: 15px;
      padding: 15px 20px 15px 20px;

      background: ${({ theme }) => theme.palette.pink._100.hex()};
      color: ${({ theme }) => theme.palette.red._700.hex()};

      /* Triângulo do HelperText */
      &::before {
        content: '';
        position: absolute;
        border-style: solid;
        border-color: ${({ theme }) => theme.palette.pink._100.hex()}
          transparent;
        border-width: 0 6px 6px 6px;
        bottom: 50%;
      }
    }

    @media screen and (min-width: 769px) {
      max-width: 569px;
      margin-bottom: 0px;
    }
  }
`;

export const CustomerTextField = styled(TextField)<ErrorProps>`
  ${props =>
    props.error === true &&
    css`
      label {
        color: ${({ theme }) => theme.palette.grey._250.hex()};
      }
    `}
`;

export const CustomerData = styled.div`
  && {
    margin-top: 28px;
    margin-bottom: 35px;

    label {
      margin-top: 18px;
    }

    input {
      transform: translateY(-5px);
    }

    @media screen and (min-width: 769px) {
      max-width: 569px;
      margin-bottom: 0px;
    }
  }
`;

export const DateContainer = styled.div`
  && {
    margin-top: 18px;
    margin-bottom: 35px;

    .MuiOutlinedInput-root {
      height: 42px;
    }
    input {
      padding: 15px 12px 10px;
    }
  }
`;

export const Loading = styled.div`
  && {
    margin-top: 20px;
  }
`;
