import { Factory } from 'miragejs';
import faker from 'faker';
import { format } from 'date-fns';

faker.locale = 'pt_BR';

export default {
  produto: Factory.extend({
    prdno() {
      return '111111';
    },
    titulo() {
      return faker.random.word();
    },
    grades() {
      return [
        {
          grade: 'Branco',
          titulo: faker.random.word(),
          disponibilidade: format(faker.date.future(), 'yyyy-mm-dd'),
          valores: [
            {
              tipo: 'Padrão',
              origem: faker.random.number(10).toString(),
              valor: faker.random.number(500000),
            },
            {
              tipo: 'Promoção',
              origem: faker.random.number(10).toString(),
              valor: faker.random.number(500000),
            },
          ],
        },
        {
          grade: 'Preto',
          titulo: faker.random.word(),
          disponibilidade: format(faker.date.future(), 'yyyy-mm-dd'),
          valores: [
            {
              tipo: 'Padrão',
              origem: faker.random.number(10).toString(),
              valor: faker.random.number(500000),
            },
            {
              tipo: 'Promoção',
              origem: faker.random.number(10).toString(),
              valor: faker.random.number(500000),
            },
          ],
        },
      ];
    },
    montagem() {
      return faker.random.boolean();
    },
    fatorConversao() {
      return faker.random.number({ min: 1, max: 4 });
    },
  }),
};
