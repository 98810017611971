import { Factory } from 'miragejs';
import faker from 'faker';
import { format, formatISO } from 'date-fns';

faker.locale = 'pt_BR';

export default {
  pedido: Factory.extend({
    id() {
      return faker.random.number(99);
    },
    version() {
      return formatISO(new Date());
    },
    situacao() {
      return 'Em Aberto';
    },
    criadoEm() {
      return '2020-01-01T09:11:28+00:00';
    },
    pagoEm() {
      return '2020-01-01T09:11:28+00:00';
    },
    valorTotalProdutos() {
      return 155876;
    },
    valorTotalFrete() {
      return 0;
    },
    valorEmAberto() {
      return 155876;
    },
    valorVenda() {
      return 155876;
    },
    cliente() {
      return {
        cpfCnpj: '397.197.110-51',
        nome: faker.name.findName(),
        telefone: faker.phone.phoneNumber(),
        dataNascimento: format(new Date(faker.date.past()), 'yyyy-MM-dd'),
      };
    },
    pagamentos() {
      return [];
    },
    entregas() {
      return [];
    },
    itens() {
      return [
        {
          produto: {
            prdno: '111111',
            grade: 'Branco',
          },
          titulo: 'Ventilador',
          valorOriginal: {
            origem: '1',
            precoUnitario: 40045,
          },
          fatorConversao: 2,
          quantidade: 2,
          disponibilidade: '2020-03-12',
          precoUnitario: 40045,
          precoTotal: 80090,
          valorTotalDescontos: 0,
          valorTotalFrete: 0,
          valorTotalServicos: 0,
          descontos: [
            {
              tipo: 'LOGISTICA_DESCONTO_NAO_MONTAR',
              nome: 'Desconto Não Montar',
              valorUnitario: 1201,
            },
          ],
          acrescimos: [
            {
              tipo: 'JUROS_CARTAO_TEF',
              nome: 'Juros Parcelamento via Mastercard',
              valorUnitario: 125,
            },
          ],
          valorTotal: 77938,
        },
        {
          produto: {
            prdno: '222222',
            grade: 'Preto',
          },
          titulo: 'Guarda Roupas',
          valorOriginal: {
            origem: '1',
            precoUnitario: 40045,
          },
          fatorConversao: 1,
          quantidade: 2,
          disponibilidade: '2020-03-12',
          precoUnitario: 40045,
          precoTotal: 80090,
          valorTotalDescontos: 0,
          valorTotalFrete: 0,
          valorTotalServicos: 0,
          descontos: [
            {
              tipo: 'LOGISTICA_DESCONTO_NAO_MONTAR',
              nome: 'Desconto Não Montar',
              valorUnitario: 1201,
            },
          ],
          acrescimos: [
            {
              tipo: 'JUROS_CARTAO_TEF',
              nome: 'Juros Parcelamento via Mastercard',
              valorUnitario: 125,
            },
          ],
          valorTotal: 77938,
        },
      ];
    },
  }),
};
