import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { useAuth } from '../../hooks/Auth';
import { useStepForm } from '../../hooks/StepForm';
import { Steps, StepPageKey } from '../../hooks/types';

import Header from '../Header';
import ProgressBar from '../ProgressBar';

import { Container, Breadcrumb, BackwardButton } from './styles';
import { useScreen } from '../../hooks/Screen';

type Props = {
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const { user, signOut } = useAuth();
  const { step } = useStepForm();
  const history = useHistory();
  const { isMobile } = useScreen();

  const [hasHeader, setHasHeader] = useState(false);
  const [hasMobileHeader, setHasMobileHeader] = useState(false);
  const [hasDesktopHeader, setHasDesktopHeader] = useState(false);
  const [isConfirmationPage, setIsConfirmationPage] = useState(false);

  useEffect(() => {
    setHasHeader(step.key === StepPageKey.delivery || !isMobile);
    setHasMobileHeader(step.key === StepPageKey.delivery && isMobile);
    setHasDesktopHeader(step.key >= StepPageKey.delivery && !isMobile);
    setIsConfirmationPage(step.key === StepPageKey.details);
  }, [isMobile, step.key]);

  const previousStep = useCallback(() => {
    const previousPage = Object.values(Steps)[step.key - 1];

    return history.push(previousPage?.url);
  }, [history, step.key]);

  return (
    <>
      {(hasMobileHeader || hasDesktopHeader) && (
        <Header name={user?.nome || ''} logout={signOut} />
      )}

      <Breadcrumb>
        <BackwardButton
          hasHeader={hasHeader}
          isConfirmationPage={isConfirmationPage}
          onClick={() => {
            previousStep();
          }}
          className="backwardButton"
        >
          <ArrowBackIosIcon />
        </BackwardButton>

        <ProgressBar />
      </Breadcrumb>

      <Container>{children}</Container>
    </>
  );
};

export default Layout;
