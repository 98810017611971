import React, { useEffect } from 'react';

import { useStepForm } from '../../hooks/StepForm';
import { Steps } from '../../hooks/types';

import InformationList from '../../components/InformationList';

import Layout from '../../components/Layout';

const Confirmation: React.FC = () => {
  const { setStep } = useStepForm();

  useEffect(() => setStep(Steps.details), [setStep]);

  return (
    <>
      <Layout>
        <InformationList />
      </Layout>
    </>
  );
};

export default Confirmation;
