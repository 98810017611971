import { Response, Server } from 'miragejs';
import { format, addDays } from 'date-fns';
import { AppSchema } from '../models/index';
import { detailOrder } from './utils';

export default (server: Server): void => {
  server.post('logistica/estimarDataEntregaMontagem', async () => {
    return new Response(
      200,
      {},
      {
        estimativas: [
          {
            itinerario: {
              id: 999,
              nome: 'SANTA MARTHA - VITORIA',
            },
            dataEntregaMinima: format(addDays(new Date(), 3), 'yyyy-MM-dd'),
            dataMontagemMinima: format(addDays(new Date(), 6), 'yyyy-MM-dd'),
            dataEntregaMaxima: format(addDays(new Date(), 10), 'yyyy-MM-dd'),
            dataMontagemMaxima: format(addDays(new Date(), 15), 'yyyy-MM-dd'),
          },
        ],
      },
    );
  });

  server.post(
    'pdv/pedido/entrega/agendar',
    async (schema: AppSchema, request) => {
      const body = JSON.parse(request.requestBody);

      const pedido = schema.find('pedido', body.pedido.id);

      if (!pedido) {
        return new Response(200, {}, detailOrder);
      }

      const itinerario = await schema.first('itinerario');

      pedido.entregas[0].dataEntrega = body.dataEntregaAgendada;
      pedido.entregas[0].dataMontagem = body.dataMontagemAgendada;
      await pedido.save();

      return new Response(
        200,
        {},
        {
          ...pedido.attrs,
          entregas: [{ ...pedido.entregas[0], itinerario: itinerario?.attrs }],
        },
      );
    },
  );
};
