/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unresolved */
import { Registry } from 'miragejs';
import Schema from 'miragejs/orm/schema';

import { EnderecoModel, ItinerarioModel } from './endereco';
import { PedidoModel } from './pedido';
import { ProdutoModel } from './produto';
import { ClienteModel } from './cliente';
import { PagamentoModel } from './pagamento';

export type AppRegistry = Registry<
  {
    endereco: typeof EnderecoModel;
    itinerario: typeof ItinerarioModel;
    pedido: typeof PedidoModel;
    cliente: typeof ClienteModel;
    produto: typeof ProdutoModel;
  },
  {}
>;

export type AppSchema = Schema<AppRegistry>;

export default {
  itinerario: ItinerarioModel,
  endereco: EnderecoModel,
  pedido: PedidoModel,
  cliente: ClienteModel,
  produto: ProdutoModel,
  pagamento: PagamentoModel,
};
