import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  .title {
    /* width: 160px; */
    background: ${({ theme }) => theme.palette.pink._100.hex()};
    padding: 8px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;
    visibility: visible;

    position: absolute;
    top: 55px;
    left: 50%;
    transform: translateX(-100%);

    color: #312e38;

    /* Triângulo do span */
    &::before {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: ${({ theme }) => theme.palette.pink._100.hex()} transparent;
      border-width: 0 6px 6px 6px;
      bottom: 20px;
      bottom: 100%;
      transform: translateX(50%);
    }
  }
`;
