export function formatMoneyToText(money: number): string {
  const formatedValue = (money / 100).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatedValue;
}

export function formatMoneyToFloat(money: number): number {
  return money / 100;
}

export function formatStringMoneyToNumber(value: string): number {
  return Number(value.replace(/\D+/g, ''));
}

export function formatMoneyFromFloat(money: number): number {
  return money * 100;
}
