import produto from './produto';
import pedido from './pedido';
import endereco from './endereco';
import itinerario from './itinerario';
import cliente from './cliente';
import pagamentos from './pagamentos';
import simularParcelamento from './simularParcelamento';

export default {
  ...endereco,
  ...pedido,
  ...itinerario,
  ...produto,
  ...pedido,
  ...cliente,
  ...pagamentos,
  ...simularParcelamento,
};
