import styled from 'styled-components';

export const Container = styled.div``;

export const Ball = styled.div`
  && {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 8px;
    margin: 3px;

    animation-name: animateBalls;
    animation-fill-mode: both;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    :nth-child(1) {
      animation-duration: 0.95s;
      animation-delay: 0;
      background: ${({ theme }) => theme.palette.white.main.hex()};
    }

    :nth-child(2) {
      animation-duration: 0.95s;
      animation-delay: 0.12s;
      background: ${({ theme }) => theme.palette.black.main.hex()};
    }

    :nth-child(3) {
      animation-duration: 0.95s;
      animation-delay: 0.24s;
      background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
    }

    @keyframes animateBalls {
      0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
      }
      16% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0.7;
      }
      33% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
      }
    }

    @media screen and (min-width: 769px) {
      && {
        width: 14px;
        height: 14px;
      }

      :nth-child(1) {
        animation-duration: 0.95s;
        animation-delay: 0;
        background: ${({ theme }) => theme.palette.grey._220.hex()};
      }
    }
  }
`;
