/* eslint-disable react/require-default-props */
import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { StyledFormControl, StyledSelect } from './styles';

type ValueType = string | number | number[] | string[];

interface ISelectProps<T extends ValueType> {
  name: string;
  id: string;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  options: Array<{
    value: T;
    valueLabel: string | number;
  }>;
  onChange?: (value: T | undefined) => void;
  autoFocus?: boolean;
  fieldValue?: T;
}

function SimpleSelect<T extends ValueType>({
  name,
  id,
  placeholder,
  label,
  disabled,
  options,
  onChange,
  fieldValue,
  ...rest
}: ISelectProps<T>): JSX.Element {
  const innerOptions = options.map(o => ({
    ...o,
    key: o.value instanceof Array ? JSON.stringify(o.value) : String(o.value),
  }));

  const [selectedValue, setSelectedValue] = React.useState<string>(
    (fieldValue && innerOptions.find(o => o.value === fieldValue)?.key) || '',
  );

  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, registerField, error } = useField(name);

  const [inputError, setInputError] = React.useState(false);

  const handleChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const key = event.target.value as string;
      setSelectedValue(key);
      setInputError(false);

      !!onChange && onChange(innerOptions.find(o => o.key === key)?.value);
    },
    [innerOptions, onChange],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => ref.value,
      setValue: (_, value: string) => setSelectedValue(value),
      clearValue: () => setSelectedValue(''),
    });
    setInputError(Boolean(error));
  }, [fieldName, registerField, error]);

  return (
    <StyledFormControl focused={false} variant="outlined">
      <label htmlFor={name}>{label}</label>
      <StyledSelect
        name={name}
        id={id}
        inputRef={selectRef}
        {...rest}
        autoWidth
        error={inputError}
        displayEmpty
        variant="outlined"
        tabIndex={0}
        native
        value={selectedValue}
        onChange={handleChange}
        onClose={() => null}
        disabled={disabled}
        autoFocus={!!rest.autoFocus}
      >
        <option
          id="placeholder"
          disabled
          value=""
          selected={selectedValue === undefined}
        >
          {placeholder}
        </option>
        {innerOptions.map(({ key, value, valueLabel }) => (
          <option key={key} value={key}>
            {valueLabel || value}
          </option>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
}

export default SimpleSelect;
