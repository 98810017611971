import { Response, Server } from 'miragejs';

export default (server: Server): void => {
  server.post('auth/perfil', async () => {
    return new Response(
      200,
      {},
      {
        id: 456,
        login: 'simonetti',
        nome: 'Vendedor Simonetti',
        loja: {
          id: 104,
          nome: 'PI',
        },
      },
    );
  });
};
