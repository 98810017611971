/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unresolved */
import { Factory } from 'miragejs';
import faker from 'faker';

faker.locale = 'pt_BR';

const parcelas = faker.random.number(12);

export default {
  data: Factory.extend({
    id() {
      return faker.random.number(99);
    },
    exibicao() {
      return `${parcelas}x`;
    },
    numeroParcelas() {
      return parcelas;
    },
  }),
};
