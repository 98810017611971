import styled, { css } from 'styled-components';
import { Button as MuiButton, IconButton } from '@material-ui/core';

import { GlobalContainer } from '../../../utils/GlobalStyles';
import Button from '../../Button';
import { MOBILE_SCREEN, TABLET_SCREEN } from '../../../utils/screenSizes';

interface DisabledLabel {
  disabled?: boolean;
}

export const Container = styled(GlobalContainer)<DisabledLabel>`
  && {
    form {
      .MuiFormControl-root {
        width: 100%;
      }
      width: 100%;
    }

    ${props =>
      props.disabled === true &&
      css`
        label {
          color: ${({ theme }) => theme.palette.grey._250.hex()};
        }
      `}

    @media screen and (min-width: ${TABLET_SCREEN - 2}px) {
      select {
        width: 419px;
      }
      form {
        max-width: 50%;
        padding-right: 20px;
      }

      .MuiInputBase-root {
        width: 100%;
        max-width: 465px;
      }
    }

    @media screen and (max-width: ${MOBILE_SCREEN}px) {
      padding: 0;
      > div:first-child {
        padding: 0 29px;
      }
      form {
        padding: 0 15px;
      }
      padding-bottom: 20px;
      margin-bottom: 0px;
      margin-top: 54px;
    }
    @media screen and (min-width: ${MOBILE_SCREEN +
      1}px) and (max-width: ${TABLET_SCREEN}px) {
      margin-top: 115px;
    }
  }
`;

export const Content = styled.div`
  @media screen and (min-width: ${TABLET_SCREEN - 1}px) {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    padding: 15px;
  }
`;

export const ProductsTitle = styled.div`
  && {
    display: flex;
    flex-direction: row;
    place-content: left;

    font-size: 20px;
    font-weight: 700;

    span {
      align-self: center;
      margin-left: 11px;
    }

    svg {
      border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
      border-radius: 50px;
      height: 22px;
      width: 22px;
      padding: 3px;
      background-color: ${({ theme }) => theme.palette.grey._50.hex()};
    }

    @media screen and (min-width: ${TABLET_SCREEN - 2}) {
      place-content: initial;
    }
  }
`;

export const Grid = styled.div`
  && {
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

export const Price = styled.div`
  && {
    margin-bottom: 20px;
  }
`;

export const Amount = styled.div`
  && {
    margin-bottom: 10px;
  }
`;

export const Mounting = styled.div`
  && {
    margin-bottom: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: ${MOBILE_SCREEN + 1}px) {
    display: flex;
    position: fixed;
    justify-content: space-between;
    margin: 0 10px;
    width: 90vw;
    bottom: 10px;
    z-index: 3;
    margin: 0 auto;
  }
`;

export const CancelButton = styled(Button)`
  width: 120px;
  background-color: ${({ theme }) => theme.palette.white._50.hex()};
  color: ${({ theme }) => theme.palette.yellow._600.hex()};
  border: 1px solid ${({ theme }) => theme.palette.yellow._600.hex()};

  &:disabled {
    opacity: 0.5;
  }
`;

export const SaveButton = styled(Button)`
  && {
    display: block;
    width: 120px;
    cursor: pointer;

    height: 40px;
    border: 1px;

    font-weight: 600;
    font-size: 16px;

    background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
    color: ${({ theme }) => theme.palette.white._50.hex()};

    margin-left: 10px;

    &:hover {
      opacity: 80%;
      transition: opacity 0.5s;
    }

    &:disabled {
      opacity: 0.5;
    }
  }
`;

export const DesktopSearchProduct = styled.div`
  && {
    margin-top: 20px;
    position: relative;
    a {
      font-weight: 400;
      color: ${({ theme }) => theme.palette.grey._600.hex()};
      position: absolute;
      right: 15px;
    }
  }
`;

export const NoProductsContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    order: 2;
    height: 80vh;

    img {
      max-width: 70px;
      margin-bottom: 15px;
    }
    p {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.grey._500.hex()};
    }
    @media screen and (min-width: ${MOBILE_SCREEN}px) {
      height: 72vh;
    }
    @media screen and (min-width: ${TABLET_SCREEN}px) {
      height: 60vh;
    }
  }
`;

export const AddProductButton = styled(MuiButton)`
  && {
    height: 48px;
    border-radius: 4px;
    text-decoration: none;

    font-weight: 600;
    font-size: 14px;
    text-transform: none;
    padding: 0 30px;
    margin-top: 40px;

    background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
    color: ${({ theme }) => theme.palette.white._50.hex()};

    &:hover {
      background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
    }

    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.5;
    }
    @media screen and (max-width: ${MOBILE_SCREEN}px) {
      position: fixed;
      bottom: 10px;
      width: 95%;
      z-index: 3;
      cursor: pointer;
      margin: 0 auto;
    }
  }
`;

export const ProductsList = styled.div`
  && {
    order: 2;
    padding-top: 8px;
    background-color: ${({ theme }) => theme.palette.grey._50.hex()};

    > div:first-child {
      overflow: auto;
      max-height: calc(100vh - 243px);
    }
    @media screen and (min-width: ${TABLET_SCREEN - 1}px) {
      width: 48%;
      > div:first-child {
        height: 400px;
        overflow: auto;
      }
    }
  }
`;
export const ProductItem = styled.div`
  && {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 18px 25px 18px 10px;
    max-width: 490px;

    background-color: ${({ theme }) => theme.palette.white.main.hex()};

    @media screen and (max-width: ${MOBILE_SCREEN}px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export const RemoveItem = styled(IconButton)`
  && {
    margin-right: 15px;
    color: ${({ theme }) => theme.palette.grey._500.hex()};
  }
`;
export const ProductItemTitle = styled.p`
  && {
    font-size: 16px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
export const ProductItemDetails = styled.div`
  && {
    width: 95%;
    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      > span {
        font-weight: 600;
      }
    }
  }
`;

export const ProductItemInfo = styled.div`
  && {
    p {
      margin: 10px 0;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      > span {
        font-size: 14px;
        font-weight: normal !important;
      }
    }
    line-height: 17px;
    font-size: 14px;
    span {
      font-size: 16px;
    }
    div:first-child span {
      font-weight: 600;
    }
  }
`;
export const ProductItemPrice = styled.div`
  && {
    line-height: 12px;
    > span {
      color: ${({ theme }) => theme.palette.green._700.hex()};
    }
  }
`;

export const ProductsListFooter = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    border-top: 2px solid ${({ theme }) => theme.palette.grey._200.hex()};
    background-color: ${({ theme }) => theme.palette.white.main.hex()};

    max-height: 60px;
    font-weight: 400;

    span,
    button {
      font-weight: 600;
    }
    button {
      text-transform: capitalize;
      color: ${({ theme }) => theme.palette.yellow._600.hex()};
    }
    button:disabled {
      opacity: 0.6;
    }
    @media screen and (max-width: ${MOBILE_SCREEN}px) {
      position: fixed;
      width: 100%;
      bottom: 72px;
    }
  }
`;

export const EditItem = styled.div`
  && {
    position: absolute;
    right: 15px;
    bottom: 10px;
  }
`;

export const ProductsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 10px;
  margin-right: 27px;

  span,
  button {
    font-weight: 600;
  }
  button {
    text-transform: capitalize;
    color: ${({ theme }) => theme.palette.yellow._600.hex()};
  }
  button:disabled {
    opacity: 0.6;
  }

  @media screen and (max-width: ${TABLET_SCREEN}px) {
    margin-right: 0;
  }
`;
