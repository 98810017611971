const any = (value: string): string => {
  return value;
};

const onlyNumbers = (value: string | number): any => {
  if (value === '') {
    return value;
  }
  return value.toString().replace(/[^0-9]+/g, '');
};

const maskCep = (value: string | number): any => {
  const number = onlyNumbers(value);

  if (number.length <= 3) {
    return number;
  }

  return number
    .substr(0, 8)
    .replace(/([0-9]{2})([0-9]{3})([0-9]{1,3})/, '$1.$2-$3');
};

export const maskCPF = (value: string | number): string => {
  const number = onlyNumbers(value);

  if (number.length <= 3) {
    return number;
  }

  if (number.length <= 6) {
    return number.replace(/([0-9]{3})([0-9]{1,3})/, '$1.$2');
  }

  if (number.length <= 9) {
    return number.replace(/([0-9]{3})([0-9]{3})([0-9]{1,3})/, '$1.$2.$3');
  }

  return number
    .substr(0, 11)
    .replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{1,2})/, '$1.$2.$3-$4');
};

const maskDate = (date: string | number): string => {
  const unmasked = onlyNumbers(date);

  if (unmasked.length < 3) {
    return unmasked;
  }

  if (unmasked.length < 5) {
    return `${unmasked.slice(0, 2)}/${unmasked.slice(2, 4)}`;
  }

  return `${unmasked.slice(0, 2)}/${unmasked.slice(2, 4)}/${unmasked.slice(
    4,
    8,
  )}`;
};

export const maskName = (value: string): string => value.toUpperCase();

const maskCurrency = (value: string): string =>
  new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(onlyNumbers(value)) / 100);

const maskPhone = (phone: string): string => {
  return onlyNumbers(phone)
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{2})(\d)/, '$1) $2')
    .replace(/(\d{4})(\d{1,4})/, '$1-$2')
    .replace(/(\d{5})(\d{5})/, '$1-$2')
    .replace(/(-\d{5})\d+?$/, '$1')
    .replace(/(\d{4})-(\d{1})(\d{4})/, '$1$2-$3');
};

export { any, onlyNumbers, maskCep, maskDate, maskCurrency, maskPhone };
