import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import { KeyboardDatePicker } from '@material-ui/pickers';

import { ThemeProvider } from '@material-ui/core/styles';
import { CalendarToday } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, subYears } from 'date-fns';

import { materialTheme, Container } from './styles';

interface DatePickerProps {
  name: string;
  initialValue?: Date | null;
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  label?: string;
  required?: boolean | undefined;
  id?: string;
  maxDateMessage?: string | undefined;
  inputVariant?: 'standard' | 'filled' | 'outlined';
  disabled?: boolean;
  onChange?: (
    currentDate: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  initialValue,
  minDate = subYears(new Date(), 100),
  maxDate,
  maxDateMessage,
  label,
  required,
  id,
  inputVariant = 'outlined',
  disabled,
  onChange,
}) => {
  const datePickerRef = useRef(null);
  const [date, setDate] = useState<Date | null>(initialValue || null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePickerRef.current,
      getValue: () => date,
      setValue: (ref, newValue: Date | null) => newValue && setDate(newValue),
      clearValue: () => {
        setDate(null);
      },
    });
  }, [date, fieldName, registerField]);

  const handleChange = useCallback(
    (currentDate, value): void => {
      setDate(currentDate);
      onChange && onChange(currentDate, value);
    },
    [onChange],
  );

  return (
    <ThemeProvider theme={materialTheme}>
      <Container>
        <label htmlFor={name}>{label}</label>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          inputVariant={inputVariant}
          format="dd/MM/yyyy"
          margin="normal"
          id={id}
          name={name}
          value={date}
          required={required}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={`A data escolhida não corresponde a data mínima ${
            minDate instanceof Date ? format(minDate, 'dd/MM/yyyy') : '.'
          }`}
          maxDateMessage={
            maxDateMessage ||
            `A data informada é após a data máxima ${
              maxDate instanceof Date ? format(maxDate, 'dd/MM/yyyy') : '.'
            }`
          }
          invalidDateMessage="A data precisa estar no formato dia/mes/ano. Ex: 21/02/2023"
          keyboardIcon={<CalendarToday />}
          autoOk
          disabled={disabled}
        />
      </Container>
    </ThemeProvider>
  );
};

export default DatePicker;
