import React from 'react';

import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';
import { StepFormProvider } from './StepForm';
import { ScreenProvider } from './Screen';
import { OrderProvider } from './OrderContext';

const HookProvider: React.FC = ({ children }): any =>
  [
    ToastProvider,
    AuthProvider,
    StepFormProvider,
    ScreenProvider,
    OrderProvider,
  ].reduce(
    (currentChildren, Provider) => <Provider>{currentChildren}</Provider>,
    children,
  );

export default HookProvider;
