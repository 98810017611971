import { Response, Server } from 'miragejs';
import { AppSchema } from '../models';

export default (server: Server): void => {
  server.post('produto/detalhar', async (schema: AppSchema, request) => {
    const body = JSON.parse(request.requestBody);

    const produto = schema.findBy('produto', { prdno: body.prdno });

    if (!produto) {
      return new Response(404, {}, { error: 'Inválid prdno' });
    }

    return new Response(
      200,
      {},
      {
        ...produto?.attrs,
      },
    );
  });
};
