import React, { useEffect, useState, useCallback } from 'react';
import { format } from 'date-fns';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import PaymentIcon from '@material-ui/icons/Payment';
import axios, { CancelTokenSource } from 'axios';
import { useHistory } from 'react-router';
import { History } from 'history';
import {
  clearStorage,
  getDetailOrder,
  getSavedOrder,
  isOrderCompleted,
  Order,
} from '../../services/order';
import { formatMoneyToText } from '../../utils/formatMoney';
import {
  maskCPF,
  maskPhone,
  maskCep,
  maskCurrency,
} from '../../services/masks';

import mountTool from '../../assets/images/mount_tool.svg';

import {
  Container,
  Success,
  StepContent,
  Content,
  Title,
  Itinerary,
  Product,
  ButtonContainer,
} from './styles';
import Loader from '../Loader';
import { Steps } from '../../hooks/types';
import { useToast } from '../../hooks/Toast';
import { useOrder } from '../../hooks/OrderContext';

interface ParamsId extends History {
  id: number;
}
const InformationList: React.FC = () => {
  const history = useHistory<ParamsId>();
  const { addToast } = useToast();
  const { setOrder: setContextOrder } = useOrder();

  const [order, setOrder] = useState<Order | null>(null);
  const [formattedDelivery, setFormattedDelivery] = useState('');
  const [formattedMounting, setFormattedMounting] = useState('');
  const [formattedBirthday, setFormattedBirthday] = useState('');
  const [formattedCep, setFormattedCep] = useState('');
  const [formattedCpfCnpj, setFormattedCpfCnpj] = useState('');
  const [formattedPhone, setFormattedPhone] = useState('');

  const formattedData = (orderData: Order): void => {
    const formatDelivery = format(
      new Date(orderData.entregas[0].dataEntrega.replace(/[-]/g, '/')),
      'dd/MM/yyyy',
    );
    setFormattedDelivery(formatDelivery);

    const formatMounting = orderData.entregas[0].dataMontagem
      ? format(
          new Date(orderData.entregas[0].dataMontagem.replace(/[-]/g, '/')),
          'dd/MM/yyyy',
        )
      : '-';

    setFormattedMounting(formatMounting);

    const formatBirthday = format(
      new Date(orderData.cliente.dataNascimento.replace(/[-]/g, '/')),
      'dd/MM/yyyy',
    );
    setFormattedBirthday(formatBirthday);

    const formatCpfCnpj = maskCPF(orderData.cliente.cpfCnpj);
    setFormattedCpfCnpj(formatCpfCnpj);

    const formatPhone = maskPhone(orderData.cliente.telefone);
    setFormattedPhone(formatPhone);

    const formatCep = maskCep(orderData.entregas[0].endereco.cep.toString());
    setFormattedCep(formatCep);
  };

  const handleClearLocalOrderData = useCallback((): void => {
    clearStorage();
    setContextOrder(null);
  }, [setContextOrder]);

  const getDetailedOrderData = useCallback(
    async (orderId: number, cancelToken?: CancelTokenSource): Promise<void> => {
      const orderData = await getDetailOrder(
        {
          pedido: {
            id: orderId,
          },
        },
        cancelToken,
      );

      if (isOrderCompleted(orderData) === false) {
        history.push(Steps.delivery.url);
        return;
      }

      handleClearLocalOrderData();
      setOrder(orderData);
      formattedData(orderData);
    },
    [handleClearLocalOrderData, history],
  );

  const getOrder = useCallback(
    async (cancelToken?: CancelTokenSource): Promise<void> => {
      const orderId = history.location.hash.replace(/[^0-9]+/g, '');

      try {
        if (orderId) {
          await getDetailedOrderData(Number(orderId), cancelToken);
        } else {
          const { pedido } = await getSavedOrder();
          await getDetailedOrderData(pedido.id, cancelToken);
        }
      } catch (error) {
        addToast({
          title: 'Erro ao buscar detalhes do pedido',
          type: 'error',
          description: error?.response?.data?.detail,
        });
      }
    },
    [history, getDetailedOrderData, addToast],
  );

  const handleNewOrder = (): void => {
    history.push(Steps.delivery.url);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getOrder(cancelToken);
    return () => cancelToken.cancel();
  }, [getOrder]);

  return (
    <Container>
      {!order ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (
        <>
          <Success>
            <DoneAllIcon />
            <div className="successText">
              <span className="sellSuccess">Venda efetuada com sucesso!</span>
              <span>
                pedido
                <span className="order">{` N° #${order?.id}`}</span>
              </span>
            </div>
          </Success>

          <StepContent>
            <h1>Dados de Entrega</h1>
            <hr className="horizontalRow" />

            <Content>
              <Title>Endereço</Title>
              <span>
                {`
              ${order?.entregas[0].endereco.tipoLogradouro}
               ${order?.entregas[0].endereco.logradouro},
               ${order?.entregas[0].endereco.numero || 'S/N'},
               ${order?.entregas[0].endereco.complemento || ''} -
               ${formattedCep} -
               ${order?.entregas[0].endereco.bairro}
             `}
              </span>

              <Itinerary>
                <Title>Itinerário</Title>
                <span>{order?.entregas[0].itinerario.nome}</span>

                <Title>Bairros</Title>
                <span>{order?.entregas[0].itinerario.descricao}</span>
              </Itinerary>
              {order.valorTotalFrete > 0 && (
                <div className="amount">
                  <Title>Valor do frete</Title>
                  <span>{formatMoneyToText(order?.valorTotalFrete)}</span>
                  <hr className="inputRow" />
                </div>
              )}
            </Content>
          </StepContent>

          <StepContent>
            <h1>Produtos</h1>
            <hr className="horizontalRow" />

            <Content>
              {order?.itens.map(item => (
                <Product key={item.produto.prdno}>
                  <Title>{`${item.produto.prdno} - ${item.titulo}`}</Title>
                  <div className="contentTable">
                    <span className="grade">{item.produto.grade}</span>
                    <span className="preco">
                      {`${formatMoneyToText(item.precoUnitario)}`}
                    </span>
                    <span className="quantidade">
                      {`Quantidade: ${item.quantidade}`}
                    </span>
                    <span className="total">
                      {`${formatMoneyToText(item.precoTotal)}`}
                    </span>
                    <div className="montagem">
                      <img src={mountTool} alt="montagem" />
                      <span>{item.montagem ? 'Montar' : 'Não Montar'}</span>
                    </div>
                  </div>
                  <hr className="productLine" />
                </Product>
              ))}

              <div className="totalAmount">
                <Title>Valor Total</Title>
                <span>{`${formatMoneyToText(order?.valorVenda)}`}</span>
              </div>
            </Content>
          </StepContent>

          <StepContent>
            <h1>Formas de Pagamento</h1>
            <hr className="horizontalRow" />
            <Content>
              {order?.pagamentos.map(
                ({
                  id,
                  metodoPagamento,
                  formaPagamento,
                  parcelamento,
                  valorPago,
                }) => {
                  return (
                    <div className="payment" key={id}>
                      <div className="paymentTitle">
                        <PaymentIcon />
                        <Title>
                          {`${metodoPagamento.descricao}
                          ${formaPagamento.nome} em
                          ${parcelamento.exibicao}.`}
                        </Title>
                      </div>
                      <span className="paymentTotal">
                        {maskCurrency(valorPago.toString())}
                      </span>
                    </div>
                  );
                },
              )}
            </Content>
          </StepContent>

          <StepContent>
            <h1>Entrega e Montagem</h1>
            <hr className="horizontalRow" />

            <Content className="groupInfo">
              <div className="info">
                <Title>Entrega à partir de</Title>
                <span>{formattedDelivery}</span>
                <hr className="inputRow" />
              </div>
              <div className="info">
                <Title>Montagem à partir de</Title>
                <span>{formattedMounting}</span>
                <hr className="inputRow" />
              </div>
            </Content>
          </StepContent>

          <StepContent>
            <h1>Cliente</h1>
            <hr className="horizontalRow" />

            <Content className="customer">
              <div className="info">
                <Title>CPF</Title>
                <span>{formattedCpfCnpj}</span>
                <hr className="inputRow" />
              </div>
              <div className="info">
                <Title>Nome</Title>
                <span>{order?.cliente.nome}</span>
                <hr className="inputRow" />
              </div>
              <div className="groupInfo">
                <div className="info">
                  <Title>Telefone</Title>
                  <span>{formattedPhone}</span>
                  <hr className="inputRow" />
                </div>
                <div className="info">
                  <Title>Data de nascimento</Title>
                  <span>{formattedBirthday}</span>
                  <hr className="inputRow" />
                </div>
              </div>
            </Content>
          </StepContent>

          <ButtonContainer>
            <button type="button" onClick={() => handleNewOrder()}>
              Nova Venda
            </button>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default InformationList;
