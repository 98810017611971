/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable import/no-unresolved */
import { ModelDefinition } from 'miragejs/-types';
import { belongsTo, Model } from 'miragejs';
import { Endereco } from './endereco';

export type PedidoEndereco = Omit<Endereco, 'id'> & {
  tipoLogradouro: string;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string;
  pontoReferencia: string;
};

type Pagamento = {
  id: number;
  metodoPagamento: {
    id: number;
    descricao: string;
  };
  formaPagamento: {
    id: number;
    nome: string;
  };
  parcelamento: {
    id: number;
    exibicao: string;
  };
  valorPago: number;
  numeroParcelas: number;
  valorParcelas: number;
  detalhamentoProdutos: [
    {
      produto: {
        prdno: string;
        grade: string;
      };
      valorUnitario: string;
    },
  ];
  tef: any;
};

export type Pedido = {
  id: number;
  version: string;
  situacao: string;
  criadoEm: string;
  pagoEm?: string;
  valorEmAberto: number;
  pagamentos: Pagamento[];
  entregas: {
    id: number;
    endereco: PedidoEndereco;
    itinerarioId: number;
    dataEntrega: string;
    dataMontagem: string;
  }[];
  requisicoesTef?: {
    status?: string;
    tipo?: number;
    dadosEnvio: Record<string, unknown>;
    dadosRetorno: Record<string, unknown>;
  }[];
};

export const PedidoModel: ModelDefinition<Pedido> = Model.extend({
  entregas: [
    {
      itinerario: belongsTo(),
    },
  ],
});
