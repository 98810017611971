import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal } from '@material-ui/core';
import Button from '../../components/Button';
import { GlobalContainer } from '../../utils/GlobalStyles';

export const Container = styled(GlobalContainer)``;

export const Title = styled.div`
  && {
    margin-top: 16px;
    margin-bottom: 10px;

    font-size: 20px;
    font-weight: 700;

    margin-left: 11px;
  }
`;

export const Content = styled.div`
  && {
    position: fixed;
    z-index: 4;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 14px;
    font-weight: 600;

    hr {
      font-family: 'Montserrat', sans-serif;
      height: 1px;
      max-width: 967.5px;
      background: ${({ theme }) => theme.palette.grey._400.hex()};
      border: none;
    }

    @media screen and (min-width: 769px) {
      position: relative;

      display: block;
      margin-top: 75px;
      max-width: 1024px;
      min-height: 735px;
      padding-left: 29px;
      background: ${({ theme }) => theme.palette.white.main.hex()};

      border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
      box-sizing: border-box;
      border-radius: 4px;

      hr {
        margin: 11px 27.5px 0 0;
      }
    }
  }
`;

export const PaymentContainer = styled.div`
  width: 100%;
  margin-top: 60px;
  padding: 10px;

  @media screen and (max-width: 769px) {
    background-color: ${({ theme }) => theme.palette.grey._50.hex()};
    margin-top: 80px;
  }
`;

export const PaymentTitle = styled.div`
  && {
    width: 90%;

    span {
      align-self: center;
      font-size: 16px;
      color: ${({ theme }) => theme.palette.grey._600.hex()};
    }

    hr {
      width: 100%;
    }

    @media screen and (min-width: 769px) {
      place-content: initial;
    }
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;

  && {
    span {
      margin-left: 11px;
    }
  }
`;

export const Table = styled.table`
  margin-top: 30px;
  table-layout: auto;
  width: 90%;

  border-spacing: 0px;

  tr {
    height: 60px;
  }

  th {
    padding: 0 14px 0 14px;
  }

  td {
    text-align: left;
    padding: 0 14px 0 14px;
  }

  .dataRow {
    font-size: 16px;
    font-weight: 500;
  }

  .dataRow:nth-child(odd) {
    background: ${({ theme }) => theme.palette.grey._50.hex()};
  }
`;

export const ButtonTef = styled(Button)`
  && {
    display: block;
    cursor: pointer;

    height: 40px;
    border: 1px;
    padding-left: 1rem;
    padding-right: 1rem;

    font-weight: 600;
    font-size: 16px;

    background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
    color: ${({ theme }) => theme.palette.white._50.hex()};

    &:hover {
      opacity: 80%;
      transition: opacity 0.5s;
    }
  }
`;

export const List = styled.div`
  background-color: ${({ theme }) => theme.palette.grey._50.hex()};
  padding: 8px 0;
`;

export const PaymentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 23px;
  min-height: 90px;

  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.07);

  .left {
    line-height: 35px;

    .tefPending {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.yellow._600.hex()};
    }
    .tefOk {
      font-weight: 600;
      color: ${({ theme }) => theme.palette.green._400.hex()};
    }
  }

  .title {
    font-size: 16px;
  }

  span {
    font-weight: 600;
  }

  .installments {
    text-decoration: underline;
    margin-left: auto;
    margin-right: 10px;
  }
`;

export const MobileIframe = styled.iframe`
  margin-top: 10px;
  border: none;
  width: 100%;
  height: 400px;
`;

export const ModalTef = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: 432px;
  max-width: 710px;
  padding: 0 4px 10px;

  background-color: ${({ theme }) => theme.palette.white._50.hex()};
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  position: relative;
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.palette.black.main.hex()};
  font-weight: 500;

  svg {
    align-self: flex-end;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    :hover {
      color: ${({ theme }) => lighten(0.6, theme.palette.black.main.hex())};
      transition: color 0.5s;
    }
  }

  iframe {
    width: 100%;
    max-width: 710px;
    height: 390px;
    border: 0;
  }
`;

export const ModalTop = styled.div`
  padding: 7px 10px;
  min-height: 32px;

  p.title {
    color: ${({ theme }) => theme.palette.black.main.hex()};
    font-weight: bold;
    line-height: normal;
  }
`;
