import styled from 'styled-components';
import { shade, lighten } from 'polished';
import { Dialog } from '@material-ui/core';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 5px;
  }
`;

export const ModalContainer = styled.div`
  && {
    min-width: 272px;
    max-width: 320px;
    width: 31vw;
    height: 200px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 5px;
      margin-right: 5px;
      cursor: pointer;

      :hover {
        color: ${({ theme }) => lighten(0.6, theme.palette.black.main.hex())};
        transition: color 0.5s;
      }

      :focus {
        color: ${({ theme }) => lighten(0.6, theme.palette.black.main.hex())};
      }
    }
  }
`;

export const QuestionText = styled.span`
  text-align: center;
  margin-top: 21px;

  font-weight: 500;
`;

export const ButtonContainer = styled.div`
  align-self: center;
  margin-top: 35px;

  .buttonYes,
  .buttonNo {
    height: 40px;
    width: 109px;
    margin: 3.5px;
    border-radius: 4px;
    cursor: pointer;

    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    size: 14px;

    :focus {
      outline: none;
    }
  }

  .buttonYes {
    background-color: ${({ theme }) => theme.palette.white._50.hex()};
    border: 1px solid ${({ theme }) => theme.palette.grey._400.hex()};

    color: ${({ theme }) => theme.palette.grey._500.hex()};

    :hover {
      background: ${({ theme }) => shade(0.03, theme.palette.white._50.hex())};
      transition: background 0.5s;
    }
    :focus {
      background: ${({ theme }) => shade(0.03, theme.palette.white._50.hex())};
    }
  }

  .buttonNo {
    background-color: ${({ theme }) => theme.palette.green._300.hex()};
    border: none;

    color: ${({ theme }) => theme.palette.white._50.hex()};

    :hover {
      background: ${({ theme }) => shade(0.1, theme.palette.green._300.hex())};
      transition: background 0.5s;
    }
    :focus {
      background: ${({ theme }) => shade(0.1, theme.palette.green._300.hex())};
    }
  }
`;
