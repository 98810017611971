import axios, { CancelTokenSource } from 'axios';
import api from '../apiClient';

export interface ItineraryProps {
  value?: any;
  text: string;
}

export interface ShippingProps {
  itineraryId: number;
  value: number;
}

export const optionsState = [
  { value: 'AC', valueLabel: 'AC' },
  { value: 'AL', valueLabel: 'AL' },
  { value: 'AP', valueLabel: 'AP' },
  { value: 'AM', valueLabel: 'AM' },
  { value: 'BA', valueLabel: 'BA' },
  { value: 'CE', valueLabel: 'CE' },
  { value: 'DF', valueLabel: 'DF' },
  { value: 'ES', valueLabel: 'ES' },
  { value: 'GO', valueLabel: 'GO' },
  { value: 'MA', valueLabel: 'MA' },
  { value: 'MT', valueLabel: 'MT' },
  { value: 'MS', valueLabel: 'MS' },
  { value: 'MG', valueLabel: 'MG' },
  { value: 'PA', valueLabel: 'PA' },
  { value: 'PB', valueLabel: 'PB' },
  { value: 'PR', valueLabel: 'PR' },
  { value: 'PE', valueLabel: 'PE' },
  { value: 'PI', valueLabel: 'PI' },
  { value: 'RJ', valueLabel: 'RJ' },
  { value: 'RN', valueLabel: 'RN' },
  { value: 'RS', valueLabel: 'RS' },
  { value: 'RO', valueLabel: 'RO' },
  { value: 'RR', valueLabel: 'RR' },
  { value: 'SC', valueLabel: 'SC' },
  { value: 'SP', valueLabel: 'SP' },
  { value: 'SE', valueLabel: 'SE' },
  { value: 'TO', valueLabel: 'TO' },
];

export type UF =
  | 'AL'
  | 'AC'
  | 'AP'
  | 'AM'
  | 'BA'
  | 'CE'
  | 'DF'
  | 'ES'
  | 'GO'
  | 'MA'
  | 'MT'
  | 'MS'
  | 'MG'
  | 'PA'
  | 'PB'
  | 'PR'
  | 'PE'
  | 'PI'
  | 'RJ'
  | 'RN'
  | 'RS'
  | 'RO'
  | 'RR'
  | 'SC'
  | 'SP'
  | 'SE'
  | 'TO';

export type Logradouro = 'RUA';

export type DataItinerario = {
  id: number;
  nome: string;
  descricao: string;
  ofereceMontagem: boolean;
  descontoNaoMontar: number;
  bairros: string[];
  valorEntrega: number;
};

export type DataCEP = {
  endereco: {
    id: string;
    cep: number;
    uf: UF;
    cidade: string;
    bairro?: string;
    tipo?: string;
    logradouro?: string;
  };
  itinerarios: DataItinerario[];
};

export type TypeAddressOptions = {
  value: string;
  valueLabel: string;
};

export const TypeAddressArray = [
  { value: 'AER', valueLabel: 'AEROPORTO' },
  { value: 'AL', valueLabel: 'ALAMEDA' },
  { value: 'AP', valueLabel: 'APARTAMENTO' },
  { value: 'AV', valueLabel: 'AVENIDA' },
  { value: 'BC', valueLabel: 'BECO' },
  { value: 'BL', valueLabel: 'BLOCO' },
  { value: 'CAM', valueLabel: 'CAMINHO' },
  { value: 'CORG', valueLabel: 'CORREGO' },
  { value: 'ESCD', valueLabel: 'ESCADINHA' },
  { value: 'EST', valueLabel: 'ESTACAO' },
  { value: 'ETR', valueLabel: 'ESTRADA' },
  { value: 'FAZ', valueLabel: 'FAZENDA' },
  { value: 'FORT', valueLabel: 'FORTALEZA' },
  { value: 'GL', valueLabel: 'GELEIRA' },
  { value: 'KM', valueLabel: 'QUILOMETRO' },
  { value: 'LD', valueLabel: 'LADEIRA' },
  { value: 'LGO', valueLabel: 'LARGO' },
  { value: 'PCA', valueLabel: 'PRACA' },
  { value: 'PR', valueLabel: 'PRAIA' },
  { value: 'PRQ', valueLabel: 'PARQUE' },
  { value: 'QD', valueLabel: 'QUADRA' },
  { value: 'QTA', valueLabel: 'QUINTA' },
  { value: 'R', valueLabel: 'RUA' },
  { value: 'ROD', valueLabel: 'RODOVIA' },
  { value: 'SQD', valueLabel: 'SUPER QUADRA' },
  { value: 'TRV', valueLabel: 'TRAVESSA' },
  { value: 'VD', valueLabel: 'VIADUTO' },
  { value: 'VL', valueLabel: 'VILA' },
];

let requestCancelToken: CancelTokenSource | undefined;

export const getCep = async (formattedCep: string): Promise<DataCEP> => {
  if (requestCancelToken !== undefined) {
    requestCancelToken.cancel();
  }

  requestCancelToken = axios.CancelToken.source();

  const { data } = await api.post(
    `logistica/buscarOpcoesEntrega`,
    {
      cep: formattedCep,
    },
    {
      cancelToken: requestCancelToken.token,
    },
  );
  return data;
};
