import { Server } from 'miragejs';

import factories from './factories';
import routes from './routes';
import models from './models';
import seeds from './seeds';

const configuration = (environment: string): any => {
  const config: any = {
    environment,
    factories,
    models,
    routes,
    seeds,
  };

  config.urlPrefix = 'http://api.mspdv.mspdv.test/api/v1';

  return config;
};

export function makeServer({ environment = 'development' } = {}): Server {
  return new Server(configuration(environment));
}
