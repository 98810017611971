import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import AddBox from '@material-ui/icons/AddBox';
import { useHistory } from 'react-router';
import { Button, IconButton } from '@material-ui/core';
import { format } from 'date-fns';
import {
  Amount,
  Container,
  Grid,
  Mounting,
  Price,
  ProductsTitle,
  SaveButton,
  DesktopSearchProduct,
  NoProductsContainer,
  AddProductButton,
  ProductsList,
  ProductItem,
  ProductItemTitle,
  ProductItemDetails,
  RemoveItem,
  ProductItemInfo,
  ProductItemPrice,
  Content,
  ProductsListFooter,
  EditItem,
  ButtonContainer,
  CancelButton,
  ProductsHeader,
} from './styles';
import TapCounter from '../../TapCounter';
import SimpleSelect from '../../SimpleSelect';
import AutoComplete from '../../Autocomplete';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useStepForm } from '../../../hooks/StepForm';
import { ProductsType, StepIndex } from '../../../hooks/types';
import {
  getProduct,
  GridInfo,
  PriceProps,
  ProductInfo,
  ProductFormData,
  ValuesInfo,
  formatCustomPrice,
} from '../../../services/product';
import {
  formatMoneyToFloat,
  formatMoneyToText,
  formatMoneyFromFloat,
} from '../../../utils/formatMoney';
import { useToast } from '../../../hooks/Toast';
import SearchBar from './SearchBar';
import {
  addProduct,
  OrderItem,
  removeProduct,
  updateProduct,
} from '../../../services/order';
import { useScreen } from '../../../hooks/Screen';
import { useOrder } from '../../../hooks/OrderContext';
import ConfirmationModal from '../../ConfirmationModal';
import { maskCurrency } from '../../../services/masks';
import Loader from '../../Loader';
import { Mask } from '../../TextField/types';

interface GridOptions {
  value: string[];
  valueLabel: string;
}

interface SelectProps {
  value?: any;
  text: string;
  noOptions?: boolean;
}

interface ProductsListTypes {
  itens: OrderItem[];
  totalValue: number;
  itensLength: number;
}

export interface SavedOrder {
  products: ProductsType[];
  productsList: ProductsListTypes;
  currentFormStep: number;
}

export type ProductDetails = {
  code: string;
  grade: string;
};

interface ModalRemoveItemProps {
  description: string;
  product: ProductDetails;
}

interface handleClearAllFieldsProps {
  type: 'add' | 'cancel';
}

enum FormStep {
  SEARCH,
  ADD,
  LIST,
  EDIT,
}

const ProductsForm: React.FC<{
  setShowFooter: Dispatch<SetStateAction<boolean>>;
  handleForward: boolean;
  setHandleForward: Dispatch<SetStateAction<boolean>>;
  setIsDisabled: (value: boolean) => void;
}> = ({ setShowFooter, handleForward, setHandleForward, setIsDisabled }) => {
  const [searchedProductCode, setSearchedProductCode] = useState<string>('');
  const [grids, setGrids] = useState<GridInfo[]>([]);
  const [selectedGrid, setSelectedGrid] = useState<GridInfo | null>();
  const [gridOptions, setGridOptions] = useState<GridOptions[]>([]);
  const [priceOptions, setPriceOptions] = useState<PriceProps[]>([]);
  const [countStep, setCountStep] = useState<number>(0);
  const [currentFormStep, setCurrentFormStep] = useState<FormStep>(
    FormStep.LIST,
  );
  const [count, setCount] = useState<number>(0);
  const [price, setPrice] = useState<SelectProps | null>(null);
  const [mounting, setMounting] = useState<boolean>(true);
  const [productOptions, setProductOptions] = useState<ProductInfo[]>([]);
  const [currentProductCode, setCurrentProductCode] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [productsList, setProductsList] = useState<ProductsListTypes>({
    itens: [],
    totalValue: 0,
    itensLength: 0,
  });

  const [
    removeItemDetails,
    setRemoveItemDetails,
  ] = useState<ModalRemoveItemProps>({
    description: '',
    product: { code: '', grade: '' },
  });

  const [showRemoveItemModal, setShowRemoveItemModal] = useState<boolean>(
    false,
  );

  const { setFormData, step, getFormData, getNextStep } = useStepForm();
  const { order, setOrder } = useOrder();
  const { isDesktop, isMobile, isTablet } = useScreen();
  const formRef = useRef<FormHandles>(null);
  const { addToast, clearToasts } = useToast();
  const history = useHistory();

  const { REACT_APP_CATALOGO_URI } = process.env;

  const getInitialValues = useCallback(async (): Promise<void> => {
    const initialValues = getFormData<SavedOrder | undefined>(
      StepIndex.PRODUCTS,
    );

    if (initialValues) {
      setProductsList(initialValues.productsList);
      setIsLoading(false);
      return;
    }

    if (order) {
      const { valorTotalProdutos, itens } = order;

      setProductsList({
        itens,
        totalValue: formatMoneyFromFloat(valorTotalProdutos),
        itensLength: order.itens.length,
      });
    }

    setCurrentFormStep(FormStep.LIST);
    setIsLoading(false);
  }, [getFormData, order]);

  const handleCloseSearchBar = (): void => {
    setCurrentFormStep(FormStep.LIST);
  };

  const resetFormData = useCallback((): void => {
    formRef.current?.reset();
    setSearchedProductCode('');
    setCurrentProductCode('');
    setSelectedGrid(null);
    setPrice(null);
    setProductOptions([]);
    setGridOptions([]);
    setPriceOptions([]);
    setCountStep(0);
    setCount(0);
  }, []);

  const saveOrderProductsForm = useCallback(
    async (
      itens: ProductsListTypes,
      productFormData?: ProductsType,
    ): Promise<void> => {
      const formData = getFormData<SavedOrder | null>(StepIndex.PRODUCTS);

      const oldProducts = formData
        ? formData?.products.filter(
            product => product.code !== productFormData?.code,
          )
        : [];

      setFormData(StepIndex.PRODUCTS, {
        products: productFormData
          ? [...oldProducts, productFormData]
          : oldProducts,
        productsList: itens,
      });
    },
    [getFormData, setFormData],
  );

  const getProductForm = useCallback(
    async ({
      prdno: productCode,
      grade: productGrid,
      product: detailedProduct,
    }: {
      prdno: string;
      grade: string;
      product: ProductInfo;
    }): Promise<ProductsType | undefined> => {
      const currentItem = productsList.itens.find(
        item =>
          item.produto.prdno === productCode &&
          item.produto.grade === productGrid,
      );

      if (!currentItem) return undefined;

      const currentGrid = detailedProduct.grades.find(
        item => item.prdnoFilho === productCode && item.grade === productGrid,
      );

      const currentValue = currentGrid?.valores.find(
        valor => valor.origem === currentItem.valorOriginal.origem,
      );

      if (!currentGrid || !currentValue) return undefined;

      const formData = {
        code: detailedProduct.prdno,
        grade: currentGrid.grade,
        preco: {
          text: `${maskCurrency(currentItem.precoUnitario.toString())} - ${
            currentValue.tipo
          }`,
          value: {
            valor: {
              tipo: currentValue.tipo,
              origem: currentValue.origem,
            },
            valorUnitario: currentItem.precoUnitario,
          },
        },
        quantidade: currentItem.quantidade,
        montagem: currentItem.montagem,
      };

      await saveOrderProductsForm(productsList, formData);

      return formData;
    },
    [productsList, saveOrderProductsForm],
  );

  const handleSubmit = useCallback(
    async (formRefData: ProductFormData): Promise<void> => {
      const formData = !formRefData.preco.value?.valor
        ? formatCustomPrice(formRefData)
        : formRefData;

      clearToasts();
      try {
        if (!order) throw new Error('Não existe um Pedido em aberto !');
        if (!selectedGrid) throw new Error('Selecione uma grade');

        const schema = Yup.object().shape({
          preco: Yup.object().shape({
            value: Yup.object()
              .required('Selecione um valor válido')
              .nullable(),
          }),
          quantidade: Yup.number().required().min(1, 'Quantidade inválida'),
          montagem: Yup.string().required('Selecione a opção de montagem'),
        });

        await schema.validate(formData, { abortEarly: false });

        const { id, version } = order;

        const productData = {
          pedido: {
            id,
            version,
          },
          produto: {
            prdno: selectedGrid.prdnoFilho,
            grade: selectedGrid.grade,
          },
          quantidade: formData.quantidade,
          montagem: Boolean(Number(formData.montagem)),
          valor: formData.preco.value.valor,
          valorUnitario: formData.preco.value.valorUnitario,
        };
        setSaveButtonDisabled(true);

        const addedToOrder =
          FormStep.ADD === currentFormStep
            ? await addProduct(productData)
            : await updateProduct(productData);

        setOrder(addedToOrder);

        const orderItens = {
          itens: addedToOrder.itens,
          totalValue: formatMoneyFromFloat(addedToOrder.valorTotalProdutos),
          itensLength: addedToOrder.itens.length,
        };

        setProductsList(orderItens);

        const formDataToSave = {
          code: searchedProductCode,
          grade: formData.grade,
          preco: {
            text: formData.preco.text,
            value: formData.preco.value,
          },
          quantidade: formData.quantidade,
          montagem: Boolean(Number(formData.montagem)),
        };
        setIsDisabled(false);
        saveOrderProductsForm(orderItens, formDataToSave);
        setCurrentFormStep(FormStep.LIST);
        resetFormData();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          Object.values(errors).forEach(fieldError => {
            addToast({
              type: 'error',
              title: 'Ocorreu um erro',
              description: fieldError,
            });
          });

          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: error.response?.data.detail,
        });
        setSaveButtonDisabled(false);
      }
    },
    [
      clearToasts,
      selectedGrid,
      order,
      currentFormStep,
      setOrder,
      searchedProductCode,
      setIsDisabled,
      saveOrderProductsForm,
      resetFormData,
      addToast,
    ],
  );

  useEffect((): void => {
    if (!selectedGrid) {
      setPriceOptions([]);
      return;
    }

    const priceSelectOptions = selectedGrid.valores.map(
      (value: ValuesInfo) => ({
        value: {
          valor: {
            tipo: value.tipo,
            origem: value.origem,
          },
          valorUnitario: value.valor,
        },
        text: String(`${maskCurrency(value.valor.toString())} - ${value.tipo}`),
      }),
    );

    setPriceOptions(priceSelectOptions);

    if (price) return;

    const defaultPrice = priceSelectOptions.find(
      item => item.value.valor.tipo === 'PADRAO',
    );
    setPrice(defaultPrice || null);
    formRef.current?.setFieldValue('preco', defaultPrice);
    setSaveButtonDisabled(false);
  }, [price, selectedGrid]);

  const handleChangeGrid = useCallback(
    (gridId): void => {
      if (!gridId) {
        setPriceOptions([]);
        formRef.current?.clearField('preco');
        return;
      }
      const [prdnoFilho, grade] = gridId;

      setPrice(null);
      setSelectedGrid(
        grids.find(
          grid => prdnoFilho === grid.prdnoFilho && grade === grid.grade,
        ),
      );
    },
    [grids],
  );

  const productNotFound = useCallback(
    (error): void => {
      if (error.response && error.response.status !== 404) {
        addToast({
          type: 'error',
          title: 'Produto não encontrado.',
          description: error.response.data.detail || '',
        });
      }

      setProductOptions([]);
      setSearchLoading(false);
    },
    [addToast],
  );

  const findProduct = useCallback(
    async (productCode: string): Promise<ProductInfo[]> => {
      if (productCode.length < 6) {
        setProductOptions([]);
        setSearchLoading(false);
        return [];
      }

      try {
        setSearchLoading(true);
        const product = await getProduct(productCode);
        setSearchLoading(false);
        setProductOptions([product]);
        setSearchedProductCode(productCode);
        return [product];
      } catch (error) {
        productNotFound(error);
        return [];
      }
    },
    [productNotFound],
  );

  const handleSearchProduct = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setSearchLoading(true);
      const currentValue = event.target.value;
      setSearchedProductCode(currentValue.replace(/[^0-9]/g, ''));

      findProduct(currentValue);
    },
    [findProduct],
  );

  const getProductFormOptions = useCallback((): void => {
    const currentProduct = productOptions.find(
      product => product.prdno === currentProductCode,
    );

    if (!currentProduct) {
      return;
    }

    setMounting(currentProduct.montagem);
    formRef.current?.setFieldValue('montagem', currentProduct.montagem ? 1 : 0);

    const { grades } = currentProduct;
    setGrids(grades);

    setGridOptions(
      currentProduct.grades.map((grade: GridInfo) => ({
        value: [grade.prdnoFilho, grade.grade],
        valueLabel: `${grade.grade} - ${grade.titulo}`,
      })),
    );
    setCountStep(currentProduct.fatorConversao);
    setCount(currentProduct.fatorConversao);
    setSearchLoading(false);
    currentFormStep === FormStep.SEARCH && setCurrentFormStep(FormStep.ADD);
  }, [currentFormStep, currentProductCode, productOptions]);

  const handleEditItem = useCallback(
    async ({
      prdno: productCode,
      grade: productGrid,
    }: {
      prdno: string;
      grade: string;
    }): Promise<void> => {
      resetFormData();

      setCurrentFormStep(FormStep.EDIT);
      formRef.current?.setFieldValue('productCode', {
        text: productCode,
        value: productCode,
      });

      setIsDisabled(true);
      const [product] = await findProduct(productCode);
      setCurrentProductCode(productCode);
      const orderProduct = await getProductForm({
        prdno: productCode,
        grade: productGrid,
        product,
      });

      if (!orderProduct) return;

      setGridOptions([
        {
          value: [productCode, orderProduct.grade],
          valueLabel: `${orderProduct.grade} - ${product.titulo}`,
        },
      ]);

      formRef.current?.setData({
        productCode: {
          text: `${productCode} - ${product?.titulo}`,
          value: productCode,
        },
        grade: [orderProduct.code, orderProduct.grade],
        quantidade: Number(orderProduct.quantidade),
        montagem: Number(orderProduct.montagem),
      });
      setPrice(orderProduct.preco);
      setCount(orderProduct.quantidade);
      setCountStep(product.fatorConversao);
      setMounting(product.montagem);
      setSelectedGrid(
        product.grades.find(
          item => item.prdnoFilho === productCode && item.grade === productGrid,
        ),
      );
      setSaveButtonDisabled(false);
    },
    [findProduct, getProductForm, resetFormData, setIsDisabled],
  );

  const handleClearAllFields = ({ type }: handleClearAllFieldsProps): void => {
    if (type === 'add') {
      setCurrentFormStep(FormStep.SEARCH);
      setIsDisabled(true);
    }

    if (type === 'cancel') {
      setCurrentFormStep(FormStep.LIST);
      setIsDisabled(false);
    }

    setSaveButtonDisabled(true);
    resetFormData();
  };

  const handleRemoveItem = useCallback(
    async (product: ProductDetails): Promise<void> => {
      if (order) {
        const { id, version } = order;
        const productData = {
          pedido: {
            id,
            version,
          },
          produto: {
            prdno: product.code,
            grade: product.grade,
          },
        };

        try {
          const data = await removeProduct(productData);

          setOrder(data);

          const newProductsList = productsList;

          newProductsList.itens = productsList.itens.filter(
            item =>
              item.produto.prdno !== product.code ||
              item.produto.grade !== product.grade,
          );
          newProductsList.itensLength = newProductsList.itens.length;
          newProductsList.totalValue = formatMoneyFromFloat(
            data.valorTotalProdutos,
          );

          setProductsList(newProductsList);

          const formData = getFormData<SavedOrder | null>(step.key);

          if (!formData || formData.products.length === 0) return;

          const oldProducts = formData.products.filter(
            currentProduct =>
              currentProduct.code !== product.code ||
              currentProduct.grade !== product.grade,
          );

          setFormData(step.key, {
            products: [...oldProducts],
            productsList,
          });
        } catch (error) {
          if (error.response) {
            addToast({
              type: 'error',
              title: 'Erro ao remover produto',
              description: `${product.code}/${product.grade}: ${
                error.response.data.detail || ''
              }`,
            });
          }
        } finally {
          setShowRemoveItemModal(false);
        }
      }
    },
    [
      order,
      setOrder,
      productsList,
      getFormData,
      step.key,
      setFormData,
      addToast,
    ],
  );

  useEffect(() => {
    if (currentProductCode) {
      getProductFormOptions();
    }
  }, [currentProductCode, getProductFormOptions]);

  useEffect(() => {
    getInitialValues();
  }, [getInitialValues]);

  useEffect(() => {
    isDesktop ||
    (currentFormStep === FormStep.LIST && productsList.itensLength > 0)
      ? setShowFooter(true)
      : setShowFooter(false);
  }, [currentFormStep, isDesktop, productsList.itensLength, setShowFooter]);

  useEffect(() => {
    if (
      productsList.itensLength > 0 &&
      currentFormStep === FormStep.LIST &&
      handleForward
    ) {
      saveOrderProductsForm(productsList);
      history.push(getNextStep(step.key)?.url);
      return;
    }
    setHandleForward(false);
  }, [
    currentFormStep,
    getNextStep,
    handleForward,
    history,
    productsList,
    saveOrderProductsForm,
    setHandleForward,
    step.key,
  ]);

  const formatProductOptions = (products: ProductInfo[]): SelectProps[] => {
    if (products.length === 0) return [];

    const { prdno, titulo } = products[0];

    return [
      {
        value: prdno,
        text: `${prdno} - ${titulo}`,
      },
    ];
  };

  return (
    <Container>
      {showRemoveItemModal && (
        <ConfirmationModal
          description={removeItemDetails.description}
          onClose={() => setShowRemoveItemModal(false)}
          open={showRemoveItemModal}
          onConfirm={() => handleRemoveItem(removeItemDetails.product)}
        />
      )}
      <ProductsHeader>
        <ProductsTitle>
          <LocalShippingOutlinedIcon />
          <span>Produtos</span>
        </ProductsTitle>
        {(isDesktop || isTablet) && productsList.itensLength > 0 && (
          <div>
            <Button
              className="addProduct"
              startIcon={<AddBox />}
              onClick={() => handleClearAllFields({ type: 'add' })}
              disabled={currentFormStep !== FormStep.LIST}
            >
              Adicionar produto
            </Button>
          </div>
        )}
      </ProductsHeader>

      <hr />
      {isLoading ? (
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Loader />
        </div>
      ) : (
        <Content>
          {!isDesktop && currentFormStep === FormStep.SEARCH && (
            <SearchBar
              onCloseSearchBar={handleCloseSearchBar}
              onSearch={handleSearchProduct}
              onSelectOption={setCurrentProductCode}
              options={productOptions}
              value={searchedProductCode}
              searchLoading={searchLoading}
            />
          )}

          {((!isMobile && productsList.itensLength > 0) ||
            FormStep.LIST === currentFormStep) && (
            <>
              {productsList.itensLength === 0 && (
                <NoProductsContainer>
                  <img
                    src="/no_products.svg"
                    alt="Nenhum produto selecionado"
                  />
                  <p>Nenhum produto selecionado</p>

                  <AddProductButton
                    className="addProduct"
                    startIcon={<AddBox />}
                    autoFocus
                    onClick={() => setCurrentFormStep(FormStep.SEARCH)}
                  >
                    Adicionar produto
                  </AddProductButton>
                </NoProductsContainer>
              )}
              {productsList.itensLength > 0 && (
                <ProductsList>
                  <div>
                    {productsList.itens.map(productItem => {
                      const {
                        produto,
                        quantidade,
                        disponibilidade,
                        titulo,
                        montagem,
                        precoUnitario,
                        precoTotal,
                      } = productItem;
                      return (
                        <ProductItem key={`${produto.prdno}|${produto.grade}`}>
                          <RemoveItem
                            id="removeItem"
                            onClick={() => {
                              setRemoveItemDetails({
                                description: `Remover ${produto.prdno} - ${titulo}?`,
                                product: {
                                  code: produto.prdno,
                                  grade: produto.grade,
                                },
                              });
                              setShowRemoveItemModal(true);
                            }}
                          >
                            <CancelIcon />
                          </RemoveItem>
                          <ProductItemDetails>
                            <ProductItemTitle
                              id="itemTitle"
                              onClick={() => handleEditItem(produto)}
                            >
                              {`${produto.prdno} - ${titulo}`}
                            </ProductItemTitle>
                            <ProductItemInfo>
                              <div>
                                <p id="gridItem">{produto.grade}</p>
                                <p id="qttyItem">{`Quantidade: ${quantidade}`}</p>
                                <p>
                                  <span id="availableItem">
                                    {disponibilidade &&
                                      `(a partir de ${format(
                                        new Date(
                                          disponibilidade.replace(/[-]/g, '/'),
                                        ),
                                        'dd/MM/yyyy',
                                      )})`}
                                  </span>
                                </p>
                                <p id="mountingItem">
                                  {montagem ? 'Montar' : 'Não Montar'}
                                </p>
                                <span id="totalValueItem">
                                  {formatMoneyToText(precoTotal)}
                                </span>
                              </div>
                              <ProductItemPrice>
                                <span id="totalValueUnit">
                                  {formatMoneyToText(precoUnitario)}
                                </span>
                                <EditItem>
                                  <IconButton
                                    id="editItem"
                                    onClick={() => handleEditItem(produto)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </EditItem>
                              </ProductItemPrice>
                            </ProductItemInfo>
                          </ProductItemDetails>
                        </ProductItem>
                      );
                    })}
                  </div>
                  <ProductsListFooter>
                    <div>
                      <p id="totalProducts">
                        {`${productsList.itensLength} ${
                          productsList.itensLength > 1 ? 'Produtos' : 'Produto'
                        }`}
                      </p>
                      <span id="totalValueList">
                        {formatMoneyToText(
                          formatMoneyToFloat(productsList.totalValue),
                        )}
                      </span>
                    </div>
                    {isMobile && (
                      <div>
                        <Button
                          startIcon={<AddBox />}
                          onClick={() => handleClearAllFields({ type: 'add' })}
                          disabled={currentFormStep !== FormStep.LIST}
                        >
                          Adicionar produto
                        </Button>
                      </div>
                    )}
                  </ProductsListFooter>
                </ProductsList>
              )}
            </>
          )}

          <Form ref={formRef} onSubmit={handleSubmit}>
            {isDesktop &&
              ([FormStep.SEARCH, FormStep.ADD].includes(currentFormStep) ||
                productsList.itensLength > 0) && (
                <DesktopSearchProduct>
                  <a
                    href={REACT_APP_CATALOGO_URI}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Catálogo
                  </a>

                  <AutoComplete
                    disabled={FormStep.LIST === currentFormStep}
                    readOnly={currentFormStep === FormStep.EDIT}
                    label="Cód do Produto"
                    name="productCode"
                    id="productCode"
                    autoFocus
                    optionsRequest={() => formatProductOptions(productOptions)}
                    clearText="Cancelar"
                    closeIcon={
                      <CancelIcon
                        onClick={() => handleClearAllFields({ type: 'cancel' })}
                      />
                    }
                    placeholder="Cód do Produto"
                    noOptionText="Produto não encontrado"
                    noFilter
                    asyncOptionsRequest={async value => {
                      if (value.length < 6) {
                        setProductOptions([]);
                        return [];
                      }
                      try {
                        const product = await getProduct(value);
                        setSearchedProductCode(product.prdno);
                        setProductOptions([product]);

                        return formatProductOptions([product]);
                      } catch (error) {
                        productNotFound(error);
                        return [];
                      }
                    }}
                    onSelectOption={async (_, value) => {
                      formRef.current?.clearField('grade');
                      formRef.current?.clearField('preco');
                      setPrice(null);
                      setCurrentProductCode(value?.value);
                    }}
                  />
                  {searchLoading && <Loader />}
                </DesktopSearchProduct>
              )}
            {([FormStep.ADD, FormStep.EDIT, FormStep.SEARCH].includes(
              currentFormStep,
            ) ||
              (!isMobile && productsList.itensLength > 0)) && (
              <>
                <Grid>
                  <SimpleSelect
                    label="Grade"
                    name="grade"
                    id="grid"
                    disabled={
                      gridOptions.length === 0 ||
                      currentFormStep === FormStep.EDIT
                    }
                    options={gridOptions}
                    onChange={handleChangeGrid}
                  />
                </Grid>
                <Price>
                  <AutoComplete
                    label="Preço"
                    name="preco"
                    id="price"
                    fieldValue={price}
                    freeSolo
                    mask={Mask.currency}
                    noOptionText="Preço não disponível"
                    optionsRequest={() => priceOptions}
                    disabled={priceOptions.length === 0}
                    required
                    onSelectOption={(_, value) => setSaveButtonDisabled(!value)}
                    onChange={value => setSaveButtonDisabled(!value)}
                  />
                </Price>
                <Amount>
                  <TapCounter
                    name="quantidade"
                    id="amount"
                    label="Quantidade"
                    count={count}
                    setCount={setCount}
                    step={countStep}
                    tabIndex={0}
                    min={countStep || 1}
                  />
                </Amount>
                <Mounting>
                  <SimpleSelect
                    label="Montagem"
                    name="montagem"
                    id="mounting"
                    disabled={!searchedProductCode || !mounting}
                    options={
                      searchedProductCode && mounting
                        ? [
                            { value: 0, valueLabel: 'Não Montar' },
                            { value: 1, valueLabel: 'Montar' },
                          ]
                        : [{ value: 0, valueLabel: 'Não Montar' }]
                    }
                  />
                </Mounting>
                <ButtonContainer>
                  <CancelButton
                    id="cancelButton"
                    disabled={currentFormStep === FormStep.LIST}
                    onClick={() => {
                      resetFormData();
                      setCurrentFormStep(FormStep.LIST);
                      setIsDisabled(false);
                      setSaveButtonDisabled(true);
                    }}
                  >
                    Cancelar
                  </CancelButton>
                  <SaveButton
                    id="saveButton"
                    type="submit"
                    disabled={saveButtonDisabled}
                  >
                    {currentFormStep === FormStep.ADD ? 'Adicionar' : 'Salvar'}
                  </SaveButton>
                </ButtonContainer>
              </>
            )}
          </Form>
        </Content>
      )}
    </Container>
  );
};

export default ProductsForm;
