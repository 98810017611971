import styled from 'styled-components';

export const Installments = styled.div`
  display: grid;
  grid: 1fr 1fr 1fr 1fr;
  grid-template-columns: 70px 100px 100px 100px;
  grid-gap: 50px;
  border: none;
  && div {
    padding-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
  }
  @media screen and (max-width: 769px) {
    grid-template-columns: 20px 60px 60px 60px;
    grid-gap: 40px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelInstallments = styled.div`
  display: grid;
  grid: 1fr 1fr 1fr 1fr;
  grid-template-columns: 70px 100px 100px 100px;
  grid-gap: 50px;
  border: none;
  font-weight: 600;
  text-transform: capitalize;

  && div {
    padding-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    font-size: 12px;
  }

  @media screen and (max-width: 769px) {
    padding-left: 0px;
    grid-gap: 40px;
    grid-template-columns: 30px 50px 60px 60px;
  }
`;
