import React, { useEffect, useState } from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { useScreen } from '../../hooks/Screen';
import { useStepForm } from '../../hooks/StepForm';
import { Steps } from '../../hooks/types';

import { ContainerBar, ContainerConfirmation, ProgressItem } from './styles';

const ProgressBar: React.FC = () => {
  const { step } = useStepForm();

  const { isMobile } = useScreen();

  const [isConfirmationPage, setIsConfirmationPage] = useState(false);

  useEffect(() => {
    setIsConfirmationPage(step.key === Steps.details.key);
  }, [step.key]);

  return (
    <>
      {!isConfirmationPage ? (
        <ContainerBar isMobile={!(step.key >= 1 && isMobile)}>
          {Object.keys(Steps).map(key => (
            <ProgressItem
              key={key}
              className={Steps[key].key ? 'containerInitial' : 'containerArrow'}
              step={step.key >= Steps[key].key ? 1 : 0}
            >
              {!!Steps[key].key && <div className="nock" />}
              <span className="crest">{Steps[key].name}</span>
              <div className="point" />
              <div className="bottomBar" />
            </ProgressItem>
          ))}
        </ContainerBar>
      ) : (
        <ContainerConfirmation isMobile={!(isConfirmationPage && isMobile)}>
          <span className="step">Detalhes</span>
          <ExitToAppIcon className="exitButton" />
        </ContainerConfirmation>
      )}
    </>
  );
};

export default ProgressBar;
