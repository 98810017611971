import { Response, Server } from 'miragejs';

import { AppSchema } from '../models';
import { ErrorResponseNotFound } from '../response';

export default (server: Server): void => {
  server.post('pessoas/detalhar', async (schema: AppSchema, request) => {
    const body = JSON.parse(request.requestBody);

    const pessoa = schema.findBy('cliente', { cpfCnpj: body.cpfCnpj });

    if (!body || !pessoa) {
      return new Response(404, {}, ErrorResponseNotFound());
    }

    return new Response(200, {}, pessoa?.attrs);
  });
};
