import React, { ChangeEvent } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import {
  SearchContainer,
  SearchBackground,
  SearchProductBar,
  InputSearch,
  SearchResult,
  SearchList,
  SearchListItem,
  CatalogButton,
} from './styles';
import { ProductInfo } from '../../../../services/product';

const { REACT_APP_CATALOGO_URI } = process.env;

const SearchBar: React.FC<{
  onCloseSearchBar: () => void;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectOption: (productCode: string) => void;
  value?: string;
  options: ProductInfo[];
  searchLoading: boolean | undefined;
}> = ({
  onCloseSearchBar,
  onSearch,
  onSelectOption,
  value,
  options,
  searchLoading,
}) => {
  return (
    <SearchContainer>
      <SearchBackground>
        <SearchProductBar>
          <CloseIcon onClick={onCloseSearchBar} style={{ cursor: 'pointer' }} />
          <InputSearch
            variant="outlined"
            placeholder="Cód do Produto"
            value={value}
            onChange={onSearch}
            autoFocus
            title="Mínimo de 6 caracteres"
            InputProps={{
              inputProps: {
                maxLength: 16,
              },
            }}
          />
          <a href={REACT_APP_CATALOGO_URI} target="_blank" rel="noreferrer">
            <CatalogButton>
              <img src="/logo_catalogo.png" alt="Abrir Catálogo" />
              <p>Catálogo</p>
            </CatalogButton>
          </a>
        </SearchProductBar>
      </SearchBackground>
      <SearchResult>
        <SearchList>
          {searchLoading && <SearchListItem button>Buscando...</SearchListItem>}
          {options.length > 0 &&
            options.map(product => {
              return (
                <SearchListItem
                  onClick={() => {
                    onSelectOption(product.prdno);
                  }}
                  key={product.prdno}
                  button
                >
                  <p>
                    <b>{product.prdno}</b>
                    {' - '}
                    {product.titulo}
                  </p>
                </SearchListItem>
              );
            })}
        </SearchList>
      </SearchResult>
    </SearchContainer>
  );
};

export default SearchBar;
