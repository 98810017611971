import React from 'react';

import { Container, Ball } from './styles';

const Loader: React.FC = () => {
  return (
    <Container>
      {Array.from({ length: 3 }).map((_, index) => (
        <Ball key={Number(index)} />
      ))}
    </Container>
  );
};

export default Loader;
