import axios, { CancelTokenSource } from 'axios';
import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import history from '../services/history';
import {
  getOrderInProgress,
  Order,
  OrderStatus,
  saveOrderInfo,
} from '../services/order';
import { tefStepUri } from '../services/tef';
import { Steps } from './types';

interface OrderContextData {
  order: Order | null;
  setOrder: Dispatch<SetStateAction<Order | null>>;
}

const OrderContext = createContext<OrderContextData>({} as OrderContextData);

const OrderProvider: React.FC = ({ children }) => {
  const [order, setOrder] = useState<Order | null>(null);

  useEffect(() => {
    if (order) {
      saveOrderInfo(order.id, order.version);
    }
  }, [order]);

  const getOrder = useCallback(
    async (cancelToken?: CancelTokenSource): Promise<void> => {
      if (window.location.pathname === Steps.details.url) {
        return;
      }

      if (order) return;
      try {
        const data = await getOrderInProgress(cancelToken);

        setOrder(data);

        if (
          data.situacao === OrderStatus.reserved ||
          data.situacao === OrderStatus.awaitingChargeback
        ) {
          history.push(tefStepUri);
          return;
        }

        if (data.situacao === 'Finalizado') {
          setOrder(null);
          history.push(`${Steps.details.url}#${data.id}`);
          return;
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          history.push('/delivery');
        }
      }
    },
    [order],
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getOrder(cancelToken);
    return () => cancelToken.cancel();
  }, [getOrder, order]);

  return (
    <OrderContext.Provider value={{ order, setOrder }}>
      {children}
    </OrderContext.Provider>
  );
};

function useOrder(): OrderContextData {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error('useOrder must be used within an OrderProvider.');
  }

  return context;
}

export { OrderProvider, useOrder };
export default OrderContext;
