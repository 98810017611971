import React, {
  RefObject,
  useCallback,
  useState,
  MouseEvent,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CancellationModal from '../CancellationModal';

import { useStepForm } from '../../hooks/StepForm';
import { Steps } from '../../hooks/types';
import { useScreen } from '../../hooks/Screen';
import { useToast } from '../../hooks/Toast';

import {
  Container,
  GroupButton,
  CancelButton,
  ForwardButton,
  BackwardButton,
  OptionsButton,
} from './styles';
import { useOrder } from '../../hooks/OrderContext';
import ConfirmationModal from '../ConfirmationModal';
import { OrderStatus, releaseOrderEdition } from '../../services/order';

const Footer: React.FC<{
  form?: RefObject<FormHandles>;
  onClickForward?: () => void;
  isDisabled?: boolean;
  handleCloseModal?: () => void;
}> = ({ form, onClickForward, isDisabled, handleCloseModal }) => {
  const history = useHistory();
  const toast = useToast();
  const { order, setOrder } = useOrder();
  const { step } = useStepForm();
  const { isMobile } = useScreen();

  const [isCancel, setIsCancel] = useState(false);
  const [isBackToEdit, setIsBackToEdit] = useState(false);
  const [orderIsReserved, setOrderIsReserved] = useState(false);
  const [awaitChargeBack, setAwaitChargeBack] = useState(false);
  const [
    optionsMenuAnchorEl,
    setOptionsMenuAnchorEl,
  ] = useState<null | HTMLElement>(null);

  const optionsIsOpen = Boolean(optionsMenuAnchorEl);

  useEffect(() => {
    setOrderIsReserved(order?.situacao === OrderStatus.reserved);
    setAwaitChargeBack(order?.situacao === OrderStatus.awaitingChargeback);
  }, [order?.situacao]);

  const previousStep = useCallback(() => {
    const previousPage = Object.values(Steps)[step.key - 1];

    return history.push(previousPage?.url);
  }, [history, step.key]);

  const handleReleaseOrderEdition = async (): Promise<void> => {
    toast.clearToasts();
    try {
      if (!order) return;

      const { id, version } = order;

      const updatedOrder = await releaseOrderEdition({
        pedido: { id, version },
      });
      setOrder(updatedOrder);

      setIsBackToEdit(false);
      window.location.replace('/delivery');
    } catch (error) {
      setIsBackToEdit(false);
      toast.addToast({
        type: 'error',
        title: error.response?.data.title,
        description: error.response?.data.detail,
      });
    }
  };

  const handleOpenOptionsMenu = (
    event: MouseEvent<HTMLButtonElement>,
  ): void => {
    setOptionsMenuAnchorEl(event.currentTarget);
  };

  return (
    <Container>
      <GroupButton>
        {isMobile ? (
          <>
            {orderIsReserved ? (
              <>
                <OptionsButton
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  variant="text"
                  aria-expanded={optionsIsOpen || undefined}
                  onClick={handleOpenOptionsMenu}
                  endIcon={<KeyboardArrowDownIcon />}
                  disabled={awaitChargeBack}
                >
                  Opções
                </OptionsButton>
                <Menu
                  anchorEl={optionsMenuAnchorEl}
                  open={optionsIsOpen}
                  onClose={() => setOptionsMenuAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => setIsCancel(true)}>
                    Cancelar
                  </MenuItem>
                  {orderIsReserved && (
                    <MenuItem onClick={() => setIsBackToEdit(true)}>
                      Voltar para Edição
                    </MenuItem>
                  )}
                </Menu>
              </>
            ) : (
              <CancelButton
                type="button"
                className="cancelButton"
                onClick={() => setIsCancel(true)}
                disabled={awaitChargeBack}
              >
                Cancelar
              </CancelButton>
            )}
          </>
        ) : (
          <>
            <CancelButton
              type="button"
              className="cancelButton"
              onClick={() => setIsCancel(true)}
              disabled={awaitChargeBack}
            >
              Cancelar
            </CancelButton>
            {orderIsReserved && (
              <CancelButton
                className="backToEdit"
                type="button"
                onClick={() => setIsBackToEdit(true)}
                disabled={awaitChargeBack}
              >
                Voltar para Edição
              </CancelButton>
            )}
          </>
        )}
      </GroupButton>

      {isBackToEdit && order && (
        <ConfirmationModal
          description="Tem certeza que deseja voltar para Edição?"
          open={isBackToEdit}
          onClose={() => setIsBackToEdit(false)}
          onConfirm={handleReleaseOrderEdition}
        />
      )}

      {isCancel && (
        <CancellationModal onClose={() => setIsCancel(false)} open={isCancel} />
      )}

      <GroupButton>
        {step.key >= 1 && (
          <BackwardButton
            disabled={isDisabled || orderIsReserved || awaitChargeBack}
            id="backButton"
            onClick={() => {
              toast.clearToasts();
              previousStep();
            }}
          >
            Voltar
          </BackwardButton>
        )}
        <ForwardButton
          disabled={isDisabled}
          type="button"
          id="forwardButton"
          onClick={() => {
            toast.clearToasts();
            handleCloseModal && isMobile && handleCloseModal();
            onClickForward ? onClickForward() : form?.current?.submitForm();
          }}
        >
          Avançar
        </ForwardButton>
      </GroupButton>
    </Container>
  );
};

export default Footer;
