import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import crypto from 'crypto';
import { authRedirect } from '../services/auth';

interface AppContextData {
  saveAppStateAndReAuth(currentData: Record<string, unknown>): void;
  getOldAppStateData(): Record<string, unknown> | undefined;
  setOldAppStateId: Dispatch<SetStateAction<string>>;
}

const AppContext = createContext<AppContextData>({} as AppContextData);

const AppProvider: React.FC = ({ children }) => {
  const [oldAppStateId, setOldAppStateId] = useState('');

  const saveAppStateAndReAuth = (
    currentData: Record<string, unknown>,
  ): void => {
    const stateId = crypto.randomBytes(20).toString('hex');
    const currentPage = window.location.pathname;

    const stateObject = { id: stateId, page: currentPage, data: currentData };
    localStorage.setItem('@MSPDV:state', JSON.stringify(stateObject));

    authRedirect();
  };

  const getOldAppStateData = useCallback(():
    | Record<string, unknown>
    | undefined => {
    const storagedOldAppState = localStorage.getItem('@MSPDV:state');

    if (!storagedOldAppState) {
      return {};
    }

    const storagedOldAppStateObject = JSON.parse(storagedOldAppState);

    if (oldAppStateId === storagedOldAppStateObject.id) {
      localStorage.removeItem('@MSPDV:state');
      return storagedOldAppStateObject.data;
    }

    return {};
  }, [oldAppStateId]);

  return (
    <AppContext.Provider
      value={{
        saveAppStateAndReAuth,
        getOldAppStateData,
        setOldAppStateId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

function useApp(): AppContextData {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error('useApp must be used within an AppProvider.');
  }

  return context;
}

export { AppProvider, useApp };
