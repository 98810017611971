import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  SetStateAction,
} from 'react';
import * as Yup from 'yup';
import AddBox from '@material-ui/icons/AddBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Button } from '@material-ui/core';
import { useFeature } from 'flagged';
import { useStepForm } from '../../../hooks/StepForm';
import { useOrder } from '../../../hooks/OrderContext';
import { useToast } from '../../../hooks/Toast';
import { StepIndex } from '../../../hooks/types';
import {
  formatMoneyToText,
  formatStringMoneyToNumber,
} from '../../../utils/formatMoney';
import { OrderPayment } from '../../../services/order';
import {
  addPayment,
  getPaymentMethods,
  getSimulatedPayments,
  PaymentData,
  removePayment,
  SearchPaymentMethodsData,
  SimulatePaymentData,
} from '../../../services/payment';
import getValidationErrors from '../../../utils/getValidationErrors';
import Loader from '../../Loader';
import SimpleSelect from '../../SimpleSelect';
import TextField from '../../TextField';

import Menu from './Menu';
import AutoComplete, { SelectProps } from '../../Autocomplete/Autocomplete';
import {
  CardBrand,
  Container,
  Content,
  PaymentTitle,
  ValueField,
  PaymentContainer,
  InstallmentsContainer,
  ValuesContainer,
  Values,
  RemoveItem,
  TableContainer,
  CircularLoader,
  InstallmentsOptions,
} from './styles';
import { Mask } from '../../TextField/types';
import ConfirmationModal from '../../ConfirmationModal';

export interface PaymentFormData {
  formaPagamento: string;
  metodoPagamento: MethodPaymentOptions;
  parcelamento: InstallmentsOptions;
  valorEmAberto: string;
}

export interface InstallmentValue {
  id: number;
  parcela: string;
  valor: string;
  valorFinal: string;
  juros: string;
}
interface MethodPaymentOptions {
  value: SearchPaymentMethodsData;
  text: string;
}
interface CardBrandOption {
  value: number;
  valueLabel: string;
}
interface InstallmentsOptions {
  value: InstallmentValue;
  text: string;
}

interface OpenValue {
  value: number;
  text: string;
}

interface ModalRemovePaymentProps {
  description: string;
  payment: OrderPayment;
}

const PaymentForm: React.FC<{
  setOrderPaymentData?: React.Dispatch<SetStateAction<PaymentData | null>>;
}> = ({ setOrderPaymentData }) => {
  const toast = useToast();
  const { setFormData } = useStepForm();
  const { order, setOrder } = useOrder();
  const editPayment = Boolean(useFeature('PermitirPagamentoRateado'));

  const formRef = useRef<FormHandles>(null);
  const [paymentValue, setPaymentValue] = useState<OpenValue | null>(null);
  const [methodPayment, setMethodPayment] = useState<SelectProps | null>(null);
  const [menuValue, setMenuValue] = useState<InstallmentValue | null>(null);
  const [methodPaymentOptions, setMethodPaymentOptions] = useState<
    MethodPaymentOptions[]
  >([]);
  const [showRemoveItemModal, setShowRemoveItemModal] = useState<boolean>(
    false,
  );
  const [
    removePaymentDetails,
    setRemovePaymentDetails,
  ] = useState<ModalRemovePaymentProps | null>(null);
  const [hasForm, setHasForm] = useState<boolean>(false);
  const [installment, setInstallment] = useState<InstallmentValue[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cardBrands, setCardBrands] = useState<CardBrandOption[]>([]);
  const [orderPayments, setOrderPayments] = useState<OrderPayment[] | null>(
    null,
  );

  const [loadingAddPayment, setLoadingAddPayment] = useState<boolean>(false);
  const [
    creditCardPaymentMethod,
    setCreditCardPaymentMethod,
  ] = useState<number>(0);
  const [cardBrand, setCardBrand] = useState<number>(0);

  const getInstallments = useCallback(
    async (cancelToken, payment) => {
      if (!order || !creditCardPaymentMethod || !cardBrand) {
        return;
      }

      if (order.valorEmAberto === 0 || payment === 0) {
        setInstallment([]);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const values = await getSimulatedPayments(cancelToken, {
          pedido: {
            id: order.id,
            version: order.version,
          },
          metodoPagamento: {
            id: creditCardPaymentMethod,
          },
          formaPagamento: { id: cardBrand },
          valorPagamento: payment || order.valorEmAberto,
        });

        const installmentsArray = (values || []).sort((prev, next) =>
          prev.parcelamento.numeroParcelas < next.parcelamento.numeroParcelas
            ? -1
            : 1,
        );

        const installmentsOptions = installmentsArray.map(
          (valueItem: SimulatePaymentData) => {
            return {
              id: valueItem.parcelamento.id,
              parcela: valueItem.parcelamento.exibicao,
              valor: formatMoneyToText(valueItem.valorParcelas),
              valorFinal: formatMoneyToText(
                Number(valueItem.valorTotalParcelado),
              ),
              juros: formatMoneyToText(valueItem.juros),
            };
          },
        );
        setMenuValue(installmentsOptions[0]);
        setInstallment(installmentsOptions);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) return;
        toast.addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: error.response.data?.detail,
        });
      }

      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardBrand, creditCardPaymentMethod, order],
  );

  const getPaymentValues = useCallback((): void => {
    if (!order) return;
    setPaymentValue({
      value: order.valorEmAberto,
      text: formatMoneyToText(order.valorEmAberto),
    });
    setOrderPayments(Object.values(order?.pagamentos));
  }, [order]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getPaymentMethods(cancelToken)
      .then(({ data }) => {
        const methodOptions: MethodPaymentOptions[] = [];
        data.forEach(item => {
          methodOptions.push({
            value: item,
            text: item.descricao,
          });
        });
        setMethodPayment({
          value: data[0].formasPagamento,
          text: data[0].descricao,
        });
        setMethodPaymentOptions(methodOptions);
        return data;
      })
      .then(([{ id, formasPagamento }]) => {
        setCreditCardPaymentMethod(id);
        setCardBrands(
          formasPagamento
            .map((f): CardBrandOption => ({ value: f.id, valueLabel: f.nome }))
            .sort((prev, next) => (prev.valueLabel < next.valueLabel ? -1 : 1)),
        );
        getPaymentValues();
        setIsLoading(false);
      });
    return () => cancelToken.cancel();
  }, [getPaymentValues]);

  const updateDetailedOrderForm = useCallback(
    (payments: OrderPayment[] | null): void => {
      if (!order || !payments) return;

      if (order.valorEmAberto === 0) {
        setHasForm(false);
      }

      setOrderPayments(Object.values(payments));
      setPaymentValue({
        value: order.valorEmAberto,
        text: formatMoneyToText(order.valorEmAberto),
      });

      formRef.current?.setData({
        valorEmAberto: order.valorEmAberto,
        metodoPagamento: methodPaymentOptions[0],
        formaPagamento: cardBrands[0],
      });
    },
    [methodPaymentOptions, cardBrands, order],
  );

  useEffect(() => {
    formRef.current?.setData({
      valorEmAberto: paymentValue?.text,
      bandeira: cardBrands[0],
    });
  }, [paymentValue, cardBrands]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getInstallments(cancelToken, paymentValue?.value);
    return () => cancelToken.cancel();
  }, [creditCardPaymentMethod, getInstallments, paymentValue]);

  const handleSubmit = useCallback(
    async (data: PaymentFormData): Promise<void> => {
      if (!order || !menuValue || !creditCardPaymentMethod || !paymentValue)
        return;
      try {
        setLoadingAddPayment(true);
        const schema = Yup.object().shape({
          formaPagamento: Yup.string().required(
            'Forma de pagamento não informado',
          ),
          metodoPagamento: Yup.object().shape({
            text: Yup.string().required('Método de pagamento não informado'),
          }),
          valorEmAberto: Yup.string().required('Valor pago não pode ser vazio'),
        });

        await schema.validate(data, { abortEarly: false });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          Object.values(errors).forEach(fieldError => {
            toast.addToast({
              type: 'error',
              title: 'Erro',
              description: fieldError,
            });
          });

          formRef.current?.setErrors(errors);
        }
        setLoadingAddPayment(false);
        return;
      }

      try {
        const formData = {
          pedido: {
            id: order.id,
            version: order.version,
          },
          parcelamento: {
            metodoPagamento: {
              id: creditCardPaymentMethod,
            },
            formaPagamento: {
              id: Number(data.formaPagamento),
            },
            parcelamento: {
              id: menuValue.id,
              numeroParcelas: formatStringMoneyToNumber(menuValue.parcela),
            },
          },
          valorPagamento: paymentValue?.value,
        };

        const responseOrder = await addPayment(formData);

        setOrder(responseOrder);

        updateDetailedOrderForm(responseOrder.pagamentos);

        setFormData(StepIndex.PAYMENT, {
          ...formData,
          paymentId: responseOrder.pagamentos[0].id,
        });
        setLoadingAddPayment(false);
      } catch (error) {
        toast.addToast({
          type: 'error',
          title: 'Ocorreu um erro',
          description: error?.response?.data?.detail,
        });
        setLoadingAddPayment(false);
      }
    },
    [
      creditCardPaymentMethod,
      order,
      setFormData,
      toast,
      menuValue,
      paymentValue,
      setOrder,
      updateDetailedOrderForm,
    ],
  );

  const handleCalculateOrderPayment = useCallback((): string => {
    let total = null;

    if (!order) return formatMoneyToText(0);

    if (order.pagamentos.length > 0) {
      total = order.pagamentos
        .map(orderPayment => orderPayment.valorPago)
        .reduce((acc, curr) => acc + curr);
    }

    return formatMoneyToText(total || 0);
  }, [order]);

  const handleRemovePayment = useCallback(
    async (paymentId: number): Promise<void> => {
      if (!order) return;
      try {
        const formData = {
          pedido: {
            id: order.id,
            version: order.version,
          },
          pagamento: {
            id: paymentId,
          },
        };

        const responseOrder = await removePayment(formData);

        updateDetailedOrderForm(responseOrder.pagamentos);

        setOrder(responseOrder);

        setShowRemoveItemModal(false);
      } catch (error) {
        toast.addToast({
          type: 'error',
          title: 'Erro ao remover pagamento',
          description: error.response?.data?.detail,
        });
        setShowRemoveItemModal(false);
      }
    },
    [toast, updateDetailedOrderForm, order, setOrder],
  );

  useEffect(() => {
    setHasForm(order?.valorEmAberto !== 0);
  }, [order]);

  useEffect(() => {
    if (!order || !menuValue || !paymentValue) return;

    const formaPagamentoData = formRef.current?.getFieldValue('formaPagamento');

    const formData = {
      pedido: {
        id: order.id,
        version: order.version,
      },
      parcelamento: {
        metodoPagamento: {
          id: creditCardPaymentMethod,
        },
        formaPagamento: {
          id: Number(formaPagamentoData),
        },
        parcelamento: {
          id: menuValue.id,
          numeroParcelas: formatStringMoneyToNumber(menuValue.parcela),
        },
      },
      valorPagamento: paymentValue.value,
    };
    setOrderPaymentData && setOrderPaymentData(formData);
  }, [
    creditCardPaymentMethod,
    menuValue,
    order,
    paymentValue,
    setOrderPaymentData,
  ]);

  return (
    <Container>
      <PaymentTitle className="paymentTitle">
        <MonetizationOnOutlinedIcon />
        <span>Forma de Pagamento</span>
      </PaymentTitle>
      <hr />
      {showRemoveItemModal && (
        <ConfirmationModal
          description={removePaymentDetails?.description || ''}
          onClose={() => setShowRemoveItemModal(false)}
          open={showRemoveItemModal}
          onConfirm={() =>
            handleRemovePayment(removePaymentDetails?.payment.id || 0)
          }
        />
      )}

      <Content disabled={!hasForm}>
        {isLoading && cardBrands.length === 0 && paymentValue ? (
          <div style={{ marginTop: '10px' }}>
            <Loader />
          </div>
        ) : (
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            onKeyPress={e =>
              e.code === 'Enter' &&
              !editPayment &&
              handleSubmit(formRef.current?.getData() as PaymentFormData)
            }
          >
            <PaymentContainer>
              <ValueField>
                <TextField
                  name="valorEmAberto"
                  label="Valor pago"
                  className="formFields"
                  mask={hasForm ? Mask.currency : Mask.none}
                  autoFocus
                  variationStyle="outlined"
                  length={20}
                  readOnly={!editPayment}
                  disabled={!hasForm}
                  autoComplete="off"
                  onChange={event =>
                    setPaymentValue({
                      value: formatStringMoneyToNumber(event.target.value),
                      text: event.target.value,
                    })
                  }
                />
              </ValueField>

              <CardBrand>
                <AutoComplete
                  id="paymentMethod"
                  className="formFields"
                  label="Método de pagamento"
                  name="metodoPagamento"
                  fieldValue={methodPayment}
                  disabled={!hasForm}
                  optionsRequest={() => methodPaymentOptions}
                  onSelectOption={(_, value) => {
                    setCreditCardPaymentMethod(value?.value.id);
                    setMethodPayment({
                      value: value?.value,
                      text: value?.value.descricao,
                    });
                  }}
                />
              </CardBrand>

              <CardBrand>
                <SimpleSelect
                  id="flag"
                  label="Forma de pagamento"
                  name="formaPagamento"
                  options={cardBrands}
                  disabled={!hasForm}
                  fieldValue={cardBrand}
                  onChange={value => value && setCardBrand(value)}
                  placeholder={
                    (hasForm && 'Selecione uma Forma de Pagamento') || ''
                  }
                  autoFocus
                />
              </CardBrand>

              {isLoading && (
                <div style={{ marginTop: '20px' }}>
                  <Loader />
                </div>
              )}

              {installment.length > 0 &&
                !isLoading &&
                hasForm &&
                cardBrand !== 0 && (
                  <>
                    <InstallmentsOptions>
                      <span>Opções de Parcelamento</span>
                    </InstallmentsOptions>
                    <InstallmentsContainer>
                      <Menu
                        id="#installment"
                        options={installment}
                        open
                        name="Parcelamento"
                        menu={menuValue}
                        setMenuValue={setMenuValue}
                      />
                    </InstallmentsContainer>
                  </>
                )}
              {editPayment && (
                <Button
                  type="submit"
                  disabled={
                    !hasForm ||
                    loadingAddPayment ||
                    installment.length === 0 ||
                    isLoading
                  }
                  className="addPayment"
                  startIcon={
                    loadingAddPayment ? (
                      <CircularLoader className="addLoadingPayment" size={20} />
                    ) : (
                      <AddBox />
                    )
                  }
                >
                  Lançar Pagamento
                </Button>
              )}
            </PaymentContainer>
          </Form>
        )}

        <TableContainer>
          <ValuesContainer>
            <div className="title">Pagamentos</div>
            {orderPayments &&
              orderPayments?.length > 0 &&
              orderPayments.map(orderPayment => {
                return (
                  <>
                    <Values key={orderPayment.id} className="money">
                      <div className="methodTitle">
                        {`${orderPayment.metodoPagamento.descricao} - ${orderPayment.formaPagamento.nome} (${orderPayment.numeroParcelas}X)` ||
                          ''}
                      </div>
                      <div className="customValue">
                        <strong>
                          {formatMoneyToText(orderPayment.valorPago)}
                        </strong>
                        <RemoveItem
                          onClick={() => {
                            setRemovePaymentDetails({
                              description: `Remover ${
                                orderPayment.metodoPagamento.descricao
                              } - ${formatMoneyToText(
                                orderPayment.valorPago,
                              )}?`,
                              payment: orderPayment,
                            });
                            setShowRemoveItemModal(true);
                          }}
                        >
                          <DeleteForeverIcon fontSize="small" />
                        </RemoveItem>
                      </div>
                    </Values>
                    <hr />
                  </>
                );
              })}

            <>
              <Values>
                <div>Valor dos pagamentos:</div>
                <div className="moneyValues">
                  <strong>{handleCalculateOrderPayment()}</strong>
                </div>
              </Values>
              <Values>
                <div>Valor Restante:</div>
                <div className="moneyValues">
                  <strong className={hasForm ? 'remaining' : 'noRest'}>
                    {formatMoneyToText(order?.valorEmAberto || 0)}
                  </strong>
                </div>
              </Values>
              <Values>
                <div>Valor do Pedido:</div>
                <div className="moneyValues">
                  <strong>{formatMoneyToText(order?.valorVenda || 0)}</strong>
                </div>
              </Values>
            </>
          </ValuesContainer>
        </TableContainer>
      </Content>
    </Container>
  );
};

export default PaymentForm;
