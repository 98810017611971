export type AutoComplete = 'on' | 'off';
export type VariationStyle = 'outlined' | 'filled' | 'standard';
// eslint-disable-next-line no-shadow
export enum Mask {
  none = 'none',
  cep = 'cep',
  currency = 'currency',
  onlyNumber = 'onlyNumber',
  cpf = 'cpf',
  nameUpperCase = 'nameUpperCase',
  phoneNumber = 'phoneNumber',
  birthday = 'birthday',
}
