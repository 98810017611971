import { Response, Server } from 'miragejs';

import { AppSchema } from '../models';

export default (server: Server): void => {
  server.post(
    'logistica/buscarOpcoesEntrega',
    async (schema: AppSchema, request) => {
      const endereco = await schema.findBy('endereco', {
        cep: JSON.parse(request.requestBody).cep,
      });

      if (!endereco?.id) {
        return new Response(
          400,
          {},
          { detail: 'O CEP Informado não é atendido' },
        );
      }

      const itinerario = await schema.where('itinerario', {
        enderecoId: endereco?.id,
      });

      return new Response(
        200,
        {},
        {
          endereco: {
            ...endereco?.attrs,
          },
          itinerarios: [...itinerario?.models],
        },
      );
    },
  );
};
