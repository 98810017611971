import React from 'react';

import { useScreen } from '../../hooks/Screen';
import { Loader } from '../index';
import { useOrder } from '../../hooks/OrderContext';
import { OrderStatus } from '../../services/order';

import {
  CardMachineImage,
  Content,
  DesktopImage,
  Label,
  LoaderContainer,
  Title,
  TryAgainButton,
} from './styles';

const LoadTef: React.FC<{
  error?: string;
  retry?: () => void;
}> = ({ error, retry }) => {
  const { isMobile } = useScreen();
  const { order } = useOrder();

  return (
    <>
      <Title>
        <span>MS TEF</span>
      </Title>

      <hr />

      <Content>
        <LoaderContainer>
          <DesktopImage isMobile={isMobile} />
          <Loader />
          <CardMachineImage isMobile={isMobile} />
        </LoaderContainer>

        {error || isMobile ? (
          <>
            <Label className="errorTef">{error}</Label>

            {order?.situacao !== OrderStatus.awaitingChargeback && (
              <TryAgainButton className="btnErrorTef" onClick={retry}>
                Tentar novamente
              </TryAgainButton>
            )}
          </>
        ) : (
          <Label>Carregando MS TEF...</Label>
        )}
      </Content>
    </>
  );
};

export default LoadTef;
