import React, {
  useState,
  useEffect,
  ChangeEvent,
  useRef,
  RefObject,
  useCallback,
} from 'react';

import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { Event } from '@material-ui/icons';
import {
  Radio as MaterialRadio,
  RadioGroup,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';

import {
  addDays,
  differenceInDays,
  format,
  isAfter,
  isBefore,
  isEqual,
  isValid,
} from 'date-fns';

import axios, { CancelTokenSource } from 'axios';

import api from '../../../services/apiClient';
import DatePicker from './DatePicker';
import { useStepForm } from '../../../hooks/StepForm';
import { useOrder } from '../../../hooks/OrderContext';
import { StepIndex, Steps, ScheduleType } from '../../../hooks/types';

import { Container, ScheduleTitle, Form } from './styles';
import Loader from '../../Loader';
import theme from '../../../utils/themes';
import { updateSavedOrderVersion } from '../../../services/order';
import { useToast } from '../../../hooks/Toast';
import { objectsMatch } from '../../../utils/functions';

interface Response {
  estimativas: Array<{
    itinerario: {
      id: number;
      nome: string;
    };
    dataEntregaMinima: string;
    dataMontagemMinima: string | null;
    dataEntregaMaxima: string;
    dataMontagemMaxima: string | null;
  }>;
}

interface Dates {
  deliveryDate?: string | null;
  assemblyDate?: string | null;
}

interface Holiday {
  nome: string;
  data: string;
}

const Radio = withStyles({
  root: {
    color: theme.palette.grey._300.hex(),
    '&$checked': {
      color: theme.palette.yellow._600.hex(),
    },
  },
  checked: {},
  label: {
    color: theme.palette.yellow._600.hex(),
  },
})(props => <MaterialRadio color="default" {...props} />);

const ScheduleForm: React.FC<{
  setFormRef: (el: RefObject<FormHandles>) => void;
}> = ({ setFormRef }) => {
  const history = useHistory();
  const toast = useToast();
  const formRef = useRef<FormHandles>(null);
  const { getNextStep, step, setFormData, getFormData } = useStepForm();
  const { order, setOrder } = useOrder();

  const [hasSchedule, setHasSchedule] = useState('yes');
  const [hasMounting, setHasMounting] = useState(true);
  const [loading, setLoading] = useState<boolean>(true);

  const [minimumDates, setMinimumDates] = useState<Dates>({});
  const [maximumDates, setMaximumDates] = useState<Dates>({});
  const [scheduleDifference, setScheduledDifference] = useState<number>(0);
  const [holidays, setHolidays] = useState<Holiday[]>([]);

  const [
    scheduledDeliveryDate,
    setScheduledDeliveryDate,
  ] = useState<Date | null>(null);
  const [
    scheduledAssemblyDate,
    setScheduledAssemblyDate,
  ] = useState<Date | null>(null);

  const handleChangeHasSchedule = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => setHasSchedule(event.target.value);

  const formatStringToDate = (dateString: string): Date =>
    new Date(dateString.concat(' ', '00:00:00'));

  const formatDate = (
    date: Date | string | null,
    selectedType: 'pt-br' | 'en-us',
  ): string | null => {
    if (date === null) {
      return null;
    }

    const typesDate = {
      'pt-br': 'dd/MM/yyyy',
      'en-us': 'yyyy-MM-dd',
    };

    if (typeof date === 'string') {
      return format(formatStringToDate(date), typesDate[selectedType]);
    }

    return format(date, typesDate[selectedType]);
  };

  const isBetween = (date: Date, minDate?: Date, maxDate?: Date): boolean => {
    if (!minDate || !maxDate) return true;

    return (
      (isBefore(date, maxDate) || isEqual(date, maxDate)) &&
      (isAfter(date, minDate) || isEqual(date, minDate))
    );
  };

  const handleLoadDates = useCallback(
    async (cancelToken: CancelTokenSource): Promise<void> => {
      const formData = getFormData<ScheduleType | undefined>(
        StepIndex.SCHEDULE,
      );

      if (!order) {
        return;
      }

      if (formData) {
        setHasSchedule(formData.hasSchedule);
      }

      const { dataMontagem, dataEntrega } = order.entregas[0];

      if (!order.entregas.length || order.entregas.length === 0) {
        toast.addToast({
          type: 'error',
          title: 'Erro ao gerar entrega',
          description: 'Dados da entrega não informados.',
        });

        history.push(Steps.delivery.url);
        return;
      }

      const { id, version } = order;
      const dataToEstimate = {
        pedido: {
          id,
          version,
        },
        itinerarios: [{ id: order.entregas[0].itinerario.id }],
      };

      try {
        const {
          data,
        }: {
          data: Response;
        } = await api.post(
          '/logistica/estimarDataEntregaMontagem',
          dataToEstimate,
          { cancelToken: cancelToken.token },
        );

        if (!data.estimativas[0].dataMontagemMinima) {
          setHasMounting(false);
        }

        data.estimativas.forEach(current => {
          setMinimumDates({
            deliveryDate: current.dataEntregaMinima.replace(/[-]/g, '/'),
            assemblyDate:
              current.dataMontagemMinima?.replace(/[-]/g, '/') ?? null,
          });

          setScheduledDeliveryDate(
            formatStringToDate(dataEntrega.replace(/[-]/g, '/')),
          );

          setScheduledAssemblyDate(
            current.dataMontagemMinima
              ? formatStringToDate(dataMontagem?.replace(/[-]/g, '/'))
              : null,
          );

          if (current.dataEntregaMaxima) {
            setMaximumDates({
              deliveryDate: current.dataEntregaMaxima.replace(/[-]/g, '/'),
              assemblyDate:
                current.dataMontagemMaxima?.replace(/[-]/g, '/') ?? null,
            });
          }

          if (current.dataMontagemMinima) {
            setScheduledDifference(
              differenceInDays(
                new Date(current.dataMontagemMinima),
                new Date(current.dataEntregaMinima),
              ),
            );
          }
        });

        const estimativa = data.estimativas[0];

        if (!estimativa) return;

        const holidaysRequestData = {
          loja: order.loja,
          periodo: {
            inicio: estimativa.dataEntregaMinima,
            fim: estimativa.dataMontagemMaxima ?? estimativa.dataEntregaMaxima,
          },
        };

        if (
          formData?.holidays &&
          objectsMatch(formData.holidays.periodo, holidaysRequestData.periodo)
        ) {
          setHolidays(formData.holidays.holidays);
          return;
        }

        const response: { data: { feriados: Holiday[] } } = await api.post(
          '/pdv/calendario/feriados/buscar',
          holidaysRequestData,
        );

        setHolidays(response.data.feriados);
      } catch (error) {
        setHasSchedule('no');
        if (axios.isCancel(error)) return;

        toast.addToast({
          type: 'error',
          title: 'Erro',
          description: error?.response?.data?.detail,
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFormData, history, order],
  );

  const isWeekend = (date: Date): boolean => {
    const weekendDays = [0, 6];
    return weekendDays.includes(date.getDay());
  };

  const isHoliday = (date: Date): boolean =>
    holidays.some(
      holiday => formatStringToDate(holiday.data).getTime() === date.getTime(),
    );

  const handleChangeDeliveryDate = useCallback(
    (date: Date | null): void => {
      if (date === null || isValid(date) === false) {
        setScheduledDeliveryDate(null);

        return;
      }

      const minDeliveryDate = minimumDates.deliveryDate
        ? new Date(minimumDates.deliveryDate)
        : null;
      const maxDeliveryDate = maximumDates.deliveryDate
        ? new Date(maximumDates.deliveryDate)
        : null;

      try {
        if (
          minDeliveryDate &&
          maxDeliveryDate &&
          isBetween(date, minDeliveryDate, maxDeliveryDate) === false
        ) {
          setScheduledDeliveryDate(minDeliveryDate);
          throw new Error(
            `Data de Entrega não está dentro do intervalo permitido (entre ${formatDate(
              minDeliveryDate,
              'pt-br',
            )} e ${formatDate(maxDeliveryDate, 'pt-br')})`,
          );
        }

        if (isWeekend(date)) {
          throw new Error(
            'Entrega não pode ser agendada para os fins de semana',
          );
        }

        if (isHoliday(date)) {
          throw new Error('Entrega não pode ser agendada para Feriados');
        }

        setScheduledDeliveryDate(date);
      } catch (error) {
        toast.clearToasts();
        toast.addToast({
          title: 'Data inválida',
          type: 'error',
          description: error?.message,
        });
        return;
      }

      if (!scheduledAssemblyDate || !date) {
        return;
      }

      const currentScheduleDifference = differenceInDays(
        scheduledAssemblyDate,
        date,
      );

      const minAssemblyDate = addDays(date, scheduleDifference);

      if (currentScheduleDifference < scheduleDifference) {
        setScheduledAssemblyDate(minAssemblyDate);
      }

      setMinimumDates({
        ...minimumDates,
        assemblyDate: formatDate(minAssemblyDate, 'en-us')?.replace(
          /[-]/g,
          '/',
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      minimumDates,
      maximumDates.deliveryDate,
      scheduledAssemblyDate,
      scheduleDifference,
      formatDate,
      isHoliday,
    ],
  );

  const handleChangeAssemblyDate = useCallback(
    (date: Date | null): void => {
      if (date === null || isValid(date) === false) {
        setScheduledAssemblyDate(null);
        return;
      }

      const minAssemblyDate = minimumDates.assemblyDate
        ? new Date(minimumDates.assemblyDate)
        : null;

      const maxAssemblyDate = maximumDates.assemblyDate
        ? new Date(maximumDates.assemblyDate)
        : null;

      try {
        if (isWeekend(date)) {
          throw new Error(
            'Montagem não pode ser agendada para os fins de semana',
          );
        }

        if (isHoliday(date)) {
          throw new Error('Montagem não pode ser agendada para Feriados');
        }

        if (
          minAssemblyDate &&
          maxAssemblyDate &&
          isBetween(date, minAssemblyDate, maxAssemblyDate) === false
        ) {
          setScheduledAssemblyDate(minAssemblyDate);
          throw new Error(
            `Data de Montagem não está dentro do intervalo permitido (entre ${formatDate(
              minAssemblyDate,
              'pt-br',
            )} e ${formatDate(maxAssemblyDate, 'pt-br')})`,
          );
        }

        setScheduledAssemblyDate(date);
      } catch (error) {
        toast.clearToasts();
        toast.addToast({
          title: 'Data inválida',
          type: 'error',
          description: error?.message,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      minimumDates.assemblyDate,
      maximumDates.assemblyDate,
      formatDate,
      isHoliday,
    ],
  );

  const verifyAssemblyDate = (dateAssembly: Date | null): string => {
    return formatDate(dateAssembly, 'en-us') ?? '';
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      if (
        hasSchedule === 'yes' &&
        (!scheduledDeliveryDate || (!scheduledAssemblyDate && hasMounting))
      ) {
        return;
      }

      if (
        hasSchedule === 'yes' &&
        scheduledDeliveryDate &&
        isWeekend(scheduledDeliveryDate)
      ) {
        throw new Error('Entrega não pode ser agendada para os fins de semana');
      }

      if (
        hasSchedule === 'yes' &&
        scheduledAssemblyDate &&
        isWeekend(scheduledAssemblyDate)
      ) {
        throw new Error(
          'Montagem não pode ser agendada para os fins de semana',
        );
      }

      const scheduleData = {
        pedido: {
          id: order?.id,
          version: order?.version,
        },
        entrega: {
          id: order?.entregas[0].id,
        },
        dataEntregaAgendada:
          hasSchedule !== 'yes'
            ? null
            : formatDate(scheduledDeliveryDate, 'en-us'),
        dataMontagemAgendada:
          hasSchedule !== 'yes' || (!hasMounting && !scheduledAssemblyDate)
            ? null
            : verifyAssemblyDate(scheduledAssemblyDate),
      };

      const { data } = await api.post(
        'pdv/pedido/entrega/agendar',
        scheduleData,
      );

      if (data) {
        setOrder(data);

        updateSavedOrderVersion(data.id, data.version);
      }
      setFormData(StepIndex.SCHEDULE, {
        deliveryDate: formatDate(scheduledDeliveryDate, 'en-us'),
        assemblyDate: verifyAssemblyDate(scheduledAssemblyDate),
        hasSchedule,
        holidays: {
          periodo: {
            inicio: minimumDates.deliveryDate?.replace(/[/]/g, '-'),
            fim: (
              maximumDates.assemblyDate ?? maximumDates.deliveryDate
            )?.replace(/[/]/g, '-'),
          },
          holidays,
        },
      });

      history.push(getNextStep(step.key)?.url);
    } catch (error) {
      toast.addToast({
        type: 'error',
        title: 'Erro',
        description: error?.response?.data?.detail || error?.message,
      });
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    handleLoadDates(cancelToken);
    return () => cancelToken.cancel();
  }, [handleLoadDates]);

  useEffect(() => {
    setFormRef(formRef);
  }, [setFormRef]);

  return (
    <Container>
      <ScheduleTitle>
        <Event />
        <span>
          {!hasMounting
            ? 'Deseja agendar a entrega?'
            : 'Deseja agendar a entrega e montagem?'}
        </span>
      </ScheduleTitle>

      <hr />

      <RadioGroup
        row
        value={hasSchedule}
        onChange={handleChangeHasSchedule}
        style={{ marginTop: 30, marginBottom: 30 }}
      >
        <FormControlLabel
          value="yes"
          control={<Radio />}
          label="Sim"
          defaultChecked
        />
        <FormControlLabel value="no" control={<Radio />} label="Não" />
      </RadioGroup>
      {loading ? (
        <Loader />
      ) : (
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          onKeyPress={e => e.code === 'Enter' && handleSubmit()}
        >
          <div className="form-group">
            <div className="info-group">
              <strong>Entrega prevista para: </strong>
              <p id="deliveryDate">
                {minimumDates.deliveryDate
                  ? formatDate(minimumDates.deliveryDate, 'pt-br')
                  : '-'}
              </p>

              {hasSchedule === 'yes' && (
                <>
                  <DatePicker
                    value={scheduledDeliveryDate}
                    required
                    error={!scheduledDeliveryDate}
                    onChange={handleChangeDeliveryDate}
                    minDate={minimumDates.deliveryDate}
                    maxDate={maximumDates.deliveryDate}
                    name="scheduledDeliveryDate"
                    iconName="iconDeliveryDate"
                    disabledDates={holidays?.map(({ data }) =>
                      formatStringToDate(data),
                    )}
                    disableWeekend
                  />
                  {!scheduledDeliveryDate && (
                    <span className="errorMsg">Campo obrigatório!</span>
                  )}
                </>
              )}
            </div>

            {hasMounting && (
              <>
                <div className="divider" />
                <div className="info-group">
                  <strong>Montagem prevista para: </strong>
                  <p id="assemblyDate">
                    {minimumDates.assemblyDate
                      ? formatDate(minimumDates.assemblyDate, 'pt-br')
                      : '-'}
                  </p>

                  {hasSchedule === 'yes' && (
                    <>
                      <DatePicker
                        value={hasMounting ? scheduledAssemblyDate : null}
                        required={hasMounting}
                        error={hasMounting ? !scheduledAssemblyDate : false}
                        onChange={handleChangeAssemblyDate}
                        minDate={minimumDates?.assemblyDate}
                        maxDate={maximumDates?.assemblyDate}
                        name="scheduledAssemblyDate"
                        iconName="iconAssemblyDate"
                        disabledDates={holidays.map(({ data }) =>
                          formatStringToDate(data),
                        )}
                        disableWeekend
                      />
                      {!scheduledAssemblyDate && (
                        <span className="errorMsg">Campo obrigatório!</span>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Container>
  );
};

export default ScheduleForm;
