import styled from 'styled-components';

export const Container = styled.div`
  && {
    font-family: 'Montserrat', sans-serif;

    background-color: ${({ theme }) => theme.palette.white._100.hex()};

    width: 100%;
    max-width: 1024px;
    margin-top: 53px;
    border: none;
    place-content: center;

    .loading {
      display: flex;
      justify-content: center;
      margin: 50px;
    }

    @media screen and (min-width: 769px) {
      margin: 0 28px 0 28px;
    }
  }
`;

export const Success = styled.div`
  && {
    height: 84px;
    background-color: ${({ theme }) => theme.palette.green._400.hex()};
    color: ${({ theme }) => theme.palette.white.main.hex()};

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;

    .MuiSvgIcon-root {
      width: 40px;
      height: 30px;
      margin-right: 20px;
    }

    .successText {
      display: flex;
      flex-direction: column;

      .sellSuccess {
        font-size: 16px;
        font-weight: 700;
      }

      .order {
        font-size: 14px;
        font-weight: 700;
      }
    }

    @media screen and (min-width: 769px) {
      background-color: ${({ theme }) => theme.palette.yellow._100.hex()};
      color: ${({ theme }) => theme.palette.black.main.hex()};
    }
  }
`;

export const StepContent = styled.div`
  && {
    h1 {
      display: flex;
      align-items: center;
      height: 51px;
      padding: 0 10px 0 10px;

      color: ${({ theme }) => theme.palette.grey._600.hex()};

      font-size: 16px;
      font-weight: 700;
    }

    .horizontalRow {
      display: none;
    }

    @media screen and (min-width: 769px) {
      background-color: ${({ theme }) => theme.palette.white.main.hex()};

      h1 {
        font-size: 18px;
        font-weight: 700;
      }

      .horizontalRow {
        display: inherit;
        width: 100%;
        max-width: 1004px;
        border: 1px solid ${({ theme }) => theme.palette.grey._300.hex()};
        border-radius: 4px;
        margin: 0 10px 0 10px;
      }

      .groupInfo {
        display: flex;

        .info:first-child {
          margin-right: 23px;
        }

        .inputRow {
          display: flex;
          height: 1px;
          width: 278px;
          background: ${({ theme }) => theme.palette.grey._400.hex()};
          border: none;
        }
      }

      .customer {
        display: flex;
        flex-direction: column;

        .inputRow {
          display: flex;
        }
      }
    }
  }
`;

export const Content = styled.div`
  && {
    background-color: ${({ theme }) => theme.palette.white.main.hex()};
    padding: 15px 10px 15px 10px;

    span {
      font-size: 14px;
      font-weight: 400;
      padding: 5px 0 5px 0;
    }

    .productLine {
      height: 1px;
      max-width: 1004px;
      background: ${({ theme }) => theme.palette.grey._400.hex()};
      border: none;
      margin: 5px -10px 5px -10px;
    }

    img {
      width: 16px;
      height: 16px;
      vertical-align: text-top;
      margin-right: 6px;
    }

    .amount {
      display: flex;
      justify-content: space-between;
      padding: 5px 0 5px 0;

      span {
        font-weight: 600;
      }
    }
    .info {
      padding: 5px 0 5px 0;
    }

    .inputRow {
      display: none;
    }

    .payment {
      padding-bottom: 10px;

      :nth-child(even) {
        background: ${({ theme }) => theme.palette.grey._50.hex()};
      }
    }

    .paymentTitle {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }

    @media screen and (min-width: 769px) {
      .amount {
        flex-direction: column;

        span {
          font-weight: 400;
        }
      }

      .totalAmount {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 15px 10px 0 10px;
      }

      .productLine {
        display: none;
      }

      .inputRow {
        display: flex;
        height: 1px;
        max-width: 579px;
        background: ${({ theme }) => theme.palette.grey._400.hex()};
        border: none;
      }

      .payment {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
      }

      .paymentTitle {
        svg {
          margin-top: -7px;
        }
      }
    }
  }
`;

export const Title = styled.h2`
  && {
    padding: 5px 0 5px 0;

    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;

    @media screen and (min-width: 769px) {
      height: 35px;
    }
  }
`;

export const Itinerary = styled.div`
  && {
    border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
    box-sizing: border-box;
    border-radius: 4px;

    margin: 10px 0 10px 0;
    padding: 5px 10px 5px 10px;
  }
`;

export const Product = styled.div`
  && {
    .grade {
      grid-area: grade;
    }
    .preco {
      grid-area: preco;
      justify-self: end;
    }
    .quantidade {
      grid-area: quantidade;
    }
    .total {
      grid-area: total;
    }
    .montagem {
      grid-area: montagem;
      justify-self: end;
      align-self: center;
    }

    .contentTable {
      display: grid;
      grid-template: 'grade preco' 'quantidade quantidade' 'total montagem';
    }

    @media screen and (min-width: 769px) {
      padding: 0 10px 0 10px;

      :nth-child(even) {
        background: ${({ theme }) => theme.palette.grey._50.hex()};
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 51px;
    margin: 15px 10px 15px 10px;

    button {
      background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
      border: none;
      border-radius: 4px;

      color: ${({ theme }) => theme.palette.white.main.hex()};
      font-size: 16px;
      font-weight: 600;

      cursor: pointer;
      height: 48px;
      width: 100%;
      max-width: 328px;
    }

    @media screen and (min-width: 769px) {
      background-color: ${({ theme }) => theme.palette.white.main.hex()};
      margin: 0;
    }
  }
`;
