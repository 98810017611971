import { CancelTokenSource } from 'axios';
import api from '../apiClient';
import { Order, OrderInfo, updateSavedOrderVersion } from '../order';

export const tefStepUri = '/tef';

export enum StatusTef {
  sent = 'Enviado',
  pending = 'Pendente',
  finished = 'Finalizado',
  problem = 'Problema',
}

enum StatusTefPending {
  sent = 'Enviado',
  pending = 'Pendente',
}

export enum PaymentStatus {
  pending = 'Pendente',
  payed = 'Pago',
  cashBack = 'Em Estorno',
  calledOff = 'Cancelado',
}

export const TefPaymentMethods = {
  credit: {
    id: 9,
  },
  debit: {
    id: 11,
  },
};

export type PaymentByTef = TefDataStoraged & {
  pedido: OrderInfo;
};

export type TefDataStoraged = {
  atendimentoUri: string;
};

export type TefData = {
  pedido: {
    id: number;
    version: string;
  };
  atendimentoUri: string;
};

export type ReversePayment = {
  pedido: {
    id: number;
    version: string;
  };
  pagamento: {
    id: number;
  };
};

export const paymentByTef = async (
  orderInfo: {
    pedido: OrderInfo;
  },
  cancelToken?: CancelTokenSource,
): Promise<PaymentByTef> => {
  const { data } = await api.post(
    '/pdv/pedido/finalizacao/pagamentoViaTef',
    orderInfo,
    {
      cancelToken: cancelToken?.token,
    },
  );

  updateSavedOrderVersion(data.pedido.id, data.pedido.version);
  return data;
};

export const reversePaymentTef = async (
  paymentData: ReversePayment,
): Promise<TefData> => {
  const { data } = await api.post(
    '/pdv/pedido/finalizacao/estornarPagamentoTef',
    paymentData,
  );

  return data;
};

export const finalizeReversePaymentTef = async (
  paymentData: ReversePayment,
): Promise<Order> => {
  const { data } = await api.post(
    '/pdv/pedido/finalizacao/concluirEstornoPagamentoTef',
    paymentData,
  );

  updateSavedOrderVersion(data.id, data.version);
  return data;
};

export const isTefPending = (status: StatusTef): boolean => {
  return status in Object.values(StatusTefPending);
};

export const isTefFinished = (status: StatusTef): boolean => {
  return status === StatusTef.finished;
};
