import history from './history';

export const authRedirect = (): void => {
  const { REACT_APP_AUTH_URI, REACT_APP_REDIRECT_URI } = process.env;

  window.location.href = `${REACT_APP_AUTH_URI}?redirect=${REACT_APP_REDIRECT_URI}`;
};

export const oauthHubRedirect = (): void => {
  const { REACT_APP_OAUTH_HUB_URI } = process.env;
  window.location.href = String(REACT_APP_OAUTH_HUB_URI);
};

type HistoryProps = {
  referrer?: string;
};

export const appRedirect = (): void => {
  const oldRouteExists = localStorage.getItem('@MSPDV:state');
  const state: HistoryProps | undefined = history.location.state
    ? (history.location.state as HistoryProps)
    : undefined;
  const refer = state?.referrer || '/delivery';

  if (!oldRouteExists) {
    history.push(refer === '/' ? '/delivery' : refer);
    return;
  }

  const { page } = JSON.parse(oldRouteExists);
  history.push(page);
};
