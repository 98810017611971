import styled from 'styled-components';
import { Button } from '@material-ui/core';
import AccessAlarm from '@material-ui/icons/AccessAlarm';

export const Container = styled.header`
  && {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 62px;
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.palette.red._700.hex()} 0%,
      ${({ theme }) => theme.palette.red._400.hex()} 100%
    );

    display: flex;
    flex-direction: row;
    flex-grow: 1;
    place-items: center;
    justify-content: center;

    @media screen and (min-width: 769px) {
      place-content: space-between;
    }
  }
`;

export const Logo = styled.img`
  && {
    height: 32px;
    @media screen and (min-width: 769px) {
      margin-left: 27px;
    }
  }
`;

export const RightContent = styled.div`
  && {
    display: none;

    @media screen and (min-width: 769px) {
      display: flex;
      margin-right: 40px;
      place-items: center;

      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      color: ${({ theme }) => theme.palette.white.main.hex()};
    }
  }
`;

export const ClockIcon = styled(AccessAlarm)`
  && {
    height: 16px;
  }
`;

export const ButtonLogout = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.grey._210.hex()};
    font-weight: 700;
    margin-top: -3px;
    padding: 5px;

    & svg {
      margin-top: -2px;
      margin-left: 2px;
    }
  }
`;

export const NameUser = styled.span`
  && {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Timer = styled.span`
  && {
    height: 17px;
  }
`;
