export const detailOrder = {
  id: 123,
  version: '2020-01-01T09:11:28+00:00',
  situacao: 'Em Aberto',
  criadoEm: '2020-01-01T09:11:28+00:00',
  pagoEm: '2020-01-01T09:11:28+00:00',
  itens: [
    {
      produto: {
        prdno: '222222',
        grade: 'Branco',
      },
      titulo: 'Ventilador',
      valorOriginal: {
        origem: '1',
        precoUnitario: 40045,
      },
      fatorConversao: 2,
      quantidade: 2,
      disponibilidade: '2020-03-12',
      precoUnitario: 40045,
      precoTotal: 8009,
      valorTotalDescontos: 0,
      valorTotalFrete: 0,
      valorTotalServicos: 0,
      descontos: [
        {
          tipo: 'LOGISTICA_DESCONTO_NAO_MONTAR',
          nome: 'Desconto Não Montar',
          valorUnitario: 1201,
        },
      ],
      acrescimos: [
        {
          tipo: 'JUROS_CARTAO_TEF',
          nome: 'Juros Parcelamento via Mastercard',
          valorUnitario: 125,
        },
      ],
      valorTotal: 77938,
    },
    {
      produto: {
        prdno: '111111',
        grade: 'Preto',
      },
      titulo: 'Celular Samsung A105m a10 32Gb',
      valorOriginal: {
        origem: '9',
        precoUnitario: 125045,
      },
      fatorConversao: 2,
      quantidade: 1,
      disponibilidade: '2021-03-12',
      precoUnitario: 125045,
      precoTotal: 125045,
      valorTotalDescontos: 0,
      valorTotalFrete: 0,
      valorTotalServicos: 0,
      descontos: [
        {
          tipo: 'LOGISTICA_DESCONTO_NAO_MONTAR',
          nome: 'Desconto Não Montar',
          valorUnitario: 1201,
        },
      ],
      acrescimos: [
        {
          tipo: 'JUROS_CARTAO_TEF',
          nome: 'Juros Parcelamento via Mastercard',
          valorUnitario: 125,
        },
      ],
      valorTotal: 125045,
    },
  ],
  cliente: {
    cpfCnpj: '45684585584',
    nome: 'João da Silva',
    telefone: '47885694511',
    dataNascimento: '1984-02-07',
  },
  pagamentos: [
    {
      id: 456,
      metodoPagamento: {
        id: 61,
        descricao: 'Cartão',
      },
      formaPagamento: {
        id: 15,
        nome: 'MASTERCARD',
      },
      parcelamento: {
        id: 223,
        exibicao: '1x',
      },
      valorPago: 38844,
      numeroParcelas: 2,
      valorParcelas: 19422,
      detalhamentoProdutos: [
        {
          produto: {
            prdno: '44555',
            grade: 'UNICA',
          },
          valorUnitario: '38969',
        },
      ],
    },
    {
      id: 456,
      metodoPagamento: {
        id: 61,
        descricao: 'Cartão',
      },
      formaPagamento: {
        id: 15,
        nome: 'MASTERCARD',
      },
      parcelamento: {
        id: 223,
        exibicao: '1x',
      },
      valorPago: 38844,
      numeroParcelas: 2,
      valorParcelas: 19422,
      detalhamentoProdutos: [
        {
          produto: {
            prdno: '44555',
            grade: 'UNICA',
          },
          valorUnitario: '38969',
        },
      ],
      tef: {
        filialFaturamento: 104,
        codigoBandeira: 0,
        bin: '',
        nomeProduto: '',
        nsuSitef: '',
        nsuHost: '',
        codigoAutorizacao: '',
        dataAutorizacao: '2020-01-01T09:11:28+00:00',
        codigoRedeAutorizadora: '',
        linhaCorte: 0,
        arquivoAutorizacao: '',
      },
    },
  ],
  requisicoesTef: [
    {
      status: 'Pendente',
      tipo: 1,
      dadosEnvio: {},
      dadosRetorno: {},
    },
  ],
  entregas: [
    {
      id: 123,
      itinerario: {
        id: 999,
        nome: 'SANTA MARTHA - VITORIA',
        descricao: 'Atende bairro SANTA MARTHA',
        ofereceMontagem: false,
        descontoNaoMontar: 6,
        valorEntrega: 2300,
      },
      endereco: {
        cep: 11111111,
        tipoLogradouro: 'AER',
        bairro: 'Santa Martha',
        logradouro: 'Laury Tavares',
        numero: '415',
        complemento: 'Sala 1',
        pontoReferencia: 'Porta de vidro ao lado da portaria',
      },
      dataEntrega: '2020-02-01',
      dataMontagem: '2020-02-03',
    },
  ],
  valorTotalProdutos: 40045,
  valorTotalFrete: 0,
  valorEmAberto: 0,
  valorVenda: 77938,
  ipAtendimento: '120.0.0.1',
  atendente: {
    id: 456,
    login: 'simonetti',
    nome: 'Vendedor Simonetti',
  },
  eventos: [
    {
      ocorreuEm: '2020-01-01T09:11:28+00:00',
      usuario: {
        id: 456,
        login: 'simonetti',
        nome: 'Vendedor Simonetti',
      },
      tipo: 'CRIADO',
    },
    {
      ocorreuEm: '2020-01-01T09:11:28+00:00',
      usuario: {
        id: 456,
        login: 'simonetti',
        nome: 'Vendedor Simonetti',
      },
      tipo: 'PRODUTO_ADICIONADO',
    },
  ],
};
