import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useToast } from '../../hooks/Toast';
import { cancelOrder, clearStorage } from '../../services/order';

import {
  Container,
  ModalContainer,
  ButtonContainer,
  QuestionText,
  CustomDialog,
} from './styles';

interface ButtonProps {
  onClose?: any;
  open?: any;
}

const CancellationModal: React.FC<ButtonProps> = ({ onClose, open }) => {
  const toast = useToast();

  const onConfirm = async (): Promise<void> => {
    try {
      const { id } = await cancelOrder();

      clearStorage();

      onClose();
      toast.addToast({
        type: 'success',
        title: 'Sucesso',
        description: `O pedido ${id} foi cancelado`,
      });

      setTimeout(() => {
        window.location.replace('/delivery');
      }, 1500);
    } catch (error) {
      onClose();
      toast.addToast({
        type: 'error',
        title: error.response?.data.title,
        description: error.response?.data.detail,
      });
    }
  };

  return (
    <Container>
      <CustomDialog onClose={onClose} open={open}>
        <ModalContainer>
          <CloseIcon onClick={onClose} tabIndex={0} />
          <QuestionText>Tem certeza que deseja Cancelar o Pedido?</QuestionText>
          <ButtonContainer>
            <button className="buttonYes" type="button" onClick={onConfirm}>
              Sim
            </button>
            <button className="buttonNo" type="button" onClick={onClose}>
              Não
            </button>
          </ButtonContainer>
        </ModalContainer>
      </CustomDialog>
    </Container>
  );
};

export default CancellationModal;
