import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MOBILE_SCREEN, TABLET_SCREEN } from '../utils/screenSizes';

interface ScreenData {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  width: number;
  height: number;
}

const ScreenContext = createContext<ScreenData>({} as ScreenData);

const ScreenProvider: React.FC = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isMobile, setIsMobile] = useState(true);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const resizeWindow = useCallback((): void => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, []);

  window.addEventListener('resize', resizeWindow);

  useEffect(() => {
    setIsMobile(width <= MOBILE_SCREEN);
    setIsTablet(width > MOBILE_SCREEN && width <= TABLET_SCREEN);
    setIsDesktop(width > TABLET_SCREEN);
  }, [width]);

  return (
    <ScreenContext.Provider
      value={{
        isMobile,
        isTablet,
        isDesktop,
        width,
        height,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

function useScreen(): ScreenData {
  return useContext(ScreenContext);
}

export { ScreenProvider, useScreen };
