import axios from 'axios';
import { authRedirect } from './auth';

const api = axios.create({
  baseURL: String(process.env.REACT_APP_API_URI),
  withCredentials: true,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) authRedirect();
    return Promise.reject(error);
  },
);

export default api;
