import styled, { css } from 'styled-components';

import { GlobalContainer } from '../../../utils/GlobalStyles';
import Tooltip from '../../Tooltip';

interface DisabledLabel {
  disabled?: boolean;
}

export const Container = styled(GlobalContainer)<DisabledLabel>`
  && {
    .optional {
      margin-left: -57px;
      font-size: 13px;
      font-weight: 400;
      color: ${({ theme }) => theme.palette.grey._500.hex()};
    }

    .address {
      display: flex;
      margin-bottom: 15px;

      @media screen and (max-width: 769px) {
        flex-direction: column;
      }
    }

    .addressLogNumber {
      display: flex;
      width: 100%;
      margin-bottom: 15px;
    }

    .complement {
      display: flex;
      flex-direction: column;
      margin-bottom: 35px;
    }

    .MuiFormControl-root {
      width: 100%;
      max-width: 393px;
    }

    input:disabled,
    .MuiOutlinedInput-input:disabled {
      height: 10%;
      background-color: ${({ theme }) => theme.palette.grey._50.hex()};
      color: ${({ theme }) => theme.palette.grey._600.hex()};
      cursor: not-allowed;
    }

    .loading {
      margin-top: -24px;
      margin-bottom: 0;
    }

    form {
      min-height: 300px;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        label {
          color: ${({ theme }) => theme.palette.grey._250.hex()};
        }
      `}

    @media screen and (min-width: 769px) {
      .complement {
        flex-direction: row;
      }

      .MuiFormControl-root {
        width: 100%;
        max-width: 278px;
      }
    }
  }
`;

export const DeliveryTitle = styled.div`
  && {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    margin-bottom: 10px;
    place-content: center;

    font-size: 20px;
    font-weight: 700;

    span {
      align-self: center;
      margin-left: 11px;
    }

    svg {
      border: 1px solid ${({ theme }) => theme.palette.grey._200.hex()};
      border-radius: 50px;
      height: 22px;
      width: 22px;
      padding: 3px;
      background-color: ${({ theme }) => theme.palette.grey._50.hex()};
    }

    @media screen and (min-width: 769px) {
      place-content: initial;
    }
  }
`;

export const ZipCode = styled.div`
  && {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 28px;
    margin-bottom: 28px;

    label {
      padding-bottom: 8px;
    }

    .MuiFormControl-root {
      width: 100%;
      max-width: 150px;
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.grey._600.hex()};
      align-self: center;
      margin-left: 10px;
      margin-top: 25px;
    }
  }
`;

export const Address = styled.div`
  && {
    width: 100%;
    max-width: 322px;

    label {
      padding-bottom: 8px;
    }

    @media screen and (min-width: 769px) {
      width: 100%;
      max-width: 466px;

      .MuiFormControl-root {
        max-width: 466px;
      }
    }
  }
`;

export const TypeAddress = styled.div`
  && {
    width: 100%;
    max-width: 180px;
    margin-bottom: 25px;

    label {
      padding-bottom: 8px;
    }

    @media screen and (min-width: 769px) {
      width: 100%;
      max-width: 180px;
      margin-right: 10px;

      .MuiFormControl-root {
        max-width: 466px;
      }
    }
  }
`;

export const Number = styled.div`
  && {
    margin-left: 12px;
    label {
      padding-bottom: 8px;
    }

    .MuiFormControl-root {
      width: 100%;
      max-width: 60px;
    }

    @media screen and (min-width: 769px) {
      .MuiFormControl-root {
        max-width: 91px;
      }
    }
  }
`;

export const Complement = styled.div`
  && {
    margin-bottom: 35px;

    label {
      padding-bottom: 8px;
    }

    @media screen and (min-width: 769px) {
      width: 100%;
      max-width: 278px;
      margin-bottom: 0px;
    }
  }
`;

export const District = styled.div`
  && {
    label {
      padding-bottom: 8px;
    }

    @media screen and (min-width: 769px) {
      width: 100%;
      max-width: 278px;
      margin-left: 12px;
    }
  }
`;

export const City = styled.div`
  && {
    margin-bottom: 35px;

    label {
      padding-bottom: 8px;
    }

    @media screen and (min-width: 769px) {
      width: 100%;
      max-width: 278px;
      margin-bottom: 0px;
    }
  }
`;

export const State = styled.div`
  && {
    label {
      padding-bottom: 8px;
    }

    @media screen and (min-width: 769px) {
      margin-left: 12px;

      select {
        width: 232px;
      }
    }
  }
`;

export const Itinerary = styled.div`
  && {
    margin-bottom: 31px;

    label {
      padding-bottom: 8px;
    }

    @media screen and (min-width: 769px) {
      .MuiFormControl-root {
        width: 100%;
        max-width: 568px;
      }
    }
  }
`;

export const Shipping = styled.div`
  padding: 10px 0;
`;

export const ItineraryError = styled(Tooltip)`
  && {
    width: 100%;
    max-width: 625px;

    svg {
      margin: 0;
    }

    .title {
      background: ${({ theme }) => theme.palette.pink._100.hex()};
      color: ${({ theme }) => theme.palette.red._700.hex()};
      top: 70px;
      padding: 10px 15px;
      transform: none;
      left: 0;
      max-width: 568px;
    }

    @media screen and (min-width: 769px) {
      .MuiFormControl-root {
        max-width: 568px;
      }
    }
  }
`;

export const Observations = styled.div`
  && {
    margin-top: 31px;
    margin-bottom: 52px;

    label {
      padding-bottom: 8px;
    }

    textarea,
    .MuiOutlinedInput-root.MuiOutlinedInput-multiline {
      width: 100%;
      max-width: 393px;
      min-height: 84px;
      max-height: 200px;
    }

    @media screen and (min-width: 769px) {
      textarea,
      .MuiOutlinedInput-root.MuiOutlinedInput-multiline,
      .MuiFormControl-root {
        max-width: 568px;
      }
    }
  }
`;
