import React, { useEffect, useState, RefObject } from 'react';

import { FormHandles } from '@unform/core';
import { Layout, Footer } from '../../components';
import ScheduleForm from '../../components/Forms/ScheduleForm';

import { useStepForm } from '../../hooks/StepForm';
import { Steps } from '../../hooks/types';

export const Schedule: React.FC = () => {
  const { setStep } = useStepForm();
  const [form, setForm] = useState<RefObject<FormHandles>>();

  useEffect(() => setStep(Steps.schedule), [setStep]);

  return (
    <>
      <Layout>
        <ScheduleForm setFormRef={setForm} />
      </Layout>

      <Footer form={form} />
    </>
  );
};

export default Schedule;
