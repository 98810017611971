import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { format, subDays, subYears } from 'date-fns';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { useHistory } from 'react-router';

import { useStepForm } from '../../../hooks/StepForm';
import { useOrder } from '../../../hooks/OrderContext';
import { StepIndex, CustomerType } from '../../../hooks/types';
import { getCpfCnpj, registerCustomer } from '../../../services/customer';
import getValidationErrors from '../../../utils/getValidationErrors';
import { maskPhone, maskCPF } from '../../../services/masks';

import { Mask } from '../../TextField/types';

import {
  Container,
  CustomerTitle,
  CpfCnpjContainer,
  CustomerTextField,
  CustomerData,
  DateContainer,
  Loading,
} from './styles';
import { useToast } from '../../../hooks/Toast';
import DatePicker from '../../DatePicker';
import Loader from '../../Loader';

interface CustomerFormData {
  cpfCnpj: string;
  name: {
    name: string;
  };
  phoneNumber: {
    phoneNumber: string;
  };
  birthday: {
    birthday: string;
  };
}

const CustomerForm: React.FC<{
  setFormRef: (el: RefObject<FormHandles>) => void;
}> = ({ setFormRef }) => {
  const history = useHistory();
  const toast = useToast();

  const { step, getFormData, getNextStep, setFormData } = useStepForm();
  const { order, setOrder } = useOrder();
  const formRef = useRef<FormHandles>(null);

  const [customerCpfCnpj, setCustomerCpfCnpj] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [hasCustomer, setHasCustomer] = useState(false);
  const [hasError, setHasError] = useState('');
  const [helperPhone, setHelperPhone] = useState<boolean>(false);

  const MAX_DATE_BIRTHDAY = subDays(new Date(), 1);
  const MIN_DATE_BIRTHDAY = subYears(new Date(), 120);

  const getCpfCnpjData = useCallback(async (value: string): Promise<void> => {
    const formattedCpfCnpj = value?.replace(/[^0-9]/g, '');
    setCustomerCpfCnpj(formattedCpfCnpj);

    if (formattedCpfCnpj?.length !== 11) return;
    setIsLoading(true);
    setHasError('');

    try {
      const data = await getCpfCnpj(formattedCpfCnpj);

      if (data) {
        setHasCustomer(true);
      }
      setHasError('');

      const parsedDate = data.dataNascimento.replace(/[-]/g, '/');

      formRef.current?.setData({
        name: {
          name: data.nome,
        },
        phoneNumber: {
          phoneNumber: maskPhone(data.telefone),
        },
        birthday: {
          birthday: new Date(parsedDate),
        },
      });
    } catch (error) {
      setHasCustomer(false);

      setHasError('Documento não localizado. Cadastre abaixo um novo cliente.');

      formRef.current?.clearField('name.name');
      formRef.current?.clearField('phoneNumber.phoneNumber');
      formRef.current?.clearField('birthday.birthday');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getInitialValues = useCallback(async (): Promise<void> => {
    const initialValues = getFormData<CustomerType | undefined>(
      StepIndex.CUSTOMER,
    );

    if (initialValues) {
      setHasCustomer(true);
      setCustomerCpfCnpj(initialValues?.cpfCnpj);
      const parsedDate = initialValues.dataNascimento.replace(/[-]/g, '/');

      formRef.current?.setData({
        cpfCnpj: maskCPF(initialValues.cpfCnpj),
        name: {
          name: initialValues.nome,
        },
        phoneNumber: {
          phoneNumber: maskPhone(initialValues.telefone),
        },
        birthday: {
          birthday: new Date(parsedDate),
        },
      });
    }

    const cliente = order?.cliente;

    if (!cliente) {
      setIsLoading(false);
      return;
    }

    setCustomerCpfCnpj(await cliente.cpfCnpj);
    const parsedDate = cliente.dataNascimento.replace(/[-]/g, '/');
    formRef.current?.setData({
      cpfCnpj: maskCPF(cliente.cpfCnpj),
      name: {
        name: cliente.nome,
      },
      phoneNumber: {
        phoneNumber: maskPhone(cliente.telefone),
      },
      birthday: {
        birthday: new Date(parsedDate),
      },
    });

    setHasCustomer(true);
    setIsLoading(false);
  }, [getFormData, order]);

  useEffect(() => {
    if (formRef) {
      getInitialValues();
    }
  }, [formRef, getInitialValues]);

  useEffect(() => {
    setFormRef(formRef);
  }, [setFormRef]);

  const onChangeCpfCnpj = useCallback(
    async (event: any): Promise<void> => {
      const { value } = event.target;

      if (value.length === 14) {
        await getCpfCnpjData(value);
      }
    },
    [getCpfCnpjData],
  );

  const handleSubmit = useCallback(
    async (data: CustomerFormData) => {
      if (isLoading) return;
      setIsLoading(true);
      try {
        const schema = Yup.object().shape({
          cpfCnpj: Yup.string()
            .min(11, 'Cpf deve ter 11 dígitos')
            .required('CPF não informado'),
          name: Yup.object().shape({
            name: Yup.string().required('Nome não informado'),
          }),
          phoneNumber: Yup.object().shape({
            phoneNumber: Yup.string()
              .min(15, 'Telefone deve ter no mínimo 11 dígitos')
              .required('Telefone não informado'),
          }),
          birthday: Yup.object().shape({
            birthday: Yup.date()
              .max(
                MAX_DATE_BIRTHDAY,
                'Data de nascimento não deve estar no futuro',
              )
              .typeError('Data de nascimento não informada')
              .min(
                MIN_DATE_BIRTHDAY,
                `Data de nascimento não pode ser inferior à ${format(
                  MIN_DATE_BIRTHDAY,
                  'mm/dd/yyyy',
                )}`,
              ),
          }),
        });

        await schema.validate(data, { abortEarly: false });

        const parsedCpfCnpj = data.cpfCnpj.replace(/[^0-9]/g, '');

        const parsedPhoneNumber = data.phoneNumber?.phoneNumber.replace(
          /[^0-9]/g,
          '',
        );

        const parsedDateInput = format(
          new Date(data.birthday.birthday),
          'yyyy-MM-dd',
        );

        const formData = {
          cpfCnpj: parsedCpfCnpj,
          nome: data.name.name,
          telefone: parsedPhoneNumber,
          dataNascimento: parsedDateInput,
        };

        const pedido = order;

        if (!pedido) return;

        const customerOrderData = {
          pedido: {
            id: pedido.id,
            version: pedido.version,
          },
          cliente: {
            ...formData,
          },
        };

        const orderData = await registerCustomer(customerOrderData);

        setOrder(orderData);

        history.push(getNextStep(step.key)?.url);

        setFormData(StepIndex.CUSTOMER, formData);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          Object.values(errors).forEach(fieldError => {
            toast.addToast({
              type: 'error',
              title: 'Erro',
              description: fieldError,
            });
          });

          formRef.current?.setErrors(errors);
          return;
        }

        toast.addToast({
          type: 'error',
          title: 'Erro',
          description: error?.response?.data?.detail,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [
      isLoading,
      MAX_DATE_BIRTHDAY,
      MIN_DATE_BIRTHDAY,
      order,
      setOrder,
      history,
      getNextStep,
      step.key,
      setFormData,
      toast,
    ],
  );

  return (
    <Container>
      <CustomerTitle className="deliveryTitle">
        <LocalShippingOutlinedIcon />
        <span>Dados do cliente</span>
      </CustomerTitle>
      <hr />
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        onKeyPress={e =>
          e.code === 'Enter' &&
          handleSubmit(formRef.current?.getData() as CustomerFormData)
        }
      >
        <CpfCnpjContainer>
          <CustomerTextField
            label="CPF"
            name="cpfCnpj"
            length={14}
            autoFocus
            variationStyle="outlined"
            mask={Mask.cpf}
            onChange={event => onChangeCpfCnpj(event)}
            helperText={hasError}
            error={hasCustomer}
            disabled={isLoading}
          />
          {isLoading && (
            <Loading>
              <Loader />
            </Loading>
          )}
        </CpfCnpjContainer>

        {customerCpfCnpj && (
          <CustomerData>
            <Scope path="name">
              <CustomerTextField
                label="Nome"
                name="name"
                length={150}
                variationStyle="filled"
                mask={Mask.nameUpperCase}
                disabled={isLoading}
              />
            </Scope>
            <Scope path="phoneNumber">
              <CustomerTextField
                label="Telefone"
                name="phoneNumber"
                mask={Mask.phoneNumber}
                length={15}
                helperText={
                  helperPhone ? 'Telefone deve conter no mínimo 11 dígitos' : ''
                }
                onChange={event => {
                  setHelperPhone(event.target.value.length < 15);
                }}
                variationStyle="filled"
                disabled={isLoading}
              />
            </Scope>
            <Scope path="birthday">
              <DateContainer>
                <DatePicker
                  name="birthday"
                  label="Data de Nascimento"
                  inputVariant="standard"
                  maxDate={MAX_DATE_BIRTHDAY}
                  minDate={MIN_DATE_BIRTHDAY}
                  maxDateMessage="Data de nascimento não deve estar no futuro"
                  disabled={isLoading}
                />
              </DateContainer>
            </Scope>
          </CustomerData>
        )}
      </Form>
    </Container>
  );
};

export default CustomerForm;
