import { formatStringMoneyToNumber } from '../../utils/formatMoney';
import api from '../apiClient';
import { OrderInfo } from '../order';

export interface PriceProps {
  value: {
    valor: {
      tipo: string;
      origem: string;
    };
    valorUnitario: number;
  };
  text: string;
}

export type ValuesInfo = {
  tipo: string;
  origem: string;
  valor: number;
};

export type GridInfo = {
  prdnoFilho: string;
  grade: string;
  titulo: string;
  disponibilidade: string;
  valores: ValuesInfo[];
};

export type ProductInfo = {
  prdno: string;
  prdnoFilho: string;
  titulo: string;
  grades: GridInfo[];
  montagem: boolean;
  fatorConversao: number;
};

export type Product = {
  prdno: string;
  grade: string;
};

export type ProductOrder = {
  pedido: OrderInfo;
  produto: Product;
};
export interface ProductFormData {
  productCode: { text: string; value: string };
  grade: string;
  preco: PriceProps;
  quantidade: number;
  montagem: boolean;
}
export interface ProductData extends ProductOrder {
  descricao?: string;
  quantidade: number;
  montagem: boolean;
  valor: {
    tipo: string;
    origem: string;
  };
  valorUnitario: number;
}

export const getProduct = async (productCode: string): Promise<ProductInfo> => {
  const { data } = await api.post('produto/detalhar', { prdno: productCode });

  return data;
};

export const formatCustomPrice = (data: ProductFormData): ProductFormData => {
  const formData = {
    ...data,
    preco: {
      value: {
        valor: {
          tipo: 'PADRAO',
          origem: data.productCode.value,
        },
        valorUnitario: formatStringMoneyToNumber(data.preco.text),
      },
      text: String(data.preco.text),
    },
  };
  return formData;
};
