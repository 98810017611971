import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';

interface FillableSelect {
  hasError?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  && {
    display: flex;
    flex: 1;
    flex-direction: column;

    label {
      color: ${({ theme }) => theme.palette.black.main.hex()};
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      size: 14px;
      padding-bottom: 4px;
    }
  }
`;

export const ListOptions = styled.li`
  && {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 14px;

    .containerDiv {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`;

export const StyledTextField = styled(TextField)<FillableSelect>`
  && {
    .MuiOutlinedInput-root {
      background: ${({ theme }) => theme.palette.white.main.hex()};
      box-sizing: border-box;
      border-radius: 4px;
      color: ${({ theme }) => theme.palette.black.main.hex()};

      &:not(:disabled):hover fieldset,
      &:not(:disabled):focus fieldset {
        fieldset {
          border-color: ${({ theme }) => theme.palette.brown.main.hex()};
        }
      }

      .MuiOutlinedInput-input:disabled {
        cursor: not-allowed;
        background: ${({ theme }) => theme.palette.grey._50.hex()};
      }

      &.Mui-focused fieldset {
        ${({ disabled }) =>
          !disabled &&
          css`
            border-color: ${({ theme }) => theme.palette.brown.main.hex()};
          `};
      }

      ${({ disabled }) =>
        disabled &&
        css`
          fieldset {
            border-color: ${({ theme }) => theme.palette.grey._250.hex()};
            color: ${({ theme }) => theme.palette.black.main.hex()};
            background: ${({ theme }) => theme.palette.grey._50.hex()};
          }
        `};

      ${({ hasError }) =>
        hasError &&
        css`
          fieldset {
            border-color: ${({ theme }) => theme.palette.pink._500.hex()};
          }

          &:hover fieldset,
          &.Mui-focused fieldset {
            border-color: ${({ theme }) => theme.palette.pink._500.hex()};
          }
        `}
    }

    .MuiInputBase-root {
      color: ${({ theme }) => theme.palette.black.main.hex()};
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      ${({ disabled }) =>
        disabled &&
        css`
          color: ${({ theme }) => theme.palette.grey._250.hex()};
        `};
    }
  }

  input {
    ::placeholder {
      color: ${({ theme }) => theme.palette.grey._600.hex()};
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      opacity: 1;
      ${({ disabled }) =>
        disabled &&
        css`
          color: ${({ theme }) => theme.palette.grey._250.hex()};
        `};
    }
  }

  .MuiButtonBase-root {
    padding: 0px;
    margin-top: 4px;
  }
`;
