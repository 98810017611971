import styled, { css } from 'styled-components';
import { Button as MaterialButton } from '@material-ui/core';
import Button from '../Button';

export const Container = styled.footer`
  && {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    z-index: 4;

    display: flex;
    flex-direction: row;
    place-content: space-between;

    background-color: ${({ theme }) => theme.palette.white.main.hex()};

    @media screen and (min-width: 769px) {
      left: 0;
      flex-grow: 1;
      place-content: space-around;
      align-items: center;

      background-color: ${({ theme }) => theme.palette.brown.main.hex()};
    }
  }
`;

export const GroupButton = styled.div`
  && {
    @media screen and (min-width: 769px) {
      display: flex;
      flex-direction: row;
      place-content: space-between;
      width: 256px;
      justify-content: space-around;
    }
  }
`;

export const CancelButton = styled(Button)`
  && {
    cursor: pointer;
    width: 120px;

    background-color: ${({ theme }) => theme.palette.white._50.hex()};
    color: ${({ theme }) => theme.palette.yellow._600.hex()};
    border: 1px solid ${({ theme }) => theme.palette.yellow._600.hex()};

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 70%;
        cursor: not-allowed;
      `};
  }
`;

export const ForwardButton = styled.button`
  && {
    cursor: pointer;

    height: 40px;
    width: 120px;
    text-decoration: none;
    border: 1px;
    border-radius: 4px;

    display: flex;
    place-items: center;
    justify-content: center;

    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    size: 14px;

    background-color: ${({ theme }) => theme.palette.yellow._600.hex()};
    color: ${({ theme }) => theme.palette.white._50.hex()};

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 70%;
        cursor: not-allowed;
      `};

    &:hover {
      opacity: ${({ disabled }) => !disabled && '80%'};
      transition: opacity 0.5s;
    }

    &:focus {
      outline: none;
      opacity: 70%;
    }
  }
`;

export const BackwardButton = styled.button`
  && {
    display: none;

    @media screen and (min-width: 769px) {
      display: flex;
      place-items: center;
      justify-content: center;

      cursor: pointer;

      height: 40px;
      width: 120px;
      text-decoration: none;
      border: 1px;
      border-radius: 4px;

      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      size: 14px;

      background-color: ${({ theme }) => theme.palette.white._50.hex()};
      color: ${({ theme }) => theme.palette.yellow._600.hex()};

      ${({ disabled }) =>
        disabled &&
        css`
          opacity: 70%;
          cursor: not-allowed;
        `};

      &:hover {
        opacity: ${({ disabled }) => !disabled && '80%'};
        transition: opacity 0.5s;
      }

      &:focus {
        outline: none;
        opacity: 70%;
      }
    }
  }
`;

export const OptionsButton = styled(MaterialButton)`
  && {
    width: 120px;
    text-transform: capitalize;

    background-color: ${({ theme }) => theme.palette.white._50.hex()};
    color: ${({ theme }) => theme.palette.yellow._600.hex()};
    border: 1px solid ${({ theme }) => theme.palette.yellow._600.hex()};
  }
`;
