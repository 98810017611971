import { Factory } from 'miragejs';
import faker from 'faker';

import { CEP } from './utils';
import { Endereco } from '../models/endereco';

faker.locale = 'pt_BR';

export default {
  endereco: Factory.extend({
    id() {
      return faker.random.number(10);
    },
    cep() {
      return faker.random.arrayElement(CEP);
    },
    tipoLogradouro() {
      return faker.fake('{{address.streetSuffix}}');
    },
    logradouro() {
      return faker.fake('{{address.streetName}}');
    },
    complemento() {
      return faker.fake('{{address.secondaryAddress}}');
    },
    bairro() {
      return faker.fake('{{address.country}}');
    },
    cidade() {
      return faker.fake('{{address.city}}');
    },
    uf() {
      return faker.fake('{{address.state}}');
    },
    afterCreate(endereco: Endereco, server: any) {
      server.create('itinerario', {
        endereco,
        bairros: ['Suazilândia', 'Equador'],
        descontoNaoMontar: 0,
        ofereceMontagem: false,
        descricao: 'Atende bairro Suíça',
        nome: 'Israel - Alagoas',
        id: endereco.id + 1,
      });

      server.create('itinerario', {
        endereco,
        bairros: ['Burkina Faso', 'Ilhas Caiman'],
        descontoNaoMontar: 0,
        ofereceMontagem: false,
        descricao: 'Atende bairro Lituânia',
        nome: 'Ilhas Marshall - Santa Catarina',
        id: endereco.id + 2,
      });

      server.create('itinerario', {
        endereco,
        bairros: ['Andorra', 'Grécia'],
        descontoNaoMontar: 0,
        ofereceMontagem: false,
        descricao: 'Atende bairro Congo',
        nome: 'México - Minas Gerais',
        id: endereco.id + 3,
      });
    },
  }),
};
