import { Factory } from 'miragejs';
import faker from 'faker';
import { format } from 'date-fns';

import { CPF } from './utils';

faker.locale = 'pt_BR';

export default {
  cliente: Factory.extend({
    id() {
      faker.random.number();
    },
    cpfCnpj() {
      return faker.random.arrayElement(CPF);
    },
    nome() {
      return faker.name.firstName();
    },
    telefone() {
      const phoneNumber = '(62) 3216-54987';

      return phoneNumber;
    },
    dataNascimento() {
      const parsedDate = format(new Date(faker.date.past()), 'yyyy-MM-dd');

      return parsedDate.toString();
    },
  }),
};
